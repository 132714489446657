import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import ItemAdd from '../components/ItemAdd'
import ItemMasterList from '../components/ItemMasterList'
import { createItemAction, updateItemAction, removeItemAction } from '../redux/actions/ItemAction'
import { getLookupData } from '../redux/actions/GeneralAction'


class ItemMaster extends Component {

    constructor(props) {
        super(props);

        this.state = {
            itemLayout: "Item List",
            lookupData: [],
            singleItem: {}
        }
    }

    itemList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('ItemList')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Add Item") }}>{this.props.t('AddItem')}  </button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ItemMasterList handleLayout={this.updateLayout} {...props} />
                </CardBody>
            </Card>
        )
    }

    itemCreate = (singleItem) => {

        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.state.singleItem!=={}&&this.state.singleItem!==null?this.props.t('EditItem'):this.props.t('AddItem')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Item List") }}> {this.props.t('ItemList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ItemAdd
                        {...this.props}
                        updateLayout={this.updateLayout}
                        singleItem={this.state.singleItem}
                    />
                </CardBody>
            </Card>
        )
    }

    itemPreview = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('EditItem')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Item List") }}>{this.props.t('ItemList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ItemAdd updateLayout={this.updateLayout} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, row = null) => {
        this.setState({ itemLayout: layout, singleItem: row });
    }

    getItemComponent(layout) {

        switch (layout) {

            case "Item List": return this.itemList(this.props)

            case "Add Item": return this.itemCreate();

            case "Edit Item": return this.itemCreate();

            default: return

        }

    }
    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                itemLayout: "Item List"
            })
        }
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {this.getItemComponent(this.state.itemLayout)}
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        allCustomers: state.customerReducer.customers,
        lookupData: state.generalReducer.lookupData,
        itemResponse: state.ItemReducer !== undefined ? state.ItemReducer.itemResponse : [],
        itemSuccess: state.ItemReducer !== undefined ? state.ItemReducer.itemSuccess : ''
    }

}

const mapDispatchToProps = {
    createItem: createItemAction,
    updateItem: updateItemAction,
    removeItem: removeItemAction,
	getLookupData: getLookupData
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ItemMaster))