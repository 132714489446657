import * as actionTypes from '../constant';
import {invoices, createInvoice,updateInvoice} from '../../services/InvoiceServices';

export const invoiceListAction = () => {

    return dispatch => {
        invoices().then(res => {

            if (res.data !== undefined) {
                dispatch(getInvoiceList(res.data.data));
               
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error invoice list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getInvoiceList = (res) => {
    return {
        type: actionTypes.INVOICE_LIST,
        value: res
    }
}

export const createInvoiceAction = (invoice) => {
    return  dispatch => {
        createInvoice(invoice).then(res => {

            if (res.data !== undefined) {
                dispatch(createInvoicePost(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error invoice create action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

export const updateInvoiceAction = (invoiceId,invoice) => {
    return  dispatch => {
        updateInvoice(invoiceId,invoice).then(res => {

            if (res.data !== undefined) {
                dispatch(updateInvoiceDispatch(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error invoice create action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const createInvoicePost = (res) => {
    return {
        type: actionTypes.INVOICE_CREATE,
        value: res
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
const updateInvoiceDispatch=(res)=>{
    return {
        type: actionTypes.INVOICE_UPDATE,
        value: res
    }
}

