import React from 'react';
import { BrowserRouter as Router,Route, Switch } from "react-router-dom";

import Login from '../pages/Login'
import Main from '../layout/Main'


class Routes extends React.Component{

	render(){
	    return(
	    	<React.Fragment>
	    		<Router>
                    <Switch>
						<Route exact path="/" component={Login} />
						<Route path="/main" component={Main} />
					</Switch>
				</Router>
	        </React.Fragment>  
	    )
	}
}
export default Routes;
