import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import i18n from '../i18n';
import thumbnail from '../assets/images/thumbnail.png'
import { connect } from 'react-redux';
import { deleteOrderAction, updateOrderStatusAction } from '../redux/actions/OrderActions';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { isInvoicing, isAdmin, isAssortmentAdmin } from '../helper/General';
import { AlertMessage } from '../helper/AlertBox'


class OrderList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            allCustomers: props.allCustomers,
            lookupData: props.lookupData,
            allOrders: props.allOrders,
            lng: 'en',
            success: props.success,
            error: props.error,
            show: false,
            orderId: '',
            orderStatus: ''
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {

        this.setState({
            allCustomers: nextProps.allCustomers,
            lookupData: nextProps.lookupData,
            allOrders: nextProps.allOrders,
            success: nextProps.success,
            show: nextProps.success ? false : this.state.show,
        })

    }

    updateOrder = (orderId) => {
        this.props.toggleButton(orderId);
    }


    updateOrderStatus = (orderId, orderStatus) => {
        this.props.updateOrderStatus(orderId, { orderStatus: orderStatus });
    }

    deleteOrder = (orderId) => {
        console.log('delete order', orderId);
        this.props.deleteOrder(orderId);

    }

    imageThumbFormatter = (cell, row) => {
        return (<span><img alt="order" src={cell ? cell : thumbnail} width={50} /></span>);
    }

    customerNameFormatter = (cell, row) => {
        const customerData = this.state.allCustomers !== undefined && this.state.allCustomers.length && this.state.allCustomers.find(customer => customer.customerId === cell);
        return (customerData !== undefined && customerData.fullName);
    }

    statusFormatter = (cell, row) => {
        let langId = i18n.language === 'en' ? 1 : 2;
        let statusData = this.state.lookupData !== undefined && this.state.lookupData.Status !== undefined && this.state.lookupData.Status.find(status => status.statusId === cell);
        let statusText = statusData !== undefined && statusData.text !== undefined && statusData.text.length > 0 && statusData.text.find(text => text.langId === langId)
        return (<span>{statusText !== undefined && statusText.name}</span>);
    }
    buttonFormatter = (cell, row) => {

        return (<React.Fragment>
            <button type="button" className="btn-primary-outline" title="Edit"><i className="icon icon-pencil"></i></button>
            { /*<button type="button" className="btn-primary-outline" title="Update Status"><i className="icon icon-note"></i></button>*/}
            <button type="button" className="btn-primary-outline ml-2" title="Delete"><i className="icon icon-trash"></i></button>
        </React.Fragment>
        );
    }

    pickupFormatter = (cell, row) => {
        let pickupAddress = '';
        if (row.pickupType === 'Drop Off by Customer') {
            let langId = i18n.language === 'en' ? 1 : 2;
            let branchData = this.state.lookupData !== undefined && this.state.lookupData.Branch !== undefined && this.state.lookupData.Branch.find(branch => branch.branchId == row.pickupAddress);
            let branchText = branchData !== undefined && branchData.text !== undefined && branchData.text.length > 0 && branchData.text.find(text => text.langId === langId)
            pickupAddress = branchText.name;
        }
        else {
            pickupAddress = row.pickupAddress
        }

        return (<span>{`${row.pickupType ? row.pickupType + ':' : ''} ${pickupAddress}`}</span>);
    }

    deliveryFormatter = (cell, row) => {
        let deliveryAddress = '';
        if (row.deliveryType === 'Customer self Pickup') {
            let langId = i18n.language === 'en' ? 1 : 2;
            let branchData = this.state.lookupData !== undefined && this.state.lookupData.Branch !== undefined && this.state.lookupData.Branch.find(branch => branch.branchId == row.deliveryAddress);
            let branchText = branchData !== undefined && branchData.text !== undefined && branchData.text.length > 0 && branchData.text.find(text => text.langId === langId)
            deliveryAddress = branchText.name;
        }
        else {
            deliveryAddress = row.deliveryAddress
        }

        return (<span>{`${row.deliveryType ? row.deliveryType + ':' : ''} ${deliveryAddress}`}</span>);
    }

    actionFormatter = (cell, row) => {

        let actions = '';
        if (isAdmin()) {
            actions = <span>
                {row.orderStatus === 1 && <button className="btn btn-link" onClick={() => this.updateOrder(row.orderId)}><i className="fa fa-pencil"></i></button>}
                {this.props.userProfile !== undefined && this.props.userProfile.roleId == 1 && <button className="btn btn-link" onClick={() => this.handleShow(row.orderId, row.orderStatus)}><i className="fa fa-cog"></i> </button>}
            </span>
        } else if (row.orderStatus === 1) {
            actions = <span><button className="btn btn-link" onClick={() => this.updateOrder(row.orderId)}><i className="fa fa-pencil"></i> </button>
                <button className="btn btn-link" onClick={() => { if (window.confirm(`Are you sure you wish to delete Order#${row.orderId}?`)) this.deleteOrder(row.orderId) }}><i className="fa fa-trash text-danger"></i> </button>
            </span>
        } else {
            actions = <span>{/*<button className="btn btn-link" onClick={()=>this.handleShow(row.orderId,row.orderStatus)}><i className="fa fa-cog"></i> </button>*/}</span>

        }
        return actions;
    }

    handleClose = () => this.setState({ orderId: '', orderStatus: '', show: !this.state.show });

    handleShow = (orderId, orderStatus) => {
        this.setState({ orderId: orderId, orderStatus: orderStatus, show: true });
    }

    render() {
        let langId = i18n.language === 'en' ? 1 : 2
        let statusOptions = this.state.lookupData !== undefined && this.state.lookupData.Status.map(
            (status) => { return <option key={`statusOpt${status.statusId}`} value={status.statusId}>{status.text.find(text => text.langId === langId).name}</option> }
        )
        document.title = 'Orders';
        const { t } = this.props;
        const { SearchBar, ClearSearchButton } = Search;
        const defaultSorted = [{
            dataField: 'orderId',
            order: 'desc'
        }];
        let columns = [
            {
                dataField: "orderId",
                text: t("OrderID"),
                headerAlign: 'left',
                align: 'left',
                sort: true
            },
            {
                dataField: "customerId",
                text: t("Customer Id"),
                headerAlign: 'left',
                align: 'left'
            },
            {
                dataField: "customerName",
                text: t("CustomerName"),
                headerAlign: 'left',
                align: 'left'
            },
            {
                dataField: "orderImages[0].url",
                text: t("ProductImage"),
                formatter: this.imageThumbFormatter,
                headerAlign: 'center',
                align: 'center'
            },
            {
                dataField: "orderRemarks",
                text: t("Remarks"),
                headerAlign: 'left',
                align: 'left'
            },
            {
                dataField: "pickupType",
                text: t("PickupType"),
                formatter: this.pickupFormatter,
                headerAlign: 'right',
                align: 'right'
            },
            {
                dataField: "deliveryType",
                text: t("DeliveryType"),
                formatter: this.deliveryFormatter,
                headerAlign: 'right',
                align: 'right'
            },
            {
                dataField: "orderStatus",
                text: t("Status"),
                formatter: this.statusFormatter,
                headerAlign: 'center',
                align: 'center'
            }
        ];
        if (!isInvoicing() && !isAssortmentAdmin()) {
            columns = [
                ...columns,
                {
                    dataField: "createdDate",
                    text: t("Action"),
                    formatter: this.actionFormatter,
                    headerAlign: 'center',
                    align: 'center'
                }
            ]
        }

        return (

            <React.Fragment>
                <Row>
                    {this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
                </Row>
                <Row>
                    <Col lg="12" md="12">
                        {this.state.lookupData !== undefined && this.state.allOrders !== undefined ?
                            <ToolkitProvider
                                keyField="orderId"
                                key={'orderListTable'}
                                data={this.state.allOrders}
                                columns={columns}
                                search={{
                                    searchFormatted: true
                                }}
                            >
                                {
                                    props => (
                                        <div>
                                            <div className="mb-2">
                                                <SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
                                                {/* <ClearSearchButton { ...props.searchProps } /> */}
                                            </div>
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    {...props.baseProps}
                                                    pagination={paginationFactory()}
                                                    defaultSorted={defaultSorted}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </ToolkitProvider>
                            : ''}
                    </Col>
                </Row>

                <Modal isOpen={this.state.show}>
                    <ModalHeader>
                        Update Order Status
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md="4" className="align-items-center">Order#:</Col>
                            <Col md="8">{this.state.orderId}</Col>
                        </Row>
                        <Row className={`form-group`}>
                            <label className="col-md-4">{this.props.t('Status')}:</label>
                            <Col md="8" className="align-items-center">
                                <Input
                                    type="select"
                                    name="orderStatus"
                                    value={this.state.orderStatus}
                                    onChange={(e) => this.setState({ 'orderStatus': e.target.value })}
                                    className="form-control">
                                    <option value="">-- {this.props.t('Status')} --</option>
                                    {statusOptions}
                                </Input>
                            </Col>
                        </Row>
                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={this.handleClose}>Close</Button>
                        <Button color="primary" className="btn btn-primary" onClick={() => this.updateOrderStatus(this.state.orderId, this.state.orderStatus)}>Update</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        allCustomers: state.customerReducer.customers,
        allOrders: state.orderReducer.orders,
        lookupData: state.generalReducer.lookupData,
        success: state.orderReducer.orderSuccess,
        error: state.orderReducer.orderError,
    }
}

const mapDispatchToProps = {
    deleteOrder: deleteOrderAction,
    updateOrderStatus: updateOrderStatusAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderList));