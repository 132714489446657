import * as actionTypes from '../constant';
import {roleToRedirect} from '../../helper/General'

const INITIAL_STATE = {};

export const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        loggedIn: action.value.token?true:false,
        userProfile :action.value,
        redirectTo:roleToRedirect(action.value.roleId)
      };

    case actionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,

      };
    case actionTypes.LOG_ERROR:
      return {
        ...state,
        loggedIn: false,
        loginError: action.value
      };
    case actionTypes.CLEAR_ERROR:
      return {
        ...state,
        loginError: null
      };


    default:
      return state; 
  }
}
