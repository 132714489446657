import * as actionTypes from '../constant';
import { customers, createCustomer, updateCustomer, customerStatement, collectMaterial, collectPayment ,showCreditExceedingCustomer} from '../../services/CustomerServices';

export const customerListAction = () => {

    return dispatch => {
        customers().then(res => {

            if (res.data !== undefined) {
                dispatch(getCustomerList(res.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
             console.log('error customer list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getCustomerList = (res) => {
    return {
        type: actionTypes.CUSTOMER_LIST,
        value: res
    }
}


export const customerStatementAction = (customerId,filter) => {

    return dispatch => {
        customerStatement(customerId,filter).then(res => {

            if (res.data !== undefined) {
                dispatch(getCustomerStatement(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getCustomerStatement = (res) => {
    return {
        type: actionTypes.CUSTOMER_STATEMENT,
        value: res
    }
}

export const createCustomerAction = (customer) => {
    return dispatch => {
        createCustomer(customer).then(res => {
            if (res.data !== undefined) {
                dispatch(createCustomerPost(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer create action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const createCustomerPost = (res) => {
    return {
        type: actionTypes.CUSTOMER_CREATE,
        value: res
    }
}

export const updateCustomerAction = (customerId, customer) => {
    return dispatch => {
        updateCustomer(customerId, customer).then(res => {
            if (res.data !== undefined) {
                dispatch(updateCustomerPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateCustomerPut = (res) => {
    return {
        type: actionTypes.CUSTOMER_UPDATE,
        value: res
    }
}

export const collectPaymentAction = (customerId, payment) => {
    return dispatch => {
        collectPayment(customerId, payment).then(res => {
            if (res.data !== undefined) {
                dispatch(collectPaymentPost(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const collectPaymentPost = (res) => {
    return {
        type: actionTypes.COLLECT_PAYMENT,
        value: res
    }
}

export const collectMaterialAction = (customerId, material) => {
    return dispatch => {
        collectMaterial(customerId, material).then(res => {
            if (res.data !== undefined) {
                dispatch(collectMaterialPost(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer material action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const collectMaterialPost = (res) => {
    return {
        type: actionTypes.COLLECT_MATERIAL,
        value: res
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}

export const creditExceedingCustomerAction = () => {

    return dispatch => {
        showCreditExceedingCustomer().then(res => {

            if (res.data !== undefined) {
                dispatch(getCustomerCreditExceedingList(res.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
             console.log('error customer exceeding creditlimt list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}
const getCustomerCreditExceedingList = (res) => {
    return {
        type: actionTypes.CUSTOMER_CREDIT_EXCEEDING_LIST,
        value: res
    }
}
