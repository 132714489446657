import * as actionTypes from '../constant';
import { branches, createBranch, updateBranch } from '../../services/BranchServices';

export const branchListAction = () => {

    return dispatch => {
        branches().then(res => {

            if (res.data !== undefined) {
                dispatch(getBranchList(res.data.data));

                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error branch list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getBranchList = (res) => {
    return {
        type: actionTypes.BRANCH_LIST,
        value: res
    }
}

export const createBranchAction = (branch) => {
    return  dispatch => {
        createBranch(branch).then(res => {

            if (res.data !== undefined) {
                dispatch(createBranchPost(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error branch create action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

export const updateBranchAction = (branchId,branch) => {
    return  dispatch => {
        updateBranch(branchId,branch).then(res => {

            if (res.data !== undefined) {
                dispatch(updateBranchDispatch(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error branch create action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const createBranchPost = (res) => {
    return {
        type: actionTypes.BRANCH_CREATE,
        value: res
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
const updateBranchDispatch=(res)=>{
    return {
        type: actionTypes.BRANCH_UPDATE,
        value: res
    }
}