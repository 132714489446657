import Api from '../Api'

export const getUsers = () => {
    return Api.get('user');
};

export const createUser = (user) => {
    return Api.post('user', user);
}

export const updateUser = (userId, user) => {
    return Api.put('user/' + userId, user);
}

export const getPackages = (userId) => {
    return Api.get('packages/user/' + userId);
}

export const getStatement = (userId) => {
    return Api.get('transactions/user/' + userId);
}

export const updateUserStatus = (data) => {
    return Api.post('auth/verify/account',data);
}
