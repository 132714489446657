import * as actionTypes from '../constant';
const INITIAL_STATE = {};

export const ledgerReducer = (state = INITIAL_STATE, action) => {
    let users = [];
    let index = false;
    switch (action.type) {
        case actionTypes.LEDGER_LIST:
            return {
                ...state,
                ledgerStatement: action.value,
                ledgerSuccess: ''
            };
        case actionTypes.TRANSFER_MONEY:
            return {
                ...state,
                ledgerStatement: [...state.ledgerStatement],
                ledgerSuccess: 'money transfer successfully'
            };
        default:
            return { ...state, userError: '', ledgerSuccess: '' };
    }
}