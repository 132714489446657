import Api from '../Api'

export const branches = () => {
    return Api.get('branch');
};

export const createBranch = (branch) => {
    return Api.post('branch', branch);
}

export const updateBranch = (branchId, branch) => {
    return Api.put('branch/'+branchId, branch);
}

export const updateBranchStatus = (branchId, branch) => {
    return Api.put('branch/update-status/'+branchId, branch);
}