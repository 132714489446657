import React, { Component } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink, ModalFooter, Button } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import { isAccounting } from '../helper/General';
import { ModalPopup } from '../helper/ModalPopup';
import { AlertMessage } from '../helper/AlertBox'
import { updateCustomerAction } from '../redux/actions/CustomerActions'

class CustomerList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allCustomers: this.props.allCustomers,
			success: props.success,
			error: props.error,
			createNoteModal: false,
			creditCustomerId: '',
			customerCredit: {
				note: '',
				creditLimit: ''
			}
		}

	}


	UNSAFE_componentWillReceiveProps(nextProps, prevState) {

		if (nextProps.allCustomers !== prevState.nextProps) {
			this.setState({
				allCustomers: nextProps.allCustomers
			})
		}
		if (nextProps.success !== prevState.success) {
			this.setState({
				success: nextProps.success,
				createNoteModal: false
			})
		}

	}
	buttonFormatter = (cell, row) => {
		return (<button type="button" className="btn-primary-outline" onClick={() => this.props.handleOrderView('View Customer Order', row.customerId)}><i className="icon icon-eye"></i></button>);
	}
	statementButtonFormatter = (cell, row) => {
		return (<button type="button" className="btn btn-primary-fill">Statement</button>);
	}
	creditnoteButtonFormatter = (cell, row) => {

		return <button type="button" className="btn btn-primary-fill" onClick={() => this.showCreditModal(row)}>Credit Note</button>
	}
	toggleModal = () => {
		this.setState((prevState) => ({
			createNoteModal: !prevState.createNoteModal
		}))
	}
	showCreditModal = (row) => {

		let customerCredit = {
			note: row.note,
			creditLimit: parseFloat(row.creditLimit).toFixed(2)
		}
		this.setState({
			createNoteModal: true,
			creditCustomerId: row.customerId,
			customerCredit: customerCredit

		})
	}
	handleSubmitCreditNote = () => {
		if (this.state.customerCredit.creditLimit < 1) {
			alert("Amount should be greater than 0.")
		} else {
			this.props.updateCustomer(this.state.creditCustomerId, this.state.customerCredit)
		}
	}
	handleChange = (e) => {
		let { customerCredit } = this.state
		customerCredit[e.target.name] = e.target.value
		this.setState({ customerCredit })
	}
	componentDidMount() {
		document.title = 'Customers';
	}
	render() {

		const { SearchBar } = Search;
		let columns = [
			{
				dataField: "customerId",
				text: this.props.t('CustomerId'),
				sort: true
			},
			{
				dataField: "fullName",
				text: this.props.t('CustomerName'),
				sort: true
			},
			{
				dataField: "orderCount",
				text: this.props.t('TotalOrder #'),
			},
			{
				dataField: "createdAt",
				text: this.props.t('View'),
				formatter: this.buttonFormatter
			}
		];
		if (isAccounting()) {
			columns = [
				...columns,
				// {
				// 	dataField: "statement",
				// 	text: 'Statement',
				// 	formatter: this.statementButtonFormatter
				// },
				{
					dataField: 'action',
					text: 'Action',
					formatter: this.creditnoteButtonFormatter
				}
			]
		}

		return (
			<React.Fragment>
				<Row>
					{this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				{this.props.allCustomers !== undefined ? <Row>
					<Col lg="12" md="12">
						<ToolkitProvider
							keyField="customerId"
							data={this.props.allCustomers}
							columns={columns}
							search
						>
							{
								props => (
									<div key={'cusotmerDiv'}>
										<div className="mb-2">
											<SearchBar {...props.searchProps} placeholder={this.props.t('Search')}
												className="offset-md-9 col-md-3" />
										</div>
										<div className="table-responsive">
											<BootstrapTable  {...props.baseProps} pagination={paginationFactory()}   key="customerTable"/>
										</div>
									</div>
								)
							}
						</ToolkitProvider>
					</Col>
				</Row> : ''
				}
				<ModalPopup
					modal={this.state.createNoteModal}
					toggle={this.toggleModal}
					title="Create Credit Note"
				>
					<div className="row">
						<div className="col-md-12">
							<div className="form-group row">
								<label className="col-md-4">{this.props.t('Note')}</label>
								<div className="col-md-8">
									<textarea className="form-control" name="note" onChange={(e) => this.handleChange(e)} defaultValue={this.state.customerCredit.note} />
								</div>
							</div>
							<div className="form-group row">
								<label className="col-md-4">{this.props.t('Amount')} (฿)</label>
								<div className="col-md-8">
									<input className="form-control" name="creditLimit" type="text" onChange={(e) => this.handleChange(e)} defaultValue={this.state.customerCredit.creditLimit} />
								</div>
							</div>
						</div>
					</div>
					<ModalFooter>
						<Button className="btn-primary-fill" onClick={this.handleSubmitCreditNote}>Save</Button>{' '}
						<Button color="secondary" onClick={this.toggleModal}>Cancel</Button>
					</ModalFooter>
				</ModalPopup>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		allCustomers: state.customerReducer.customers,
		allOrders: state.orderReducer.orders,
		lookupData: state.generalReducer.lookupData,
		success: state.customerReducer.customerSuccess,
		error: state.customerReducer.customerError,
	}
}

const mapDispatchToProps = {
	updateCustomer: updateCustomerAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerList))