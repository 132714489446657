import React, { Component } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink, ModalFooter, Button } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import { isAccounting } from '../helper/General';
import { ModalPopup } from '../helper/ModalPopup';
import { AlertMessage } from '../helper/AlertBox'
import { creditExceedingCustomerAction } from '../redux/actions/CustomerActions'
{/* this components is used in showing credit exceeding list when user press on creedit excedding list inside side menu it is used in  creditExceedingCustomers*/}
class CustomerCreditExceedingList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			creditExceedingCustomers: props.creditExceedingCustomers,
			success: props.success,
			error: props.error,
		}

	}
	componentDidMount() {
			this.props.creditExceedingCustomerAction();
	}
    buttonFormatter = (cell, row) => {
        return (<button type="button" className="btn-primary-outline" onClick={() => this.props.handleOrderView('View Customer Order', row.customerId)}><i className="icon icon-eye"></i></button>);
    }
	render() {
		let columns = [
			{
				dataField: "customerId",
				text: this.props.t('CustomerId'),
				sort: true
			},
			{
				dataField: "fullName",
				text: this.props.t('CustomerName'),
				sort: true
			},
			{
				dataField: "orderCount",
				text: this.props.t('TotalOrder #'),
			},
            {
                dataField: "creditLimit",
                text: this.props.t('CreditLimit'),
            	formatter:(cell) => {
					return '฿'+parseFloat(cell).toFixed(2);
				}
            },
            {
                dataField: "usedLimit",
                text: this.props.t('LimitUsed'),
            	formatter:(cell) => {
					return '฿'+parseFloat(cell).toFixed(2);
				}
            },
            {
                dataField: "remainingLimit",
                text: this.props.t('RemaingLimit'),
            	formatter:(cell) => {
					return '฿'+parseFloat(cell).toFixed(2);
				}
            },
			{
				dataField: "createdAt",
				text: this.props.t('View'),
                formatter: this.buttonFormatter
            }
		];
		return (
			<React.Fragment>
				<Row>
					{this.state.success !== undefined && this.state.success !== '' ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				{this.props.creditExceedingCustomers !== undefined ? <Row>
					<Col lg="12" md="12">
						<ToolkitProvider
							keyField="customerId"
							data={this.props.creditExceedingCustomers}
							columns={columns}
						>
							{
								props => (
									<div key={'cusotmerDiv'}>
										<div className="mb-2">
										</div>
										<div className="table-responsive">
											<BootstrapTable  {...props.baseProps} pagination={paginationFactory()}   key="customerTable"/>
										</div>
									</div>
								)
							}
						</ToolkitProvider>
					</Col>
				</Row> : ''
				}
			</React.Fragment>
		)
	}
}
const mapStateToProps = state => {
		return {
			creditExceedingCustomers: state.customerReducer.creditExceedingCustomers,
			success: state.customerReducer.customerSuccess,
			error: state.customerReducer.customerError,
		}
}



const mapDispatchToProps = {
	creditExceedingCustomerAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerCreditExceedingList))
