import React, { Component, useState } from 'react'
import { Row, Col, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import imgUpload from '../assets/images/img-upload.png'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createOrderAction, updateOrderAction } from '../redux/actions/OrderActions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { orderValidation } from '../Validation/OrderValidationSchema';
import i18n from '../i18n';
import { createCustomerAction } from '../redux/actions/CustomerActions';
import { customerValidation } from '../Validation/CustomerValidationSchema';
import { FieldArray, Field, getIn } from 'formik';
import { isInvoicing, isAdmin, isAssortmentAdmin } from '../helper/General'
import { AlertMessage } from '../helper/AlertBox'
const Repeater = (props) => {
    let values = props.values;


    return (
        <React.Fragment>
            <div className="row" key={props.index}>
                <div className="col-md-3">
                    <select name={`material[${props.index}].metal`}
                        className={
                            props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index] !== undefined && props.errors.material[props.index].metal !== undefined && props.touched !== undefined && props.touched.material[props.index].metal
                                ? "form-control error"
                                : "form-control"

                        }
                        value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].metal}
                        onChange={props.handleChange}
                    >
                        <option value="0">-- {props.t('Metal')} --</option>
                        {
                            props.lookupData.Metal !== undefined &&
                            props.lookupData.Metal.map(metal =>
                                <option key={`metalOpt${metal.itemId}`} value={metal.itemId}>
                                    {metal.isPureMetal === true && metal.text.find(text => text.langId === 1).name}
                                </option>)
                        }
                    </select>
                    {props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index] !== undefined && props.errors.material[props.index].metal !== undefined && props.touched.material[props.index].metal && (<div className="input-feedback">{props.errors.material[props.index].metal}</div>)}
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <input name={`material[${props.index}].quantity`} onChange={props.handleChange} type="text" className="form-control" value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].quantity} />
                        {props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index].quantity && props.touched.material[props.index].quantity && (<div className="input-feedback">{props.errors.material[props.index].quantity}</div>)}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <select name={`material[${props.index}].unit`} onChange={props.handleChange} className="form-control" value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].unit}>
                            <option value="0">-- {props.t('Unit')} --</option>
                            {
                                props.lookupData.Unit !== undefined &&
                                props.lookupData.Unit.map(unit =>
                                    <option key={`unitOpt${unit.unitId}`} value={unit.unitId}>
                                        {unit.text.find(text => text.langId === 1).name}
                                    </option>)
                            }
                        </select>
                        {props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index].unit !== undefined && props.errors.material[props.index].unit && props.touched.material[props.index].unit && (<div className="input-feedback">{props.errors.material[props.index].unit}</div>)}
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <button type="button" className="btn-primary-outline" onClick={() => { props.removeItem(props.index) }}><i className="icon-close icon"></i></button>
                    </div>
                </div>
            </div>

        </React.Fragment >

    )
}
class OrderCreate extends Component {

    constructor(props) {
        super(props);
        let order = props.orderId !== '' && props.allOrders !== undefined && props.allOrders.find(order => {
            return order.orderId === this.props.orderId
        });
        this.fileTop = React.createRef();
        this.state = {
            order: {},
            customer: {},
            allOrders: [],
            customerId: order !== undefined ? order.customerId : '',
            orderRemarks: order !== undefined ? order.orderRemarks : '',
            pickupType: order !== undefined ? order.pickupType : '',
            pickupAddress: order !== undefined ? order.pickupAddress : '',
            deliveryType: order !== undefined ? order.deliveryType : '',
            deliveryAddress: order !== undefined ? order.deliveryAddress : '',
            quantity: order !== undefined ? order.quantity : '',
            oldOrderImages: order !== undefined && order.orderImages !== undefined ? order.orderImages : [],
            orderImages: [],
            orderFiles: [],
            deleteOrderImages: [],
            error: '',
            success: '',
            customerError: '',
            customerCreateSuccess: "",
            imagePreviewTop: imgUpload,
            formValid: true,
            orderCreated: false,
            orderId: '',
            lng: i18n.language === 'en' ? 1 : 2,
            addCustomerModal: false,
            checked: false,
            isCreditChecked: false
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        console.log(nextProps)
        let errors = '';
        if (this.state.error !== nextProps.error) {
            if (typeof nextProps.error === 'object') {
                errors = Object.values(nextProps.error)[0].join(',');
            }
            else {
                errors = nextProps.error;
            }

            this.setState( {
                error: errors,
                checked: true
            })
        }
        if (nextProps.success!==undefined && nextProps.success !== this.state.success) {
           this.setState(prevState => ({ addCustomerModal: !prevState.addCustomerModal, success: nextProps.success, checked: false }));
        }
       
        if (this.state.customerCreateSuccess !== nextProps.customerCreateSuccess) {
            this.setState({
                customerCreateSuccess: nextProps.customerCreateSuccess,
                addCustomerModal: !this.state.addCustomerModal
            })
        }

        if (nextProps.order !== undefined && this.state.order !== nextProps.order) {
            this.props.toggleButton();
        }
    }

    _handleImageChange(e) {
        e.preventDefault();
        let imageName = e.target.getAttribute('name');
        let file = ''
        if (imageName === 'topImage') {

            file = e.target.files[0]
        }


        if (file === undefined) {
            return false
        }
        let reader = new FileReader();

        reader.onloadend = () => {
            if (imageName === 'topImage') {

                const temp = [...this.state.orderImages];
                const files = [...this.state.orderFiles];
                temp.push(reader.result);
                files.push(file);
                this.setState({
                    orderImages: temp,
                    orderFiles: files
                })
            }
        }
        reader.readAsDataURL(file);

    }
    toggleModal = () => {

        this.setState(prevState => {
            return {
                addCustomerModal: !prevState.addCustomerModal
            }
        })
    }
    handleCheckBox = (e) => {

        this.setState((prevState) => ({ ...prevState, checked: !prevState.checked }));
    }
    closeImage = (e) => {
        e.target.parentNode.remove();
    }

    deleteImage = (e, imageId) => {
        e.target.parentNode.remove();
        let deleteImages = [...this.state.deleteOrderImages];
        deleteImages.push(imageId);
        this.setState({ deleteOrderImages: deleteImages });
    }

    pickupType = (e) => {
        if (e.target.value === "Drop Off by Customer") {
            document.getElementById('pickupBranch').classList.remove("hide")
            document.getElementById('pickupAddr').classList.add("hide")
        } else {
            document.getElementById('pickupAddr').classList.remove("hide")
            document.getElementById('pickupBranch').classList.add("hide")
        }
    }

    deliveryType = (e) => {
        if (e.target.value === "Customer self Pickup") {
            document.getElementById('deliveryBranch').classList.remove("hide")
            document.getElementById('deliveryAddr').classList.add("hide")
        } else {
            document.getElementById('deliveryAddr').classList.remove("hide")
            document.getElementById('deliveryBranch').classList.add("hide")
        }
    }

    onSubmit = (values) => {
        let order = {
            'customerId': values.customerId,
            'orderRemarks': values.orderRemarks,
            'pickupType': values.pickupType,
            'pickupAddress': values.pickupAddress,
            'deliveryType': values.deliveryType,
            'deliveryAddress': values.deliveryAddress,
            'quantity': values.quantity,
            'orderImages': [...this.state.orderFiles],
            'deleteOrderImages[]': this.state.deleteOrderImages
        }
        let formData = new FormData();


        for (let [key, value] of Object.entries(order)) {
            if (key === 'orderImages') {
                for (let i = 0; i < order[key].length; i++) {
                    formData.append('orderImages[]', order[key][i], order[key][i].name);
                }
            } else
                formData.append(key, value);

        }
        if (this.props.orderId === '')
            this.props.createOrder(formData);
        else {
            this.props.updateOrder(this.props.orderId, formData);
        }
    }

    handleCheckBox = (e) => {

        this.setState((prevState) => ({ ...prevState, checked: !prevState.checked }));
    }
    handleCreditCheckBox = (e) => {

        this.setState((prevState) => ({ ...prevState, isCreditChecked: !prevState.isCreditChecked }));
    }


    addRow(errors, touched, values, handleChange) {
        let rows = [...this.state.rows];
        rows.push(<Repeater errors={errors} touched={touched} values={values} handleChange={handleChange} index={rows.length + 1} removeItem={(index) => this.removeItem(index)} key={rows.length + 1} lookupData={this.state.lookupData} {...this.props} />)
        this.setState({ rows: rows })
    }
    removeItem(index) {
        const allRowItems = [...this.state.rows]
        allRowItems.splice(index - 1, 1)
        this.setState({ rows: allRowItems })
    }
    onModalSubmit = (values, checked) => {

        let customer = {
            'address': values.address,
            'email': values.email,
            'fullName': values.fullName,
            'phone': values.phone,
            'customerType': values.customerType,
            'is_have_metal': values.is_have_metal,
            'isCreditCustomer': values.isCreditCustomer
        }
        if (checked == true) {
            customer.is_have_metal = 1;
            customer.metals = values.metals;
        } else {
            customer.is_have_metal = 0;
        }
        if (values.customerType === 'Personal') {
            customer.personalId = values.personalId;
            delete values.taxId;
        }
        if (values.customerType === 'Company') {
            customer.taxId = values.taxId;
            delete values.personalId;
        }
        this.props.createCustomer(customer);
    }
    render() {
      
        let fieldStatus = (isAdmin() || isInvoicing()) ? true : false
        let langId = i18n.language === 'en' ? 1 : 2

        let branchOptions = this.props.lookupData !== undefined && this.props.lookupData.Branch.map(
            (branch) => { return <option key={`branchOpt${branch.branchId}`} value={branch.branchId} disabled={!branch.branchStatus}>{branch.text.find(text => text.langId === this.state.lng).name}</option> }
        )

        let metalOptions = this.props.lookupData !== undefined && this.props.lookupData.Metal.map((metal) => {
            return <option key={`metalOpt${metal.itemId}`} value={metal.itemId}>{metal.text.find(text => text.langId === langId).name}</option>
        });

        let unitOptions = this.props.lookupData !== undefined && this.props.lookupData.Unit.map((unit) => {
            return <option key={`unitOpt${unit.unitId}`} value={unit.unitId}>{unit.text.find(text => text.langId === langId).name}</option>
        });
        let customer = this.props.customerId !== "" ? this.state.customer : null;
       
        return (
            <React.Fragment>
                  <Row>
                                {this.state.error !== '' ? <Col lg="12" md="12"><AlertMessage error={this.state.error} /></Col> : ''}
                                {this.state.customerCreateSuccess !== '' ? <Col lg="12" md="12"><AlertMessage success={this.state.customerCreateSuccess} /></Col> : ''}
                </Row>
                <Formik
                    initialValues={{
                        'customerId': this.state.customerId !== undefined ? this.state.customerId : '',
                        'orderRemarks': this.state.orderRemarks !== undefined ? this.state.orderRemarks : '',
                        'pickupType': this.state.pickupType !== undefined ? this.state.pickupType : '',
                        'pickupAddress': this.state.pickupAddress !== undefined ? this.state.pickupAddress : '',
                        'deliveryType': this.state.deliveryType !== undefined ? this.state.deliveryType : '',
                        'deliveryAddress': this.state.deliveryAddress !== undefined ? this.state.deliveryAddress : '',
                        'quantity': this.state.quantity !== undefined ? this.state.quantity : '',
                        'orderImages': []
                    }}
                    onSubmit={values => this.onSubmit(values)}
                    validationSchema={Yup.object().shape({ ...orderValidation })}
                >{props => {
                    const {
                        values,
                        touched,
                        errors,
                        dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        handleReset,
                        setFieldValue
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                          
                            {this.props.orderId !== '' && <Row>
                                <Col lg="6" md="6">
                                    <Row className={`form-group`}>
                                        <label className="col-md-4">{this.props.t('Order')}#: </label>
                                        <Col md="8" className="align-items-center"><label>{this.props.orderId}</label></Col>
                                    </Row>
                                </Col>
                            </Row>}
                            <Row>
                                <Col lg="6" md="6">
                                    <Row className={`form-group`}>
                                        <label className="col-md-4">{this.props.t('Customer')}:</label>
                                        <Col md="8" className="align-items-center">
                                            <Input
                                                type="select"
                                                name="customerId"
                                                id="cName"
                                                value={values.customerId}
                                                disabled={this.props.orderId !== '' && values.customerId !== '' ? true : false}
                                                className={
                                                    errors.customerId && touched.customerId
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option value="">-- {this.props.t('customerName')} --</option>
                                                {
                                                    this.props.allCustomers !== undefined && this.props.allCustomers.map(customer => <option key={customer.customerId} value={customer.customerId}>{customer.fullName} {`(${this.props.t('CustomerId')}-${customer.customerId})`}</option>)
                                                }
                                            </Input>
                                            {errors.customerId && touched.customerId && (<div className="input-feedback">{errors.customerId}</div>)}
                                        </Col>
                                    </Row>
                                </Col>
                                {this.props.orderId === '' && isInvoicing() === false && isAssortmentAdmin()===false && <Col lg="6" md="6">
                                    <Row className="form-group">
                                        <Link to="#" className="add-more" data-toggle="modal" data-target="#addCustomer" title="add New Customer" onClick={this.toggleModal} ><i className="icon-plus icons"></i></Link>
                                    </Row>
                                </Col>}
                            </Row>
                            <Row>
                                <Col lg="6" md="6">
                                    <Row className={`form-group`} >
                                        <label className="col-md-4">{this.props.t('Remarks')}:</label>
                                        <Col md="8">
                                            <Input type="text"
                                                name="orderRemarks"
                                                value={values.orderRemarks}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.orderRemarks && touched.orderRemarks
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.orderRemarks && touched.orderRemarks && (<div className="input-feedback">{errors.orderRemarks}</div>)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6">
                                    <Row className={`form-group`}>
                                        <label className="col-md-4">{this.props.t('Quantity')}:</label>
                                        <Col md="8">
                                            <Input type="text"
                                                name="quantity"
                                                value={values.quantity}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.quantity && touched.quantity
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.quantity && touched.quantity && (<div className="input-feedback">{errors.quantity}</div>)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6">
                                    <Row className="form-group">
                                        <label className="col-md-4">{this.props.t('PickupType')}:</label>
                                        <Col md="8">
                                            <Input type="select"
                                                name="pickupType"
                                                id="pickup"
                                                value={values.pickupType}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.pickupType && touched.pickupType
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={e => {
                                                    this.pickupType(e);
                                                    setFieldValue('pickupType', e.target.value);
                                                    setFieldValue('pickupAddress', '');
                                                }}
                                                onBlur={handleBlur}>
                                                <option value=''>-- {this.props.t('PickupType')} --</option>
                                                <option value="Drop Off by Customer">{this.props.t('DropOffByCustomer')}</option>
                                                <option value="OneStop Pickup">{this.props.t('OneStopPickup')}</option>
                                            </Input>
                                            {errors.pickupType && touched.pickupType && (<div className="input-feedback">{errors.pickupType}</div>)}

                                        </Col>
                                    </Row>
                                    <Row className={`form-group ${values.pickupType === 'Drop Off by Customer' ? '' : 'hide'}`} id="pickupBranch">
                                        <label className="col-md-4">{this.props.t('Branch')}:</label>
                                        <Col md="8">
                                            <Input type="select"
                                                name="pickupAddress"
                                                value={values.pickupAddress}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.pickupAddress && touched.pickupAddress
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option value=''>-- {this.props.t('Branch')} --</option>
                                                {branchOptions}
                                            </Input>
                                            {errors.pickupAddress && touched.pickupAddress && (<div className="input-feedback">{errors.pickupAddress}</div>)}

                                        </Col>
                                    </Row>
                                    <Row className={`form-group ${values.pickupType === 'OneStop Pickup' ? '' : 'hide'}`} id="pickupAddr">
                                        <label className="col-md-4">{this.props.t('Address')}:</label>
                                        <Col md="8">
                                            <input type="text"
                                                name="pickupAddress"
                                                disabled={fieldStatus}
                                                value={values.pickupAddress}
                                                className={
                                                    errors.pickupAddress && touched.pickupAddress
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur} />
                                            {errors.pickupAddress && touched.pickupAddress && (<div className="input-feedback">{errors.pickupAddress}</div>)}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" md="6">
                                    <Row className="form-group">
                                        <label className="col-md-4">{this.props.t('DeliveryType')}:</label>
                                        <Col md="8">
                                            <Input type="select"
                                                name="deliveryType"
                                                id="delivery"
                                                value={values.deliveryType}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.deliveryType && touched.deliveryType
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={e => {
                                                    this.deliveryType(e);
                                                    setFieldValue('deliveryType', e.target.value);
                                                    setFieldValue('deliveryAddress', '');
                                                }}
                                                onBlur={handleBlur}>
                                                <option value=''>-- {this.props.t('DeliveryType')} --</option>
                                                <option value="Customer self Pickup">{this.props.t('CustomerselfPickup')}</option>
                                                <option value="Delivery by OneStop">{this.props.t('DeliverybyOneStop')}</option>
                                            </Input>
                                            {errors.deliveryType && touched.deliveryType && (<div className="input-feedback">{errors.deliveryType}</div>)}
                                        </Col>
                                    </Row>
                                    <Row className={`form-group ${values.deliveryType === 'Customer self Pickup' ? '' : 'hide'}`} id="deliveryBranch">
                                        <label className="col-md-4">{this.props.t('Branch')}:</label>
                                        <Col md="8">
                                            <Input type="select"
                                                name="deliveryAddress"
                                                value={values.deliveryAddress}
                                                disabled={fieldStatus}
                                                className={
                                                    errors.deliveryAddress && touched.deliveryAddress
                                                        ? "form-control error"
                                                        : "form-control"
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <option value=''>-- {this.props.t('Branch')} --</option>
                                                {branchOptions}
                                            </Input>
                                            {errors.deliveryAddress && touched.deliveryAddress && (<div className="input-feedback">{errors.deliveryAddress}</div>)}

                                        </Col>
                                    </Row>
                                    <Row className={`form-group ${values.deliveryType === 'Delivery by OneStop' ? '' : 'hide'}`} id="deliveryAddr">
                                        <label className="col-md-4">{this.props.t('Address')}:</label>
                                        <Col md="8"><input type="text"
                                            name="deliveryAddress"
                                            value={values.deliveryAddress}
                                            disabled={fieldStatus}
                                            className={
                                                errors.deliveryAddress && touched.deliveryAddress
                                                    ? "form-control error"
                                                    : "form-control"
                                            }
                                            onChange={handleChange}
                                            onBlur={handleBlur} />
                                            {errors.deliveryAddress && touched.deliveryAddress && (<div className="input-feedback">{errors.deliveryAddress}</div>)}
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row className="mb-5">
                                <Col lg="2" sm="3" xs="12">
                                    <Label htmlFor="item">{this.props.t('UploadImages')}:</Label>
                                </Col>
                                <Col lg="10" sm="9" xs="12">
                                    <div className="upload-image">
                                        {this.state.oldOrderImages.length > 0 ? this.state.oldOrderImages.map((item, index) => {
                                            return (
                                                <div className="imgPreview" key={index}>
                                                    <img src={item.url} className="img-fluid" alt='' />
                                                    <i className="icon-close icons close" onClick={(e) => this.deleteImage(e, item.orderImageId)}></i>
                                                </div>
                                            )
                                        }) : ''}
                                        {this.state.orderImages.length > 0 ? this.state.orderImages.map((item, index) => {
                                            return (
                                                <div className="imgPreview" key={index}>
                                                    <img src={item} className="img-fluid" alt='' />
                                                    <i className="icon-close icons close" onClick={(e) => this.closeImage(e)}></i>
                                                </div>
                                            )
                                        }) : ''}
                                        <div className="imgPreview">
                                            <Input type="file" name="topImage" onChange={(e) => this._handleImageChange(e)} accept="image/*" />
                                            <img src={imgUpload} className="img-fluid" alt='' />
                                        </div>
                                    </div>
                                </Col>
                                {!isAdmin() && !isInvoicing() && !isAssortmentAdmin() &&  <div className="col-md-12 mt-4">
                                    <div className="float-right">
                                        <button type="submit"
                                            className={`btn-primary-fill mr-2 ${isSubmitting ? 'btn-disabled' : ''}`}
                                            disabled={isSubmitting} >{this.props.t('Submit')}</button>
                                        <button type="button"
                                            className={`btn-primary-outline ${!dirty || isSubmitting ? 'btn-disabled' : ''}`}
                                            onClick={handleReset}
                                            disabled={!dirty || isSubmitting}
                                        >{this.props.t('Clear')}</button>
                                    </div>
                                </div>}
                            </Row>
                        </form>
                    )
                }}
                </Formik>

                <AddCustomerModal
                    modal={this.state.addCustomerModal}
                    toggle={() => this.toggleModal()}
                    checked={this.state.checked}
                    {...this.props}
                    handleCreditCheckBox={this.handleCreditCheckBox}
                    isCreditChecked={this.state.isCreditChecked}
                    unitOptions={unitOptions}
                    metalOptions={metalOptions}
                    customer={customer}
                    onModalSubmit={this.onModalSubmit}
                    customerValidation={customerValidation}
                    error={this.state.error !== '' && this.state.error !== undefined ? this.state.error : ''}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {

    return {
        allOrders: state.orderReducer.orders,
        lookupData: state.generalReducer.lookupData,
        error: state.orderReducer.orderError,
        order: state.orderReducer.order,
        customerCreateSuccess: state.customerReducer.customerSuccess,
        customerError: state.customerReducer.customerError,
        customer: state.customerReducer.customer,
    }
}

const mapDispatchToProps = {
    createOrder: createOrderAction,
    updateOrder: updateOrderAction,
    createCustomer: createCustomerAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(OrderCreate));

export const AddCustomerModal = (props) => {

    const { modal } = props;
    const { toggle } = props;
    const { unitOptions, metalOptions, customer, error, onModalSubmit } = props;
    let check = props.error !== "" ? true : false;
    const [checked, handleCheckBox] = useState(check)
    // const invoiceOptions = allInvoices !== undefined && allInvoices.filter(invoice => invoice.roleId !== 3).map(user => <option key={`user-${user.userId}`} value={user.userId}>{user.fullName}</option>);
    return (
        <div>
            <Modal className="addCustomerModal" isOpen={modal} toggle={toggle} size="lg">
                <ModalHeader toggle={toggle}>{props.t('AddCustomer')}</ModalHeader>
                <ModalBody>
                    <React.Fragment>
                        <Formik
                            initialValues={{

                                fullName: customer !== null && customer.fullName !== undefined ? customer.fullName : '',
                                phone: customer !== null && customer.phone !== undefined ? customer.phone : '',
                                email: customer !== null && customer.email !== undefined ? customer.email : '',
                                address: customer !== null && customer.address !== undefined ? customer.address : '',
                                creditLimit: customer !== null && customer.creditLimit !== undefined ? customer.creditLimit : '',
                                customerType: customer !== null && customer.customerType !== undefined ? customer.customerType : '',
                                personalId: customer !== null && customer.personalId !== undefined ? customer.personalId : '',
                                taxId: customer !== null && customer.taxId !== undefined ? customer.taxId : '',
                                metals: [{ 'quantity': '', 'unitId': '', 'metalId': '' }],

                            }}
                            onSubmit={values => onModalSubmit(values, checked)}
                            validationSchema={Yup.object().shape({
                                ...customerValidation,
                                personalId: Yup.string().trim()
                                    .required((values) => {
                                        if (values.customerType === 'Personal') {
                                            return 'Please enter personal id.'
                                        } else {
                                            return
                                        }
                                    }),
                                taxId: Yup.string().trim()
                                    .required((values) => {
                                        if (values.customerType === 'Company') {
                                            return 'Please enter tax id.'
                                        } else {
                                            return
                                        }
                                    }),
                                creditLimit: Yup.number()
                                    .required((values) => {
                                        if (props.isCreditChecked) {
                                            return 'Please enter credit limit.'
                                        } else {
                                            return
                                        }
                                    }),
                                metals: Yup.array()
                                    .of(
                                        Yup.object().shape({
                                            metalId: Yup.string()
                                                .required((values) => {
                                                    if (checked) {
                                                        return 'Please select metal.'
                                                    } else {
                                                        return
                                                    }
                                                }),
                                            quantity: Yup.number()
                                                .required(() => {
                                                    if (checked) {
                                                        return 'Please enter quantity.'
                                                    } else {
                                                        return
                                                    }
                                                }).when("unitId", {
                                                    is: val => (val && val == 10 ? true : false),
                                                    then: Yup.number().min(
                                                        10,
                                                        "Quantity less than 10 Mg. is not accepted."
                                                    )
                                                }),
                                            unitId: Yup.string()
                                                .required(() => {
                                                    if (checked) {
                                                        return 'Please select unit.'
                                                    } else {
                                                        return
                                                    }
                                                })
                                        })
                                    )
                            })
                            }
                        >
                            {formProps => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    handleReset
                                } = formProps;
                                return (

                                    <form onSubmit={handleSubmit} >
                                        {error !== '' && error !== undefined ?
                                            <Row>
                                                <Col lg="12" md="12"><AlertMessage error={error} /></Col>
                                            </Row>
                                            : ''}
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('CustomerName')}</label>
                                                    <input
                                                        type="text"
                                                        name="fullName"
                                                        value={values.fullName}
                                                        className={
                                                            errors.fullName && touched.fullName
                                                                ? "form-control error"
                                                                : "form-control"
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    {errors.fullName && touched.fullName && (<div className="input-feedback">{errors.fullName}</div>)}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('Mobile')} #</label>
                                                    <input
                                                        type="text"
                                                        name="phone"
                                                        value={values.phone}
                                                        className={
                                                            errors.phone && touched.phone
                                                                ? "form-control error"
                                                                : "form-control"
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                    />
                                                    {
                                                        errors.phone && touched.phone && (
                                                            <div className="input-feedback">{errors.phone}</div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('Email')}</label>
                                                    <input
                                                        type="text"
                                                        name="email"
                                                        value={values.email}
                                                        className={
                                                            errors.email && touched.email
                                                                ? "form-control error"
                                                                : "form-control"
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                    />
                                                    {
                                                        errors.email && touched.email && (
                                                            <div className="input-feedback">{errors.email}</div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('Address')}</label>
                                                    <input
                                                        type="text"
                                                        name="address"
                                                        value={values.address}
                                                        className={
                                                            errors.address && touched.address
                                                                ? "form-control error"
                                                                : "form-control"
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                    />
                                                    {
                                                        errors.address && touched.address && (
                                                            <div className="input-feedback">{errors.address}</div>
                                                        )}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('TypeOfCustomer')}</label>
                                                    <div className="col-12">
                                                        <div className="custom-control form-check form-check-inline">
                                                            <input
                                                                id="inline-checkbox1"
                                                                name="customerType"
                                                                type="radio"
                                                                className="form-check-input custom-control-input form-check-input"
                                                                value="Personal"
                                                                onChange={handleChange}
                                                                checked={values.customerType === "Personal" ? true : false}
                                                            />
                                                            <label htmlFor="inline-checkbox1" className="form-check-label custom-control-label form-check-label">{props.t('Personal')}</label>
                                                        </div>
                                                        <div className="custom-control form-check form-check-inline">
                                                            <input
                                                                id="inline-checkbox2"
                                                                name="customerType"
                                                                type="radio"
                                                                className="form-check-input custom-control-input form-check-input"
                                                                value="Company"
                                                                onChange={handleChange}
                                                                checked={values.customerType === "Company" ? true : false}
                                                            />
                                                            <label htmlFor="inline-checkbox2" className="form-check-label custom-control-label form-check-label">{props.t('Company')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${values.customerType === 'Personal' ? "col-md-4" : "col-md-4 hide"}`}>
                                                <div className="form-group">
                                                    <label>{props.t('PersonalId')}#</label>
                                                    <input
                                                        type="text"
                                                        name="personalId"
                                                        value={values.personalId}
                                                        className={errors.personalId && touched.personalId
                                                            ? "form-control error"
                                                            : "form-control"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.personalId && touched.personalId && (<div className="input-feedback">{errors.personalId}</div>)}
                                                </div>
                                            </div>
                                            <div className={`${values.customerType === 'Company' ? "col-md-4" : "col-md-4 hide"}`}>
                                                <div className="form-group">
                                                    <label>{props.t('TaxID')}#</label>
                                                    <input
                                                        type="text"
                                                        name="taxId"
                                                        value={values.taxId}
                                                        className={errors.taxId && touched.taxId
                                                            ? "form-control error"
                                                            : "form-control"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.taxId && touched.taxId && (<div className="input-feedback">{errors.taxId}</div>)}
                                                </div>
                                            </div>
                                            {isAdmin() && <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('IsCreditCustomer')}</label>
                                                    <div className="col-md-12">
                                                        <div className="custom-control form-check form-check-inline w-100">
                                                            <input
                                                                id="checkbox"
                                                                type="checkbox"
                                                                className="form-check-input custom-control-input form-check-input"
                                                                value="IsCredit"
                                                                onChange={props.handleCreditCheckBox}
                                                                checked={props.isCreditChecked}
                                                            />
                                                            <label htmlFor="checkbox" className="form-check-label custom-control-label form-check-label">{props.t('IsCredit')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className={`${props.isCreditChecked ? "col-md-4" : "col-md-4 hide"}`} id="isCreditCustomer">
                                                <div className="form-group">
                                                    <label>{props.t('CreditLimit')} (฿)</label>
                                                    <input
                                                        type="text"
                                                        name="creditLimit"
                                                        value={values.creditLimit}
                                                        className={errors.creditLimit && touched.creditLimit
                                                            ? "form-control error"
                                                            : "form-control"}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.creditLimit && touched.creditLimit && (<div className="input-feedback">{errors.creditLimit}</div>)}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label>{props.t('ProvidesOwnMetal')}</label>
                                                    <div className="col-md-12">
                                                        <div className="custom-control form-check form-check-inline w-100">
                                                            <input
                                                                id="checkbox1"
                                                                type="checkbox"
                                                                className="form-check-input custom-control-input form-check-input"
                                                                value="ProvidesMetal"
                                                                onChange={() => handleCheckBox(!checked)}
                                                                checked={checked}
                                                            />
                                                            <label htmlFor="checkbox1" className="form-check-label custom-control-label form-check-label">{props.t('ProvidesMetal')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={`${checked ? "col-md-12" : "col-md-12 hide"}`} id="provideMetal">
                                                <FieldArray name="metals">
                                                    {({ push, remove }) => (
                                                        <div>
                                                            {values.metals !== undefined && values.metals.map((p, index) => {
                                                                const metalName = `metals[${index}].metalId`;
                                                                const metalErrorMsg = getIn(errors, metalName);

                                                                const quantityName = `metals[${index}].quantity`;
                                                                const quantityErrorMsg = getIn(errors, quantityName);

                                                                const unitName = `metals[${index}].unitId`;
                                                                const unitErrorMsg = getIn(errors, unitName);
                                                                return (
                                                                    <React.Fragment key={index}>
                                                                        <div className="row">
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <label>Metal</label>
                                                                                    <Field
                                                                                        name={metalName}
                                                                                        as="select"
                                                                                        className="form-control"
                                                                                        value={p.metalId}
                                                                                        onChange={handleChange}
                                                                                    >
                                                                                        <option key="metalsOptempty" value="metals">--{props.t('Metal')} --</option>
                                                                                        {metalOptions}
                                                                                    </Field>
                                                                                    {metalErrorMsg && <div className="input-feedback">{metalErrorMsg}</div>}

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <label>Quantity</label>
                                                                                    <Field
                                                                                        name={quantityName}
                                                                                        className="form-control"
                                                                                        value={p.quantity}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                    {quantityErrorMsg && <div className="input-feedback">{getIn(errors, `metals[${index}].quantity`)}</div>}

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                <div className="form-group">
                                                                                    <label>Unit</label>
                                                                                    <Field
                                                                                        name={unitName}
                                                                                        as="select"
                                                                                        className="form-control"
                                                                                        value={p.unitId}
                                                                                        onChange={handleChange}
                                                                                    >
                                                                                        <option key="unitOpt" value="">--{props.t('Unit')} --</option>
                                                                                        {unitOptions}
                                                                                    </Field>
                                                                                    {unitErrorMsg && <div className="input-feedback">{getIn(errors, `metals[${index}].unitId`)}</div>}

                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-3">
                                                                                {index != 0 ? <div className="form-group">
                                                                                    <label></label>
                                                                                    <button type="button" className="btn-primary-outline d-block mt-2" onClick={() => { remove(index) }}><i className="icon-close icon"></i></button>
                                                                                </div> : ''}
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <button type="button" className="btn-primary-fill float-right" onClick={() => push({ metalId: '', quantity: '', unitId: '' })}>Add Metal</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <div className="float-right">
                                                    <button
                                                        type="submit"
                                                        className={`btn-primary-fill mr-2`}
                                                    >
                                                        {props.t('Submit')}
                                                    </button>
                                                    <button type="button"
                                                        className={`btn-primary-outline`}
                                                        onClick={handleReset}
                                                        data-dismiss="modal"
                                                    >{props.t('Clear')}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </React.Fragment>
                </ModalBody>
            </Modal>
        </div>
    );
}