import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from 'react-bootstrap-table2-paginator';
import { AlertMessage } from '../helper/AlertBox'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
class ItemMasterList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allUnit: [],
			allMetal: [],
			allProcess: [],
			success: props.success
		}
	}
	static getDerivedStateFromProps(nextProps, state) {
		if (nextProps.lookupData !== undefined) {
			return {
				allUnit: nextProps.lookupData['Unit'],
				allMetal: nextProps.lookupData['Metal'],
				allProcess: nextProps.lookupData['Process']
			}
		}
		return null
	}
	getUnit = (unitId) => {
		let langId = this.props.i18n.language !== 'en' ? 2 : 1;
		let allUnits = [...this.state.allUnit]
		let singleUnitObject = allUnits.find(singleUnit => singleUnit.unitId === unitId)
		return singleUnitObject.text.find(unt => unt.langId === langId).name

	}
	removeItem = (row) => {
		let { allMetal, allProcess } = this.state

		if (row.isProcess !== 0) {
			let processIndex = allProcess.findIndex(pp => pp.itemId === row.itemId)
			allProcess.splice(processIndex, 1);
			this.setState({
				allProcess
			})
		} else {
			let metalIndex = allMetal.findIndex(pp => pp.itemId === row.itemId)
			allMetal.splice(metalIndex, 1);
			this.setState({
				allMetal
			})
		}
		this.props.removeItem(row.itemId)
	}
	render() {
        const { SearchBar } = Search;


		this.columns = [

			{
				dataField: "itemId",
				text: this.props.t('ItemId')
			},
			{
				dataField: "itemName",
				text: this.props.t('ItemName'),
				formatter: (cell, row) => {
					let itemName = '';
					let langId = this.props.i18n.language !== 'en' ? 2 : 1;
					if (row.text.length > 1) {
						itemName = row.text.find(txt => txt.langId === langId).name
					}
					if (row.text.length === 1) {
						itemName = row.text.find(txt => txt.langId === 1).name
					}
					return itemName;
				}
			},
			{
				dataField: "price",
				text: this.props.t('Rate'),
				formatter: (cell, row) => {
					return cell + ' per ' + this.getUnit(row.unitId)
				}
			},
			{
				dataField: "action",
				text: this.props.t('Action'),
				formatter: (cell, row) => {
					return (
						<React.Fragment>
							<button type="button" className="btn mr-2" onClick={() => this.props.handleLayout('Edit Item', row)}><i className="icon-pencil icon"></i></button>
							<button type="button" className="btn" onClick={() => { if (window.confirm(`Are you sure you wish to delete Item #${row.itemId}?`)) this.removeItem(row) }}><i className="icon-trash icon"></i></button>
						</React.Fragment>
					)
				}
			}
		];

		return (
			<React.Fragment>
				<Row>
					{this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				<div>
					{this.props.lookupData !== undefined ?
						<ToolkitProvider
							key="lookupId"
							keyField="lookupId"
							data={[...this.state.allMetal, ...this.state.allProcess]}
							columns={this.columns}
							search={{
                                searchFormatted: true
                            }}
						>
                            {
                                props => (<div key={'itemsDiv'}>
									<div className="mb-2">
										<SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
									</div>
									<div>
										<BootstrapTable  {...props.baseProps} pagination={paginationFactory()} />
									</div>
								</div>)
                            }
						</ToolkitProvider> : ''}
				</div>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		error: state.itemReducer.itemError,
		success: state.itemReducer.itemSuccess
	}
}
export default withTranslation()(connect(mapStateToProps)(ItemMasterList))