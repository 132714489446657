import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux';
import { Formik} from 'formik';
import * as Yup from 'yup';
import i18n from '../i18n';
import { transferMoneyAction } from '../redux/actions/LedgerActions';
import { AlertMessage } from '../helper/AlertBox'
import { transferMoneyValidation } from '../Validation/TransferMoneyValidationSchema';
class TransferMoneyForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			allUsers: props.allUsers,
			error: '',
			userData:''
		}
	}

	static getDerivedStateFromProps(nextProps, state) {
		let user = nextProps.allUsers !== undefined && nextProps.allUsers.find(user => {
			return user.userId === nextProps.userId
		});

		if (nextProps.userId !== undefined && Object.entries(state.user).length === 0) {

			return {
				...state,
				allUsers: nextProps.allUsers,
				user
			}
		}


		let errors = '';
		if (state.error !== nextProps.error) {

			if (typeof nextProps.error === 'object') {

				errors = Object.values(nextProps.error)[0].join(',');

			}
			else {

				errors = nextProps.error;
			}
			return {
				error: errors
			}
		}

		if (nextProps.success !== undefined && nextProps.success !== "") {
			nextProps.updateLayout("User List");
		}
		return null;

	}
	render() {
		
		return (
			<React.Fragment>
				<Formik
					enableReinitialize={true}
					initialValues={{
							user_id:'' ,
							amount: '',
					}}
					validationSchema={Yup.object().shape({
						...transferMoneyValidation
					})}
					onSubmit={values => {
						this.props.transferMoney(values)
						this.props.updateLayout('Ledger List')
					}}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit
						} = props;
						

						return (
							<form onSubmit={handleSubmit}>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">{this.props.t('Users')}</label>
										<div className="col-md-9">
											{this.props.allUsers
												&&
											<select
												name="user_id"
												className={
													errors.user_id && touched.user_id
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												defaultValue={this.props.userId }
												disabled={this.props.userId !== '' && this.props.userId !== undefined ? true : false}
											>
												<option value="">--- {this.props.t('SelectUsers')} --- </option>

												{this.props.allUsers.filter(user=>user.roleId===1).map(user => <option value={user.userId} key={user.userId}>{user.fullName}</option>)}
											</select>
										}
											{errors.roleId && touched.roleId && (<div className="input-feedback">{errors.roleId}</div>)}
										</div>
									</div>
									
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">{this.props.t('Amount')}</label>
										<div className="col-md-9">
											<input
												type="text"
												name="amount"
												value={values.amount}
												className={
													errors.amount && touched.amount
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											{errors.amount && touched.amount && (<div className="input-feedback">{errors.amount}</div>)}
										</div>

								</div>
								<div className="col-md-12 mt-4">
									<div className="float-right">
										<button type="submit"
											className={`btn-primary-fill mr-2`}
										>{this.props.t('Submit')}</button>
										<button type="button"
											className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
											onClick={() => { this.props.updateLayout('Ledger List') }}
										>{this.props.t('Cancel')}</button>
										{/* <button type="button" className="btn-primary-outline" onClick={() => { this.props.updateLayout('User List') }}>{this.props.t('Cancel')}</button> */}
									</div>
								</div>
							</div>
						</form>)
					}}

				</Formik>

			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {

	return {
		allUsers: state.userReducer.users,
		success: state.userReducer.userSuccess,
		error: state.userReducer.userError,
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = {
	transferMoney:transferMoneyAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TransferMoneyForm));
