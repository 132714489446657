import React, { Component } from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTranslation } from 'react-i18next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import { getSalesAction } from '../redux/actions/ReportActions'
import { connect } from 'react-redux';
import i18n from '../i18n';
import dateFormat from 'dateformat';

const { ExportCSVButton } = CSVExport;

class SalesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            salesReport: this.props.salesReport,
            lookupData: this.props.lookupData,
            filters: {}
        }
    }
    static getDerivedStateFromProps(nextProps, state) {
        let nextRes = { ...state };

        if (nextProps.salesReport !== undefined)
            nextRes.salesReport = nextProps.salesReport;

        return { ...nextRes };
    }

    componentDidMount() {
        this.props.getSalesAction(this.state.filters);
    }

    handleChange = (e) => {
        let filter = { ...this.state.filters };
        filter[e.target.name] = e.target.value;
        this.props.getSalesAction(filter);
        this.setState({ filters: filter });
    }
    dateFormatter = (cell) => {
        return dateFormat(cell, "dd/mm/yyyy")
    }

    render() {
        const { SearchBar } = Search;
        const columns = [
            {
                dataField: "itemId",
                text: this.props.t('Material'),
                sort: true,
                formatter: (cell, row) => {
                    let langId = i18n.language === 'en' ? 1 : 2;
                    let metalData = this.state.lookupData !== undefined && this.state.lookupData.Metal !== undefined && this.state.lookupData.Metal.find(item => item.itemId === cell);
                    let metalText = metalData !== undefined && metalData.text !== undefined && metalData.text.length > 0 && metalData.text.find(text => text.langId === langId)
                    return (<span>{metalText !== undefined && metalText.name}</span>);
                    // console.log(cell, row)
                    // return (<button type="button" className="btn-primary-outline" onClick={() => this.props.handleOrderView('View Customer Order', row.customerId)}><i className="icon icon-eye"></i></button>);
                }
            },
            {
                dataField: "amountUsed",
                text: this.props.t('QuantityInGms'),
                sort: true,
                formatter: (cell) => {
                    return parseFloat(cell).toFixed(2);
                }
            },
            {
                dataField: "dateCreated",
                text: this.props.t('Date'),
                sort: true,
                formatter: this.dateFormatter
            },
            {
                dataField: "rate",
                text: this.props.t('Rate฿'),
                sort: true,
                formatter: (cell) => {
                    return parseFloat(cell).toFixed(2);
                }
            }
        ];

        return (
            <React.Fragment>

                {this.state.salesReport !== undefined ? <ToolkitProvider
                    keyField="rowNo"
                    key={`salesReportTable${new Date().getTime()}`}
                    data={this.state.salesReport}
                    columns={columns}
                    search
                >
                    {
                        props => (
                            <div>
                                <div className="row mb-2">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.props.t('Material')}</label>
                                            <select
                                                name="itemId"
                                                className="form-control"
                                                defaultValue={this.state.filters['itemId']}
                                                onChange={(e) => this.handleChange(e)}
                                            >
                                                <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                {this.state.lookupData !== undefined && this.state.lookupData['Metal'] !== undefined && this.state.lookupData['Metal'].length > 0 && this.state.lookupData['Metal'].map(data => {
                                                    return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                        {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                    </option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.props.t('From') + ' ' + this.props.t('Date')}</label>
                                            <input name="fromDate" type="Date" defaultValue={this.state.filters['fromDate']}
                                                className="form-control" onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label>{this.props.t('To') + ' ' + this.props.t('Date')}</label>
                                            <input name="toDate" type="Date" defaultValue={this.state.filters['toDate']}
                                                className="form-control" onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label></label>
                                            <ExportCSVButton className="btn btn-primary-fill btn-sm my-2 float-right mt-4" {...props.csvProps}>{this.props.t('Generate CSV')}</ExportCSVButton>
                                        </div>
                                    </div>
                                </div>
                                <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
                            </div>
                        )
                    }
                </ToolkitProvider> : ''}

            </React.Fragment>
        )
    }
}
const mapStateToProps = state => {
    return {
        salesReport: state.reportReducer.salesReport,
        lookupData: state.generalReducer.lookupData,
    }
}

const mapDispatchToProps = {
    getSalesAction,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SalesList));