import Api from '../Api'

export const orders = () => {
    return Api.get('order');
};

export const createOrder = (order) => {
    return Api.post('order', order);
}

export const updateOrder = (orderId, order) => {
    return Api.post('order/' + orderId, order);
}

export const updateOrderStatus = (orderId, orderStatus) => {
    return Api.put('order/update-status/' + orderId, orderStatus);
}

export const deleteOrder = (orderId) => {
    return Api.delete('order/' + orderId);
}

export const deliverOrderToCustomer = (order) => {
    return Api.post('order/deliver', order);
}

export const deliveredOrderedItems = () => {
    return Api.get('delivered-invoice-list');
}

export const verifyPayment = (payment) => {
    return Api.post('verify-payment',payment);
}