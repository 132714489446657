import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const branchReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.BRANCH_LIST:
            return {
                ...state,
                branches: action.value
            };

        case actionTypes.BRANCH_CREATE:
            return {
                ...state,
                branches: [...state.branches,action.value],
                branch: action.value,
                branchSuccess: 'Branch created successfully.'
            };
        case actionTypes.BRANCH_UPDATE:
            const allBranches= [...state.branches]
            let existIndex = allBranches.findIndex(inv=>inv.branchId == action.value.branchId)

            if(existIndex >= 0){
                allBranches[existIndex]=action.value
            }
            return {
                ...state,
                branches: allBranches,
                branch: action.value,
                branchSuccess: 'Branch Updated successfully.'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                branchError: action.value,
                branchSuccess:''
            };
        default:
            return {...state,branchError: '', branchSuccess:''};
    }
}