import * as Yup from 'yup';

export const itemValidation = {
    itemName: Yup.string().trim()
        .required('Please enter item name.'),
    itemNameInTh: Yup.string().trim()
        .required('Please enter item name in thai.'),
    rate: Yup.string().trim()
        .required('Please enter rate.'),
    // itemType: Yup.string()
    //     .required('Please select item type').nullable(),
    unitId: Yup.string().trim()
        .required('Please select unit.').nullable(),
    taxRate: Yup.string().trim()
        .required('Please enter tax rate.').nullable(),
    lossPercentageProduction: Yup.string().trim()
        .required('Please enter loss percentage production.').nullable(),
    lossPercentageBilling: Yup.string().trim()
        .required('Please enter loss percentage billing.').nullable()

}