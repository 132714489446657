import Api from '../Api'

export const userData=() => {
    return  Api.get('auth/me').then(res=>res.data).catch(err=> sendServerError("UserData",err))
 }
 
 export const lookupData=() => {
     return  Api.get('lookup').then(res=>res.data).catch(err=>sendServerError("lookupData",err))
 }
 
 export const uploadfile=(data)=>{
     return Api.post('upload',data).then(res=>console.log(res.data)).catch(err=>console.log(err))
 }
 const sendServerError=(type,err)=>{
     console.log(type,err);
     return false
 }