import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import UserAdd from '../components/UserAdd'
import UserList from '../components/UserList'
import ViewUser from '../components/ViewUser'
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "User List",
            userId: null
        }
    }

    userList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('UserList')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Add User") }}>{this.props.t('AddUser')}  </button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <UserList handleOrderView={this.updateLayout} />
                </CardBody>
            </Card>
        )
    }

    userCreate = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('AddUser')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <UserAdd updateLayout={this.updateLayout} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    viewUserDetail = (props) => {
        return (<Card className="card-box">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6">
                        <div className="title">{this.props.t('UserDetails')} </div>
                    </div>
                    <div className="col-md-6">
                        <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                    </div>
                </div>
            </div>
            <CardBody>
                <ViewUser updateLayout={this.updateLayout} userId={this.state.userId} {...props} />
            </CardBody>
        </Card>
        )
    }

    updateUser = () => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('EditUser')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("User List") }}>{this.props.t('UserList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <UserAdd updateLayout={this.updateLayout} {...this.props} userId={this.state.userId} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, id = null) => {
        this.setState({ customerLayout: layout, userId: id });
    }

    getCustomerComponent(layout) {
        switch (layout) {
            case "User List": return this.userList(this.props);

            case "Add User": return this.userCreate(this.props);

            case "Edit User": return this.updateUser(this.props);

            case "View User": return this.viewUserDetail(this.props);

            default:
                return false;
        }
    }
    componentDidMount() {
        document.title = "Users"
    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                customerLayout: "User List"
            })
        }
    }

    render() {

        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {this.getCustomerComponent(this.state.customerLayout)}
                    </div>
                </div>
            </div>
        )
    }
}

export default withOrderInvoiceHoc(Users)