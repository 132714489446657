import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getRole } from '../helper/General';
import { updateUserStatus } from '../services/UserServices';
import { AlertMessage } from '../helper/AlertBox';

class UserList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			allUsers: this.props.allUsers,
			success: props.success,
			error: props.error,
		}

	}
	handleStatusChange = (e, userId) => {
		let userStatus = { "userId": userId, "status": e.target.checked ? 1 : 0 }
		updateUserStatus(userStatus).then(res => {
			this.setState({
				success: res.data.message
			})
		}).catch(err => console.log(err))
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			allUsers: nextProps.allUsers
		})
	}
	userStatusFormatter = (cell, row) => {

		return (
			<div className="d-flex">
				<label>{this.props.t('Inactive')}</label>
				<label className="label-switch switch-primary">
					<input type="checkbox" className="switch switch-bootstrap status" name="status" defaultChecked={row.isActive == 1 ? true : false} defaultValue={row.isActive} onChange={(e) => this.handleStatusChange(e, row.userId)} />
					<span className="lable"></span>
				</label>
				<label>{this.props.t('Active')}</label>
			</div>
		)
	}
	userTypeFormatter = (cell, row) => {
		return getRole(row.roleId)
	}

	buttonFormatter = (cell, row) => {

		return (
			<React.Fragment>
				<button type="button" className="btn" onClick={() => this.props.handleOrderView('Edit User', row.userId)}><i className="icon-pencil icon"></i></button>
				<button type="button" className="btn" onClick={() => this.props.handleOrderView('View User', row.userId)}><i className="icon-eye icon"></i></button>
			</React.Fragment>

		);
	}



	render() {
		const { SearchBar } = Search;

		let columns = [
			{
				dataField: "fullName",
				text: this.props.t('Name'),
				sort: true
			},
			{
				dataField: "email",
				text: this.props.t('Email')
			},
			{
				dataField: "userType",
				text: this.props.t('UserType'),
				formatter: this.userTypeFormatter,
			},
			{
				dataField: "isActive",
				text: this.props.t('Status'),
				formatter: this.userStatusFormatter
			},
			{
				dataField: "action",
				text: this.props.t('Action'),
				formatter: this.buttonFormatter
			}
		];

		if (this.props.allUsers === undefined) {
			return ''
		}
		return (
			<React.Fragment>
				<Row>
					{this.state.success !== undefined && this.state.success !== '' ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<Col lg="12" md="12">
						<ToolkitProvider
							keyField="userId"
							key={`userTable`}
							data={this.props.allUsers}
							columns={columns}
							search
						>
							{
								props => (
									<div>
										<div className="mb-2">
											<SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
										</div>
										<div className="table-responsive">
											<BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
										</div>
									</div>
								)
							}
						</ToolkitProvider>
					</Col>
				</Row>
				{/* <div className="table-responsive">
					<BootstrapTable keyField="id" data={this.customer} columns={this.columns} />
					<Pagination className="float-right">
						<PaginationItem>
							<PaginationLink previous tag="button"></PaginationLink>
						</PaginationItem>
						<PaginationItem active>
							<PaginationLink tag="button">1</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">2</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">3</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink tag="button">4</PaginationLink>
						</PaginationItem>
						<PaginationItem>
							<PaginationLink next tag="button"></PaginationLink>
						</PaginationItem>
					</Pagination>
				</div> */}
			</React.Fragment>
		)

	}
}


const mapStateToProps = state => {
	return {
		allUsers: state.userReducer.users,
		success: state.userReducer.userSuccess,
		error: state.userReducer.userError,
	}
}
export default withTranslation()(connect(mapStateToProps)(UserList))