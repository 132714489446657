import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Formik, FieldArray, Field, getIn } from 'formik';
import * as Yup from 'yup';
import i18n from '../i18n';
import { createCustomerAction, updateCustomerAction } from '../redux/actions/CustomerActions';
import { customerValidation } from '../Validation/CustomerValidationSchema';
import { AlertMessage } from '../helper/AlertBox'
import { isAdmin } from '../helper/General'

const Repeater = (props) => {
	let values = props.values;


	return (
		<React.Fragment>
			<div className="row" key={props.index}>
				<div className="col-md-3">
					<select name={`material[${props.index}].metal`}
						className={
							props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index] !== undefined && props.errors.material[props.index].metal !== undefined && props.touched !== undefined && props.touched.material[props.index].metal
								? "form-control error"
								: "form-control"

						}
						value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].metal}
						onChange={props.handleChange}
					>
						<option value="0">-- {props.t('Metal')} --</option>
						{
							props.lookupData.Metal !== undefined &&
							props.lookupData.Metal.map(metal =>
								<option key={`metalOpt${metal.itemId}`} value={metal.itemId}>
									{metal.isPureMetal === true && metal.text.find(text => text.langId === 1).name}
								</option>)
						}
					</select>
					{props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index] !== undefined && props.errors.material[props.index].metal !== undefined && props.touched.material[props.index].metal && (<div className="input-feedback">{props.errors.material[props.index].metal}</div>)}
				</div>
				<div className="col-md-3">
					<div className="form-group">
						<input name={`material[${props.index}].quantity`} onChange={props.handleChange} type="text" className="form-control" value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].quantity} />
						{props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index].quantity && props.touched.material[props.index].quantity && (<div className="input-feedback">{props.errors.material[props.index].quantity}</div>)}
					</div>
				</div>
				<div className="col-md-3">
					<div className="form-group">
						<select name={`material[${props.index}].unit`} onChange={props.handleChange} className="form-control" value={values.material !== undefined && values.material[props.index] !== undefined && values.material[props.index].unit}>
							<option value="0">-- {props.t('Unit')} --</option>
							{
								props.lookupData.Unit !== undefined &&
								props.lookupData.Unit.map(unit =>
									<option key={`unitOpt${unit.unitId}`} value={unit.unitId}>
										{unit.text.find(text => text.langId === 1).name}
									</option>)
							}
						</select>
						{props.errors !== undefined && props.errors.material !== undefined && props.errors.material[props.index].unit !== undefined && props.errors.material[props.index].unit && props.touched.material[props.index].unit && (<div className="input-feedback">{props.errors.material[props.index].unit}</div>)}
					</div>
				</div>
				<div className="col-md-3">
					<div className="form-group">
						<button type="button" className="btn-primary-outline" onClick={() => { props.removeItem(props.index) }}><i className="icon-close icon"></i></button>
					</div>
				</div>
			</div>

		</React.Fragment >

	)
}


class CustomerAdd extends Component {
	constructor(props) {
		super(props);




		this.state = {
			customer: {},
			allCustomers: this.props.allCustomers,
			lookupData: this.props.lookupData,
			checked: false,
			isCreditChecked: false,
			error: '',
			values: this.props.values || {},
			rows: []
		}
	}

	static getDerivedStateFromProps(nextProps, state) {
		//console.log("nextProps ",nextProps);
		//console.log("state ",state);

		let customer = nextProps.allCustomers !== undefined && nextProps.allCustomers.find(customer => {
			return customer.customerId === nextProps.customerId
		});

		if (nextProps.customerId !== "" && Object.entries(state.customer).length === 0) {

			return {
				...state,
				allCustomers: nextProps.allCustomers,
				lookupData: nextProps.lookupData,
				checked: customer.is_have_metal === 1 ? true : false,
				isCreditChecked: customer.isCreditCustomer==1 ? true : false,
				customer
			}
		}


		let errors = '';
		if (nextProps.error !== undefined && nextProps.error !== '') {
			console.log("nextProps ", 323, state);

			if (typeof nextProps.error === 'object') {
				errors = Object.values(nextProps.error).join(',');
			}
			else {
				errors = nextProps.error;
			}

			return {
				error: errors
			}
		}

		if (nextProps.success !== undefined && nextProps.success !== "" && nextProps.success !== state.success) {
			if(nextProps.location.pathname=='/main/CreditExceedingCustomers'){
				nextProps.updateLayout("Credit Exceeding List");
			}
			else {
                nextProps.updateLayout("Customer List");
            }
		}

		return null;

	}

	handleCheckBox = (e) => {

		this.setState((prevState) => ({ ...prevState, checked: !prevState.checked }));
	}

	handleCreditCheckBox = (e) => {

		this.setState((prevState) => ({ ...prevState, isCreditChecked: !prevState.isCreditChecked }));
	}

	addRow(errors, touched, values, handleChange) {


		let rows = [...this.state.rows];
		rows.push(<Repeater errors={errors} touched={touched} values={values} handleChange={handleChange} index={rows.length + 1} removeItem={(index) => this.removeItem(index)} key={rows.length + 1} lookupData={this.state.lookupData} {...this.props} />)
		this.setState({ rows: rows })
	}
	removeItem(index) {
		const allRowItems = [...this.state.rows]
		allRowItems.splice(index - 1, 1)
		this.setState({ rows: allRowItems })
	}
	onSubmit = (values) => {

		let customer = {
			'address': values.address,
			'email': values.email,
			'fullName': values.fullName,
			'phone': values.phone,
			'customerType': values.customerType,
			'is_have_metal': values.is_have_metal,
			'creditLimit': values.creditLimit
		}
		if (this.state.checked) {
			customer.is_have_metal = 1;
			customer.metals = [...values.metals];
		} else {
			customer.is_have_metal = 0;
		}

		if(isAdmin())
		{
		    customer.isCreditCustomer=this.state.isCreditChecked?1:0
		}

		if (values.customerType === 'Personal') {
			customer.personalId = values.personalId;
			delete values.taxId;
		}
		if (values.customerType === 'Company') {
			customer.taxId = values.taxId;
			delete values.personalId;
		}
		if (this.props.customerId === "") {
			console.log(customer);
			this.props.createCustomer(customer);
		} else {
			delete customer.phone;
			delete customer.email;
			this.props.updateCustomer(this.props.customerId, customer);
		}

	}
	render() {
		let langId = i18n.language === 'en' ? 1 : 2

		let metalOptions = this.props.lookupData !== undefined && this.props.lookupData.Metal.map((metal) => {
			return <option key={`metalOpt${metal.itemId}`} value={metal.itemId}>{metal.text.find(text => text.langId === langId).name}</option>
		});

		let unitOptions = this.props.lookupData !== undefined && this.props.lookupData.Unit.map((unit) => {
			return <option key={`unitOpt${unit.unitId}`} value={unit.unitId}>{unit.text.find(text => text.langId === langId).name}</option>
		});

		let customer = this.props.customerId !== "" ? this.state.customer : null;
		return (

			<React.Fragment>
				<Formik
					initialValues={{

						fullName: customer !== null && customer.fullName !== undefined ? customer.fullName : '',
						phone: customer !== null && customer.phone !== undefined ? customer.phone : '',
						email: customer !== null && customer.email !== undefined ? customer.email : '',
						address: customer !== null && customer.address !== undefined ? customer.address : '',
						creditLimit: customer !== null && customer.creditLimit !== undefined ? customer.creditLimit : '',
						customerType: customer !== null && customer.customerType !== undefined ? customer.customerType : '',
						personalId: customer !== null && customer.personalId !== undefined ? customer.personalId : '',
						taxId: customer !== null && customer.taxId !== undefined ? customer.taxId : '',
						metals: [{ 'quantity': '', 'unitId': '', 'metalId': '' }],

					}}
					onSubmit={values => this.onSubmit(values)}

					validationSchema={Yup.object().shape({
						...customerValidation,
						personalId: Yup.string().trim()
							.required((values) => {
								if (values.customerType === 'Personal') {
									return 'Please enter personal id.'
								} else {
									return
								}
							}),
						taxId: Yup.string().trim()
							.required((values) => {
								if (values.customerType === 'Company') {
									return 'Please enter tax id.'
								} else {
									return
								}
							}),
						creditLimit: Yup.number()
							.required((values) => {
								if (this.state.isCreditChecked) {
									return 'Please enter credit limit.'
								} else {
									return
								}
							}),
						metals: Yup.array()
							.of(
								Yup.object().shape({
									metalId: Yup.string()
										.required((values) => {

											if (this.state.checked) {
												return 'Please select metal.'
											} else {
												return
											}


										}),



									quantity: Yup.number()
										.required(() => {
											if (this.state.checked) {
												return 'Please enter quantity.'
											} else {
												return
											}
										}).when("unitId", {
											is: val => (val && val == 10 ? true : false),
											then: Yup.number().min(
												10,
												"Quantity less than 10 Mg. is not accepted"
											)
										}),

									unitId: Yup.string()
										.required(() => {
											if (this.state.checked) {
												return 'Please select unit.'
											} else {
												return
											}
										})
								})
							)
					})
					}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset
						} = props;

						return (

							<form onSubmit={handleSubmit} >
								{this.state.error !== '' && this.state.error !== undefined ?
									<Row>
										<Col lg="12" md="12"><AlertMessage error={this.state.error} /></Col>
									</Row>
									: ''}
								<div className="row">
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('CustomerName')}</label>
											<input
												type="text"
												name="fullName"
												value={values.fullName}
												className={
													errors.fullName && touched.fullName
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.fullName && touched.fullName && (<div className="input-feedback">{errors.fullName}</div>)}
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('Mobile')} #</label>
											<input
												type="text"
												name="phone"
												value={values.phone}
												className={
													errors.phone && touched.phone
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur}

											/>
											{
												errors.phone && touched.phone && (
													<div className="input-feedback">{errors.phone}</div>
												)}
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('Email')}</label>
											<input
												type="text"
												name="email"
												value={values.email}
												className={
													errors.email && touched.email
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur}

											/>
											{
												errors.email && touched.email && (
													<div className="input-feedback">{errors.email}</div>
												)}
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('Address')}</label>
											<input
												type="text"
												name="address"
												value={values.address}
												className={
													errors.address && touched.address
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur}

											/>
											{
												errors.address && touched.address && (
													<div className="input-feedback">{errors.address}</div>
												)}
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('TypeOfCustomer')}</label>
											<div className="col-12">
												<div className="custom-control form-check form-check-inline">
													<input
														id="inline-checkbox1"
														name="customerType"
														type="radio"
														className="form-check-input custom-control-input form-check-input"
														value="Personal"
														onChange={handleChange}
														checked={values.customerType === "Personal" ? true : false}
													/>
													<label htmlFor="inline-checkbox1" className="form-check-label custom-control-label form-check-label">{this.props.t('Personal')}</label>
												</div>
												<div className="custom-control form-check form-check-inline">
													<input
														id="inline-checkbox2"
														name="customerType"
														type="radio"
														className="form-check-input custom-control-input form-check-input"
														value="Company"
														onChange={handleChange}
														checked={values.customerType === "Company" ? true : false}
													/>
													<label htmlFor="inline-checkbox2" className="form-check-label custom-control-label form-check-label">{this.props.t('Company')}</label>
												</div>
											</div>
										</div>
									</div>
									<div className={`${values.customerType === 'Personal' ? "col-md-4" : "col-md-4 hide"}`}>
										<div className="form-group">
											<label>{this.props.t('PersonalId')}#</label>
											<input
												type="text"
												name="personalId"
												value={values.personalId}
												className={errors.personalId && touched.personalId
													? "form-control error"
													: "form-control"}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											{errors.personalId && touched.personalId && (<div className="input-feedback">{errors.personalId}</div>)}
										</div>
									</div>
									<div className={`${values.customerType === 'Company' ? "col-md-4" : "col-md-4 hide"}`}>
										<div className="form-group">
											<label>{this.props.t('TaxID')}#</label>
											<input
												type="text"
												name="taxId"
												value={values.taxId}
												className={errors.taxId && touched.taxId
													? "form-control error"
													: "form-control"}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											{errors.taxId && touched.taxId && (<div className="input-feedback">{errors.taxId}</div>)}
										</div>
									</div>
									{isAdmin() && <div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('IsCreditCustomer')}</label>
											<div className="col-md-12">
												<div className="custom-control form-check form-check-inline w-100">
													<input
														id="checkbox"
														type="checkbox"
														className="form-check-input custom-control-input form-check-input"
														value="IsCredit"
														onChange={this.handleCreditCheckBox}
														checked={this.state.isCreditChecked}
													/>
													<label htmlFor="checkbox" className="form-check-label custom-control-label form-check-label">{this.props.t('IsCredit')}</label>
												</div>
											</div>
										</div>
									</div>}
									<div className={`${this.state.isCreditChecked ? "col-md-4" : "col-md-4 hide"}`} id="isCreditCustomer">
										<div className="form-group">
											<label>{this.props.t('CreditLimit')} (฿)</label>
											<input
												type="text"
												name="creditLimit"
												value={values.creditLimit}
												className={errors.creditLimit && touched.creditLimit
													? "form-control error"
													: "form-control"}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
											{errors.creditLimit && touched.creditLimit && (<div className="input-feedback">{errors.creditLimit}</div>)}
										</div>
									</div>
									<div className="col-md-4">
										<div className="form-group">
											<label>{this.props.t('ProvidesOwnMetal')}</label>
											<div className="col-md-12">
												<div className="custom-control form-check form-check-inline w-100">
													<input
														id="checkbox1"
														type="checkbox"
														className="form-check-input custom-control-input form-check-input"
														value="ProvidesMetal"
														onChange={this.handleCheckBox}
														checked={this.state.checked}
													/>
													<label htmlFor="checkbox1" className="form-check-label custom-control-label form-check-label">{this.props.t('ProvidesMetal')}</label>
												</div>
											</div>
										</div>
									</div>
									<div className={`${this.state.checked ? "col-md-12" : "col-md-12 hide"}`} id="provideMetal">
										<FieldArray name="metals">
											{({ push, remove }) => (
												<div>
													{values.metals !== undefined && values.metals.map((p, index) => {
														const metalName = `metals[${index}].metalId`;
														const metalErrorMsg = getIn(errors, metalName);

														const quantityName = `metals[${index}].quantity`;
														const quantityErrorMsg = getIn(errors, quantityName);

														const unitName = `metals[${index}].unitId`;
														const unitErrorMsg = getIn(errors, unitName);


														return (
															<React.Fragment key={index}>
																<div className="row">
																	<div className="col-md-3">
																		<div className="form-group">
																			<label>{this.props.t('Metal')}</label>
																			<Field
																				name={metalName}
																				as="select"
																				className="form-control"
																				value={p.metalId}
																				onChange={handleChange}
																			>
																				<option key="materialOptempty" value="metals">--{this.props.t('Metal')} --</option>
																				{metalOptions}
																			</Field>
																			{metalErrorMsg && <div className="input-feedback">{metalErrorMsg}</div>}

																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="form-group">
																			<label>{this.props.t('Quantity')}</label>
																			<Field
																				name={quantityName}
																				className="form-control"
																				value={p.quantity}
																				onChange={handleChange}
																			/>
																			{quantityErrorMsg && <div className="input-feedback">{getIn(errors, `metals[${index}].quantity`)}</div>}

																		</div>
																	</div>
																	<div className="col-md-3">
																		<div className="form-group">
																			<label>{this.props.t('Unit')}</label>
																			<Field
																				name={unitName}
																				as="select"
																				className="form-control"
																				value={p.unitId}
																				onChange={handleChange}
																			>
																				<option key="unitOpt" value="">--{this.props.t('Unit')} --</option>
																				{unitOptions}
																			</Field>
																			{unitErrorMsg && <div className="input-feedback">{getIn(errors, `metals[${index}].unitId`)}</div>}

																		</div>
																	</div>
																	<div className="col-md-3">
																		{index !== 0 ? <div className="form-group">
																			<label></label>
																			<button type="button" className="btn-primary-outline d-block mt-2" onClick={() => { remove(index) }}><i className="icon-close icon"></i></button>
																		</div> : ''}
																	</div>
																</div>
															</React.Fragment>
														)
													})}
													<div className="row">
														<div className="col-md-12">
															<button type="button" className="btn-primary-fill float-right" onClick={() => push({ metalId: '', quantity: '', unitId: '' })}>Add Metal</button>
														</div>
													</div>
												</div>
											)}
										</FieldArray>
									</div>
									<div className="col-md-12 mt-4">
										<div className="float-right">
											<button type="submit"
												className={`btn-primary-fill mr-2`}
											>{this.props.t('Submit')}</button>
											<button type="button"
												className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
												onClick={handleReset}
												disabled={isSubmitting}
											>{this.props.t('Clear')}</button>
										</div>
									</div>
								</div>
							</form>
						)
					}}
				</Formik>
			</React.Fragment>
		)
	}
}


const mapStateToProps = state => {

	return {
		allCustomers: state.customerReducer.customers,
		success: state.customerReducer.customerSuccess,
		error: state.customerReducer.customerError,
		customer: state.customerReducer.customer,
	}
}

const mapDispatchToProps = {
	createCustomer: createCustomerAction,
	updateCustomer: updateCustomerAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CustomerAdd));