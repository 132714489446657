import Api from '../Api'

export const finishedProductList = () => {
    return Api.get('finished-product').then(res => res.data).catch(err => console.log(err))
};
export const addFinishedProduct = (productData) => {
    return Api.post('finished-product', productData);
}
export const updateFinishedProduct = (productId,productData) => {
    return Api.put('finished-product/'+productId, productData);
}