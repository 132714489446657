import * as actionTypes from '../constant';
import { finishedProductList,addFinishedProduct,updateFinishedProduct} from '../../services/finishedProductServices';
export const finishedProductAction = () => {
	return dispatch => {
		finishedProductList().then(res => {

			if (res.data !== undefined) {
				dispatch(getFinishedProductList(res.data));
			} else {
				// dispatch(error(res.error_description));
			}

		}).catch(err => {
			 console.log('error finished list  action ', err);
			let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
			dispatch(error(errormsg ));
		});
	}
}

const getFinishedProductList = (res) => {
	return {
		type: actionTypes.FINISHED_PRODUCT_LIST,
		value: res
	}
}
export const addFinishedProductAction = (productData) => {
	return dispatch => {
		addFinishedProduct(productData).then(res => {
			if (res.data !== undefined) {
				dispatch(addFinishedProductPost(res.data.data));
			} else {
				// dispatch(error(res.error_description));
			}

		}).catch(err => {
			console.log('error finished product add action ', err);
			//var errMsg = Object.assign({}, err).response.data.error_description;
			let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
			dispatch(error( errormsg));
		});
	}
}

export const UpdatefinishedProductAction = (productId,productData) => {
    return dispatch => {
        updateFinishedProduct(productId,productData).then(res => {
            if (res.data !== undefined) {
                dispatch(updatefinisheProductPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error customer update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updatefinisheProductPut = (res) => {
    return {
        type: actionTypes.FINISHED_PRODUCT_UPDATE,
        value: res
    }
}
const addFinishedProductPost = (res) => {
	return {
		type: actionTypes.FINISHED_PRODUCT_ADD,
		value: res
	}
}



const error = (err) => {
	return {
		type: actionTypes.LOG_ERROR,
		value: err
	}
}