import React, { Component } from 'react'
import { Row, Col } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from 'react-bootstrap-table2-paginator';
import { branchListAction } from '../redux/actions/BranchActions'
import { updateBranchStatus } from '../services/BranchServices'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import i18n from '../i18n';
import { AlertMessage } from '../helper/AlertBox';

const onColumnMatch = ({ searchText, value, column, row }) => {
	if (searchText !== '' || searchText !== null) {
		return row.branchName.trim().toLowerCase().indexOf(searchText) < 0 ? false : row
	}
	return row
}

class BranchList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			allBranches: props.allBranches,
			success: props.success,
			error: props.error,
			branchId: '',
			branchStatus: ''
		}
	}

	componentDidMount() {
		this.props.branchListAction();
	}

	static getDerivedStateFromProps(nextProps, state) {
		let nextRes = { ...state };
		if (nextProps.success !== undefined && nextProps.success !== "") {
			nextRes.success = nextProps.success;
		}

		if (nextProps.allBranches !== undefined)
			nextRes.allBranches = nextProps.allBranches;

		return { ...nextRes };
	}

	handleStatusChange = (e, branchId) => {
		let status = e.target.checked ? 1 : 0;
		updateBranchStatus(branchId, { "branchStatus": status }).then(res => {
			let branches = [...this.state.allBranches]
			let index = branches.findIndex(branch => branch.branchId == branchId);
			branches[index].branchStatus = status;
			this.setState({
				success: res.data.message,
				allBranches: branches
			})
		}).catch(err => console.log(err))
	}

	render() {
		const { SearchBar, ClearSearchButton } = Search;

		this.columns = [
			{
				dataField: "branchId",
				text: this.props.t("Branch Name"),
				formatter: (cell) => {
					let langId = i18n.language === 'en' ? 1 : 2;
					let branchData = this.state.allBranches !== undefined && this.state.allBranches !== undefined && this.state.allBranches.find(branch => branch.branchId === cell);
					let branchText = branchData !== undefined && branchData.branchName !== undefined && branchData.branchName.length > 0 && branchData.branchName.find(text => text.langId === langId)
					return (<span>{branchText !== undefined && branchText.name}</span>);
				}
			},
			{
				dataField: "branchAddress",
				text: this.props.t("Branch Address")
			},
			{
				dataField: "branchStatus",
				text: this.props.t("Status"),
				formatter: (cell, row) => {
					return <React.Fragment>
						<div className="d-flex">
							<label>{this.props.t('Inactive')}</label>
							<label className="label-switch switch-primary">
								<input type="checkbox" className="switch switch-bootstrap status" name="status" id="status" defaultValue={cell} defaultChecked={cell == 1 ? true : false} onChange={(e) => this.handleStatusChange(e, row.branchId)} />
								<span className="lable"></span>
							</label>
							<label>{this.props.t('Active')}</label>
						</div>
					</React.Fragment>
				}
			},
			{
				dataField: "createdAt",
				text: this.props.t("Action"),
				formatter: (cell, row) => {
					return <React.Fragment>
						<button type="button" className="btn btn-sm mr-2" onClick={() => this.props.handleOrderView('Edit Branch', row.branchId)}><i className="icon-pencil icon"></i></button>
						{/* <button type="button" className="btn btn-sm btn-info" >Marked Inactive</button> */}
					</React.Fragment>
				}
			}
		];


		return (
			<React.Fragment>
				<Row>
					{this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage key={new Date().getTime()} success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<Col lg="12" md="12">
						{this.state.allBranches !== undefined ?
							<ToolkitProvider
								keyField="branchId"
								key={`BranchListTable`}
								data={this.state.allBranches}
								columns={this.columns}
								search
							>
								{
									props => (
										<div>
											<div className="mb-2">
												<SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
											</div>
											<div className="table-responsive">
												<BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
											</div>
										</div>
									)
								}
							</ToolkitProvider>
							: ''}
					</Col>
				</Row>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		allBranches: state.branchReducer.branches,
		success: state.branchReducer.branchSuccess,
		error: state.branchReducer.branchError,
	}
}

const mapDispatchToProps = {
	branchListAction,
	updateBranchStatus
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BranchList))
