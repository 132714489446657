import React from 'react'
import { Switch, Route, } from "react-router-dom"
import { isAdmin, isAccounting, isDeliveryAdmin,isAssortmentAdmin,isCastingAdmin } from '../helper/General'

// import Breadcrumbs from "../BreadCrumbs";
export default class MainContainer extends React.Component {
    componentDidMount() {
        if (localStorage.getItem('token') === null) {

            this.props.history.push('/');
        }
    }
    render() {
        let { roles } = this.props
        let routes = [...roles.Common]
        let allRoutes = []
        if (isAdmin()) {
            routes = [...routes, ...roles.Admin]
        } else if (isAccounting()) {
            routes = [...roles.Accounting]
        } else if (isDeliveryAdmin()) {
            routes = [...roles.DeliveryAdmin]
        }  else if (isAssortmentAdmin()) {
            routes = [...roles.AssortmentAdmin]
        } else if (isCastingAdmin()) {
            routes = [...roles.CastingAdmin]
        }

        routes.map(route => {
            if (route.subMenu !== undefined && route.subMenu.length) {
                allRoutes.push(...route.subMenu)
            } else {
                allRoutes.push(route)
            }

        })

        return (
            <div className={this.props.handleMainClass ? "main-container" : "main-container full"} id="main-container">
                {/*<Breadcrumbs />*/}
                <Switch>
                    {/* <Route exact path="/main" component={Dashboard} /> */}
                    {allRoutes.map((route, index) => <Route exact path={route.url} component={route.component} key={index} />)}
                </Switch>
            </div>

        )
    }
}
