import * as actionTypes from '../constant';
import { REHYDRATE } from 'redux-persist';

const INITIAL_STATE = {};

export const generalReducer = (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case REHYDRATE:
      return {
        ...state,
        lookupData : action.value
      };
  
    case actionTypes.LOOKUP_DATA:
      return {
        ...state,
        lookupData: action.value
      };
    default:
      return state;
  }
}
