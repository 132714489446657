import React from 'react';
import FlashMessage from 'react-flash-message'

export const AlertMessage = (props) => {
  return (
    <FlashMessage key={new Date().getTime()} duration={3000}>
      <div className={props.error ? "alert alert-danger" : "alert alert-success"}>{props.error ? props.error : props.success}</div>
    </FlashMessage>
  );

}