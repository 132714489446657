import * as actionTypes from '../constant';

import { getUsers, createUser, updateUser } from '../../services/UserServices';

export const userListAction = (type) => {
    return dispatch => {
        getUsers().then(res => {
            if (res.data !== undefined) {
                dispatch(getUserList(res.data.data));
                dispatch(clearError());
            }
        }).catch(err => {
            console.log('error user list action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const getUserList = (res) => {
    return {
        type: actionTypes.USER_LIST,
        value: res
    }
}

export const createUserAction = (user) => {
    return dispatch => {
        createUser(user).then(res => {
            if (res.data !== undefined) {
                dispatch(createUserPost(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error user create action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const createUserPost = (res) => {
    return {
        type: actionTypes.USER_CREATE,
        value: res
    }
}

export const updateUserAction = (userId, user) => {
    return dispatch => {
        updateUser(userId, user).then(res => {
            if (res.data !== undefined) {
                dispatch(updateUserPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error user update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateUserPut = (res) => {
    return {
        type: actionTypes.USER_UPDATE,
        value: res
    }
}
const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
