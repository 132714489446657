import React, { Component } from 'react'
import { Formik, FieldArray, Field, getIn } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'reactstrap'
import { itemValidation } from '../Validation/ItemValidationSchema';
import { withTranslation } from 'react-i18next'
import { AlertMessage } from '../helper/AlertBox';
import { connect } from 'react-redux';

class ItemAdd extends Component {

	constructor(props) {
		super(props);
		this.state = {
			rowEle: [],
			error: ''
		}

	}
	static getDerivedStateFromProps(nextProps, state) {
		let errors = '';

		if (state.error !== nextProps.error) {
			if (typeof nextProps.error === 'object') {
				errors = Object.values(nextProps.error)[0].join(',');
			}
			else {
				errors = nextProps.error;
			}


			return {
				error: errors
			}
		}


		if (nextProps.success !== undefined && nextProps.success !== "" &&nextProps.success !== state.success) {
			nextProps.getLookupData();
			nextProps.updateLayout("Item List");
		}
		return null;
	}
	handleItemType = (e) => {
		let value = e.target.value;


		if (value === 'alloy') {
			document.getElementById('moremetal').classList.remove('hide');
		} else {
			document.getElementById('moremetal').classList.add('hide');
		}
	}

	removeCompositionRow = (index) => {
		const rowEle = [...this.state.rowEle]
		rowEle.splice(index, 1)
		this.setState({ rowEle: rowEle })
	}

	showComposition = () => {
		document.getElementById('moremetal').classList.remove('hide');
	}

	addMoreComposition = () => {
		let rows = [...this.state.rowEle];
		rows.push(<CompositionRow index={rows.index} removeCompositionRow={this.removeCompositionRow} />)
		this.setState(prevState => ({
			rowEle: rows
		}))
	}

	onSubmit = (values) => {

		if (this.props.singleItem !== null && this.props.singleItem !== '') {
			this.props.updateItem(this.props.singleItem.itemId, values)
		} else {

			this.props.createItem(values)
		}

	}

	render() {

		let { singleItem, i18n, lookupData } = this.props
		let langId = i18n.language !== 'en' ? 2 : 1
		return (
			<React.Fragment>
				<Formik
					enableReinitialize={true}
					initialValues={{
						itemName: singleItem !== null && singleItem.text.find(it => it.langId === 1) !== undefined ? singleItem.text.find(it => it.langId === 1).name : '',
						itemNameInTh: singleItem !== null && singleItem.text.find(it => it.langId === 2) !== undefined ? singleItem.text.find(it => it.langId === 2).name : '',
						itemType: singleItem !== null && singleItem.isProcess == 0 ? 'Metal' : 'Process',
						rate: singleItem !== null ? singleItem.price : '',
						unitId: singleItem !== null && singleItem.unitId ? singleItem.unitId : '',
						taxRate: singleItem !== null ? singleItem.taxRate : '',
						lossPercentageProduction: singleItem !== null ? singleItem.productionLoss : '',
						lossPercentageBilling: singleItem !== null ? singleItem.billingLoss : '',
						isTaxable: singleItem !== null && singleItem.isTaxable ? singleItem.isTaxable : 0

					}}
					onSubmit={values => this.onSubmit(values)}
					validationSchema={Yup.object().shape({
						...itemValidation
					})}
				>{props => {
					const {
						values,
						touched,
						errors,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
						handleReset,
						setFieldValue
					} = props;
					return (
						<form onSubmit={handleSubmit}>
							{this.state.error !== '' && this.state.error !== undefined ?
								<Row>
									<Col lg="12" md="12"><AlertMessage error={this.state.error} /></Col>
								</Row>
								: ''}
							<div className="row">
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-4">{this.props.t('ItemName')}</label>
										<div className="col-md-4">
											<input
												type="text"
												name="itemName"
												placeholder={this.props.t('InEnglish')}
												value={values.itemName}
												className={
													errors.itemName && touched.itemName
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.itemName && touched.itemName && (<div className="input-feedback">{errors.itemName}</div>)}
										</div>
										<div className="col-md-4">
											<input
												type="text"
												name="itemNameInTh"
												placeholder={this.props.t('InThai')}
												value={values.itemNameInTh}
												className={
													errors.itemNameInTh && touched.itemNameInTh
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.itemNameInTh && touched.itemNameInTh && (<div className="input-feedback">{errors.itemNameInTh}</div>)}
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('ItemType')}</label>
										<div className="col-md-7">
											{/* <select className="form-control" onChange={this.handleItemType} > */}
											<select
												disabled={singleItem !== null}
												name="itemType"
												className={
													errors.itemType && touched.itemType
														? "form-control error"
														: "form-control"
												}
												onChange={e => {
													this.handleItemType(e);
													setFieldValue('itemType', e.target.value);
												}}
												defaultValue={singleItem !== null ? (singleItem.isProcess==0?'Metal':'Process' ): ''}
												onBlur={handleBlur}>
												<option value="Metal">{this.props.t('Metal')}</option>
												<option value="Process">{this.props.t('Process')}</option>
											</select>
											{errors.itemType && touched.itemType && (<div className="input-feedback">{errors.itemType}</div>)}
										</div>

									</div>
								</div>


								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('Rate')}</label>
										<div className="col-md-7">
											<input
												type="text"
												name="rate"
												value={values.rate}
												className={
													errors.rate && touched.rate
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.rate && touched.rate && (<div className="input-feedback">{errors.rate}</div>)}
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('Unit')}</label>
										<div className="col-md-7">
											<select
												name="unitId"
												className={
													errors.unitId && touched.unitId
														? "form-control error"
														: "form-control"
												} defaultValue={singleItem !== null ? singleItem.unitId : ''}
												onChange={e => {
													setFieldValue('unitId', e.target.value);
												}}
												onBlur={handleBlur}>
												<option value=''>-- {this.props.t('SelectUnit')} --</option>
												{lookupData !== undefined && lookupData['Unit'].map(opt => <option key={opt.lookupId} value={opt.unitId}>{opt.text.find(st => st.langId == langId).name}</option>)}
											</select>
											{errors.unitId && touched.unitId && (<div className="input-feedback">{errors.unitId}</div>)}
											{/* <select name="unitId" className="form-control" >
												{lookupData !== undefined && lookupData['Unit'].map(opt => <option key={opt.lookupId} value={opt.unitId}>{opt.text.find(st => st.langId == langId).name}</option>)}
											</select> */}
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('TaxRate')}</label>
										<div className="col-md-7">
											<input
												type="text"
												name="taxRate"
												value={values.taxRate}
												className={
													errors.taxRate && touched.taxRate
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.taxRate && touched.taxRate && (<div className="input-feedback">{errors.taxRate}</div>)}
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('LossPercentageProduction')}</label>
										<div className="col-md-7">
											<input
												type="text"
												name="lossPercentageProduction"
												value={values.lossPercentageProduction}
												className={
													errors.lossPercentageProduction && touched.lossPercentageProduction
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.lossPercentageProduction && touched.lossPercentageProduction && (<div className="input-feedback">{errors.lossPercentageProduction}</div>)}
										</div>

									</div>
								</div>

								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('LossPercentageBilling')} </label>
										<div className="col-md-7">
											<input
												type="text"
												name="lossPercentageBilling"
												value={values.lossPercentageBilling}
												className={
													errors.lossPercentageBilling && touched.lossPercentageBilling
														? "form-control error"
														: "form-control"
												}
												onChange={handleChange}
												onBlur={handleBlur} />
											{errors.lossPercentageBilling && touched.lossPercentageBilling && (<div className="input-feedback">{errors.lossPercentageBilling}</div>)}
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-5">{this.props.t('IsTaxable')}</label>
										<div className="col-md-7">
											<div>
												<div className="custom-control form-check form-check-inline">
													{/* <input id="inline-checkbox1" name="isTaxeble" type="radio" className="form-check-input custom-control-input form-check-input" value="1" defaultChecked={} /> */}
													<input
														id="inline-checkbox1"
														name="isTaxable"
														type="radio"
														className="form-check-input custom-control-input form-check-input"
														value="1"
														onChange={handleChange}
														checked={values.isTaxable == 1 ? true : false}
													/>
													<label htmlFor="inline-checkbox1" className="form-check-label custom-control-label form-check-label">{this.props.t('Yes')}</label>
												</div>
												<div className="custom-control form-check form-check-inline">
													{/* <input id="inline-checkbox2" name="isTaxeble" type="radio" className="form-check-input custom-control-input form-check-input" value="0" defaultChecked={singleItem !== null && singleItem.isTaxable == 0} /> */}
													<input
														id="inline-checkbox2"
														name="isTaxable"
														type="radio"
														className="form-check-input custom-control-input form-check-input"
														value="0"
														onChange={handleChange}
														checked={values.isTaxable == 0 ? true : false}
													/>
													<label htmlFor="inline-checkbox2" className="form-check-label custom-control-label form-check-label">{this.props.t('No')}</label>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <div className="col-md-12">
							<button type="button" className="btn btn-primary-fill mb-2" onClick={this.showComposition}><i className="icon-plus icon mr-2"></i> Add Composition </button>
						</div> */}
								<div className="col-md-12 hide" id="moremetal">
									<div className="row">

										<div className="col-md-6">
											<div className="form-group row">
												<label className="col-md-5">Metal</label>
												<div className="col-md-7">
													<select className="form-control">
														<option value="metal">Metal 1</option>
														<option value="process">Metal 2</option>
													</select>

												</div>

											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group row">
												<label className="col-md-5">Percentage</label>
												<div className="col-md-7">
													<input type="text" className="form-control" />
												</div>
												{/* <div className="col-md-2"><button type="button" className="btn-primary-outline btn-sm float-right"><i className="icon-minus icon"></i></button></div> */}
												{/* <div className="col-md-1">
											<i className="icon-cross icon"></i>
										</div> */}
											</div>
										</div>
										{this.state.rowEle}
										<div className="col-md-12 mb-2">
											<h6>
												<button type="button" className="btn btn-primary-fill float-right mb-2" onClick={this.addMoreComposition}><i className="icon-plus icon"></i>Add More </button>
											</h6>
										</div>
									</div>
								</div>
								<div className="col-md-12 mt-4">
									<div className="float-right">
										<button type="submit"
											className={`btn-primary-fill mr-2`}
										>{this.props.t('Submit')}</button>
										<button type="button"
											className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
											onClick={handleReset}
											disabled={isSubmitting}
										>{this.props.t('Clear')}</button>
									</div>
								</div>
							</div>
						</form>
					)
				}}
				</Formik>
			</React.Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		error: state.itemReducer.itemError,
		success: state.itemReducer.itemSuccess
	}
}

export default withTranslation()(connect(mapStateToProps)(ItemAdd));

const CompositionRow = (props) => {
	return (
		<React.Fragment>
			<div className="col-md-6">
				<div className="form-group row">
					<label className="col-md-5">Metal</label>
					<div className="col-md-7">
						<select className="form-control" >
							<option value="metal">Metal 1</option>
							<option value="process">Metal 2</option>
						</select>

					</div>

				</div>
			</div>
			<div className="col-md-6">
				<div className="form-group row">
					<label className="col-md-5">Percentage</label>
					<div className="col-md-7">
						<div className="d-flex">
							<input type="text" className="form-control" />
							<button type="button" className="btn-primary-outline btn-sm float-right ml-3" onClick={() => props.removeCompositionRow(props.index)}><i className="icon-minus icon"></i></button>
						</div>
					</div>
					{/* <div className="col-md-2"><button type="button" className="btn-primary-outline btn-sm float-right" onClick={() => props.removeCompositionRow(props.index)}><i className="icon-minus icon"></i></button></div> */}
					{/* <div className="col-md-1">
						<i className="icon-cross icon"></i>
					</div> */}
				</div>
			</div>
		</React.Fragment>
	)
}