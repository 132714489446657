import { combineReducers } from 'redux'
import { authReducer } from './AuthReducer'
import { generalReducer } from './GeneralReducer'
import { orderReducer } from './OrderReducer'
import { invoiceReducer } from './InvoiceReducer'
import { customerReducer } from './CustomerReducer'
import { packageReducer } from './PackageReducer'
import { userReducer } from './UserReducer'
import { branchReducer } from './BranchReducer'
import { reportReducer } from './ReportReducer'
import { itemReducer } from './ItemReducer'
import { ledgerReducer } from './LedgerReducer'
import { finishedProductReducer } from './finishedProductReducer'

export default combineReducers({
    authReducer,
    generalReducer,
    orderReducer,
    customerReducer,
    invoiceReducer,
    packageReducer,
    userReducer,
    branchReducer,
    reportReducer,
    itemReducer,
    ledgerReducer,
    finishedProductReducer,
})

