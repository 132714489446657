import * as actionTypes from '../constant';
import { getInventoryReport, getSalesReport, getWastageReport } from '../../services/ReportServices';

export const getInventoryAction = (filters) => {

    return dispatch => {
        getInventoryReport(filters).then(res => {

            if (res.data !== undefined) {
                dispatch(getInventory(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error inventory report action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getInventory = (res) => {
    return {
        type: actionTypes.INVENTORY_REPORT,
        value: res
    }
}

export const getWastageAction = (filters) => {

    return dispatch => {
        getWastageReport(filters).then(res => {

            if (res.data !== undefined) {
                dispatch(getWastage(res.data.data));
                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error wastage  report action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getWastage = (res) => {
    return {
        type: actionTypes.WASTAGE_REPORT,
        value: res
    }
}


export const getSalesAction = (filters) => {

    return dispatch => {
        getSalesReport(filters).then(res => {

            if (res.data !== undefined) {
                dispatch(getSales(res.data.data));

                dispatch(clearError());
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error sales report action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getSales = (res) => {
    return {
        type: actionTypes.SALES_REPORT,
        value: res
    }
}


const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}