import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { connect } from 'react-redux';
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import MaterialEdit from '../components/MaterialEdit'
import MaterialList from '../components/MaterialList'
import { deliveredOrderedItems } from '../services/OrderServices'

class ManageMaterial extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "Material List",
            allOrder: [],
            row: ''
        }
    }
    componentDidMount() {
        document.title = 'Manage Material'
        deliveredOrderedItems().then(res => {
            this.setState({
                allOrder: res.data.data
            })
        }).catch(err => console.log(err))
    }
    materialList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('MaterialList')} </div>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <MaterialList handleOrderView={this.updateLayout} allOrder={this.state.allOrder} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    materialEdit = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('EditMaterial')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Material List") }}> {this.props.t('MaterialList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <MaterialEdit updateLayout={this.updateLayout} rowData={this.state.row} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, row = '') => {
        this.setState({ customerLayout: layout, row: row });
    }

    getCustomerComponent(layout) {

        var showLayout = '';

        switch (layout) {
            case "Material List": showLayout = this.materialList(this.props);
                break;
            case "Edit Material": showLayout = this.materialEdit(this.props);
                break;
            default:
                showLayout = "";
        }

        return showLayout;

    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {this.getCustomerComponent(this.state.customerLayout)}
                    </div>
                </div>
            </div>
        )
    }
}

export default withOrderInvoiceHoc(ManageMaterial)
