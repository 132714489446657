import React, {Component} from 'react'

class InvoiceLogs extends Component{
	render(){
		return(

			<h1>Invoice Preview</h1>

			)
	}
}

export default InvoiceLogs