import * as actionTypes from '../constant';

const INITIAL_STATE = {};

export const orderReducer = (state = INITIAL_STATE, action) => {
    let orders = [];
    let index = false;
    switch (action.type) {
        case actionTypes.ORDER_LIST:
            return {
                ...state,
                orders: action.value,
                orderSuccess: ''
            };
        case actionTypes.ORDER_CREATE:
            return {
                ...state,
                orders: [...state.orders, action.value],
                order: action.value,
                orderSuccess: 'Order created successfully'
            };
        case actionTypes.ORDER_UPDATE:
            orders = [...state.orders];
            index = orders.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            orders[index] = action.value
            return {
                ...state,
                orders: orders,
                order: action.value,
                orderSuccess: 'Order updated successfully.'
            };
        case actionTypes.ORDER_STATUS:
            orders = [...state.orders];
            index = orders.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            orders[index] = action.value
            return {
                ...state,
                orders: orders,
                order: action.value,
                orderSuccess: 'Order status updated successfully.'
            };
        case actionTypes.ORDER_DELETE:
            orders = [...state.orders];
            index = orders.findIndex(order => {
                return order.orderId === action.value.orderId
            });
            orders.splice(index, 1)
            return {
                ...state,
                orders: orders,
                order: action.value,
                orderError:'',
                orderSuccess: 'Order deleted successfully.'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                orderError:action.value,
                orderSuccess: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                orderError: '',
                orderSuccess: ''
            };
        default:
            return { ...state, orderError: '', orderSuccess: '' };
    }
}
