import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import CustomerAdd from '../components/CustomerAdd'
import CustomerList from '../components/CustomerList'
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import ViewCustomer from '../components/ViewCustomer'
import { isInvoicing,isAccounting, isAssortmentAdmin } from "../helper/General";

class Customers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "Customer List"
        }
    }

    customerList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('CustomerList')}</div>
                        </div>
                        {!isInvoicing() && !isAccounting() && !isAssortmentAdmin() && <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Add Customer") }}>{this.props.t('AddCustomer')}</button>
                        </div>}
                    </div>
                </div>
                <CardBody>
                    <CustomerList handleOrderView={(layout,customerId)=>this.updateLayout('View Customer Order',customerId)} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    customerCreate = (props, id) => {

        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.state.customerId ? this.props.t('EditCustomer') : this.props.t('AddCustomer')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Customer List") }}> {this.props.t('CustomerList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CustomerAdd {...this.props} customerId={this.state.customerId} updateLayout={this.updateLayout} />
                </CardBody>
            </Card>
        )
    }

    customerOrderPreview = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> {this.props.t('ViewCustomerDetails')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Customer List") }}> {this.props.t('CustomerList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ViewCustomer {...this.props} editCustomer={this.updateLayout} customerId={this.state.customerId} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, id = '') => {
        this.setState({ customerLayout: layout, customerId: id });
    }

    getCustomerComponent(layout) {

        var showLayout = '';

        switch (layout) {
            case "Customer List": showLayout = this.customerList(this.props);
                break;
            case "Add Customer": showLayout = this.customerCreate(this.props);
                break;
            case "View Customer Order": showLayout = this.customerOrderPreview(this.props);
                break;
            default:
                showLayout = "";
        }

        return showLayout;

    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                customerLayout: "Customer List"
            })
        }
    }

    render() {

        var showLayout = this.getCustomerComponent(this.state.customerLayout)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
        )
    }
}

export default withOrderInvoiceHoc(Customers)