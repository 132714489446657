import axios from 'axios'
import i18n from './i18n';
const BASE_URL = "https://projects2.sofmen.com/onestopapi/api/";
//const BASE_URL = "http://onestop.com/api/";


const Api = axios.create({
    baseURL: BASE_URL
});



Api.interceptors.request.use(function (config) {
    var token = localStorage.getItem('token')
    if (token !== undefined && token !== null) {
        if(i18n.language!==undefined){
             config.headers.common['lang'] = i18n.language === 'en' ? 1 : 2;
        }
        config.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers.common['Content-Type'] = 'application/json';
    }

    return config;
});

Api.interceptors.response.use((response) => {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        console.log('unauthorized, logging out ...');
        localStorage.removeItem('token')
        localStorage.clear(); 
        window.location.href='/';
    }
    return Promise.reject(error.response);

});


export default Api
