import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const reportReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case actionTypes.INVENTORY_REPORT:
            return {
                ...state,
                inventoryReport: action.value
            };

        case actionTypes.WASTAGE_REPORT:
            return {
                ...state,
                wastageReport: action.value,
            };
        case actionTypes.SALES_REPORT:
            return {
                ...state,
                salesReport: action.value,
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                reportError: action.value,
            };
        default:
            return {...state,reportError: ''};
    }
}