import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import BootstrapTable from "react-bootstrap-table-next"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import i18n from '../i18n';
import { convertUnit, calculateWastage, totalAmountWithWaistage } from '../helper/common';
import Select from 'react-select';
import * as Yup from 'yup';
import { LineItemValidation, invoiceValidation } from '../Validation/InvoiceValidationSchema'
import { Formik } from 'formik';
import { createInvoiceAction, updateInvoiceAction } from '../redux/actions/InvoiceActions';
import { isInvoicing, isAdmin } from "../helper/General";
import { AlertMessage } from '../helper/AlertBox';

export const LineItemModal = (props) => {

    const { modal } = props;
    const { t } = props;
    const options = props.lookupData !== undefined &&
        props.lookupData['Process'] !== undefined &&
        props.lookupData['Process'].length > 0 &&
        props.lookupData['Process'].map(data => {
            let label = data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name;
            return { 'value': data.itemId, 'label': label };
        });
    return (
        <div>
            <Formik
                enableReinitialize 
                initialValues={props.initialValues}
                onSubmit={(values) => props.addLineItem(values)}
                validationSchema={Yup.object().shape({ ...LineItemValidation })}>
                {formprops => {
                    const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue, initialValues
                    } = formprops;
                    return (
                        <form onSubmit={formprops.handleSubmit}>
                            <Modal isOpen={modal} toggle={props.toggle} size="lg">
                                <ModalHeader toggle={props.toggle}>{props.t('AddInvoiceLineItem')}</ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">{props.t('Metal')}</label>
                                                <div className="col-md-8">
                                                    <select
                                                        name="itemId"
                                                        className="form-control"
                                                        defaultValue={initialValues.itemId}
                                                        onChange={e => {
                                                            props.calculateAmount(initialValues, true, e);
                                                            setFieldValue('itemId', e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option key="emptyItem" value="">-- {t('SelectMetal')} --</option>
                                                        {props.lookupData !== undefined && props.lookupData['Metal'] !== undefined && props.lookupData['Metal'].length > 0 && props.lookupData['Metal'].map(data => {
                                                            return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                            </option>
                                                        })}
                                                    </select>
                                                    {errors.itemId && touched.itemId && (<div className="input-feedback">{errors.itemId}</div>)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">{props.t('Weight')}</label>
                                                <div className="col-md-8">
                                                    <input type="text"
                                                        name='quantity'
                                                        className="form-control"
                                                        defaultValue={initialValues.quantity}
                                                        onChange={e => {
                                                            props.calculateAmount(initialValues, true, e);
                                                            setFieldValue('quantity', e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.quantity && touched.quantity && (<div className="input-feedback">{errors.quantity}</div>)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="col-md-4">{props.t('Unit')}</label>
                                                <div className="col-md-8">
                                                    <select className="form-control"
                                                        name="unitId"
                                                        defaultValue={initialValues.unitId}
                                                        onChange={e => {
                                                            props.calculateAmount(initialValues, true, e);
                                                            setFieldValue('unitId', e.target.value);
                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option key="emptyunit" value="">-- {t('Unit')} --</option>
                                                        {
                                                            props.lookupData !== undefined && props.lookupData['Unit'] !== undefined &&
                                                            props.lookupData['Unit'].length > 0 &&
                                                            props.lookupData['Unit'].filter(unit => unit.unitId === 2 || unit.unitId == 3).map(data => {
                                                                return <option key={`opt-unit-${data.unitId}`}
                                                                    value={data.unitId}>{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {errors.unitId && touched.unitId && (<div className="input-feedback">{errors.unitId}</div>)}
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-6" id="processItems">
                                            <div className="form-group row">
                                                <label className="col-md-4">{props.t('AddProcess')}</label>
                                                <div className="col-md-8">
                                                    <Select options={options}
                                                        isMulti={true} name="process"
                                                        defaultValue={initialValues.process}
                                                        onChange={e => {
                                                            props.calculateAmount(initialValues, true, e);
                                                            setFieldValue('process', e);
                                                        }}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>
                                        </div><div className="col-lg-6">
                                            <div className="form-group row">
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group row">
                                                <label className="col-md-4"><b>{props.t('Amount')}</b></label>
                                                <div className="col-md-8">
                                                    <p className="lineItemAmt" id="amount">฿{props.invoiceTotal}</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <button type="submit" onClick={formprops.handleSubmit}
                                        className={`btn-primary-fill mr-2`}
                                    >{props.t('Ok')}</button>{' '}
                                    <Button color="secondary" onClick={props.toggle}
                                        type="button"
                                        className="btn-primary-outline"
                                    >{props.t('Cancel')}</Button>
                                </ModalFooter>
                            </Modal>
                        </form>
                    )
                }}
            </Formik>
        </div>
    );
}

class InvoiceCreate extends Component {
    constructor(props) {
        super(props)
        let invoice = props.invoiceId !== '' && props.allInvoices !== undefined && props.allInvoices.find(invoice => invoice.invoiceId === this.props.invoiceId);
        this.state = {
            products: [],
            paymentStatus:invoice !== undefined && invoice.paymentStatus !== undefined ? invoice.paymentStatus : 'Unpaid',
            orderId: invoice !== undefined && invoice.orderId !== undefined ? invoice.orderId : '',
            deliveryNotes: invoice !== undefined && invoice.deliveryNotes !== undefined ? invoice.deliveryNotes : '',
            lineItems: invoice !== undefined && invoice.invoiceLineItems !== undefined ? invoice.invoiceLineItems : [],
            allOrders: [],
            orderOptions: props.allOrders !== undefined && props.allOrders.filter(obj => invoice.orderId !== undefined ? obj : obj.orderStatus == 7).map(order =>
                <option key={`order-${order.orderId}`} value={order.orderId}>Order {order.orderId}</option>),
            modal: false,
            editLineItem: {},
            invoiceTotal: 0,
            error: '',
            createError:'',
            singleLineItem: { itemId: '', unitId: '', quantity: '', process: [] },
            allMetal:props.lookupData !== undefined ? props.lookupData.Metal:[],
            allUnit:props.lookupData !== undefined ? props.lookupData.Unit:[],
            allProcess:props.lookupData !== undefined ? props.lookupData.Process:[],
            allCustomers:[],
        }
    }
    updateModal = (itemId) => {
       
        let allLineItems = [...this.state.lineItems]
        let newSingleItemObj={}
        let singleLineItem = allLineItems.find(item => item.itemId == itemId)
        
       // let subTotal = this.calculateAmount({ itemId: singleLineItem.itemId, unitId: singleLineItem.unitId, quantity: singleLineItem.quantity,process:[...singleLineItem.process] }, false)
        
        let modalState = this.state.modal

        if (singleLineItem !== undefined) {
              newSingleItemObj = {
                itemId: singleLineItem.itemId.toString(),
                unitId: singleLineItem.unitId.toString(),
                quantity: singleLineItem.quantity.toString(),
                process: [...singleLineItem.process]
            }
            if (singleLineItem.lineItemId !== undefined) {
                newSingleItemObj.lineItemId = singleLineItem.lineItemId
            }
            let subTotal = this.calculateAmount(singleLineItem, false,[...singleLineItem.process])
           
            this.setState({
                singleLineItem: newSingleItemObj,
                modal: !modalState,
                invoiceTotal: subTotal
            })

        } else {
            this.setState({ modal: !modalState })
        }

    }
    toggleModal = () => {

        this.setState(prevState => {
            return {
                modal: !prevState.modal,
                singleLineItem: { itemId: '', unitId: '', quantity: '', process: [] },
                invoiceTotal:0
            }
        })
    }

    calculateAmount = (values, flag = true, e = '') => {
        
        if (e !== null) {
            if (e.target !== undefined && e.target.name !== undefined && e.target.value !== undefined) {
                values[e.target.name] = e.target.value
            } else {

                values.process = [...e]

            }
        } else {
            values.process = []
        }

        if (values.itemId !== '' && values.unitId !== '' && values.quantity !== '') {
            let allMetal = this.props.lookupData !== undefined && this.props.lookupData.Metal;
            let item = allMetal !== undefined && allMetal.find(metal => metal.itemId == values.itemId);
            let total = 0
            let points = 0;
            let waste = 0;
            if (values.process !== undefined && values.process !== null && values.process.length > 0) {
                let allProcess = this.props.lookupData !== undefined && this.props.lookupData.Process;
                values.process.forEach(process => {
                    let singleProcess = allProcess !== undefined && allProcess.find(pro => pro.itemId == process.value)
                    points += singleProcess.point;
                })
                waste = calculateWastage(values.quantity, values.itemId, points)
                values.process.forEach(process => {
                    let singleProcess = allProcess !== undefined && allProcess.find(pro => pro.itemId == process.value)
                    total = total + totalAmountWithWaistage(singleProcess, values, waste)
                })
            }
            waste = Math.round(parseFloat(waste) * 100) / 100;

            total = total + totalAmountWithWaistage(item, values, waste)
            if (flag === true) {
                this.setState({ invoiceTotal: total })
            } else {
                return total
            }

        }
    }
    addSubTotalRows = () => {
        const rows = [...this.state.products]
        let subTotal = 0
        if (rows.length > 0) {
            rows.forEach(row => {
                subTotal = subTotal + parseFloat(row.total)
            })
        }

        let incVat = subTotal * 0.07
        return [
            { id: '', action: '', description: this.props.t('SubTotal'), quantity: '', total: parseFloat(subTotal).toFixed(2) },
            { id: ' ', action: '', description: this.props.t('Vat'), quantity: '7%', total: parseFloat(incVat).toFixed(2) },
            { id: '  ', action: '', description: this.props.t('Total'), quantity: '', total: parseFloat((subTotal + incVat)).toFixed(2) }
        ];
    }

    addLineItem = (values, flag = true) => {

        let {allMetal,allUnit,allProcess} =this.state
       
        let item = allMetal.find(metal => metal.itemId == values.itemId);
        let rows = [...this.state.products];
        let lineItems = [...this.state.lineItems];

        
        let existItemIndex = lineItems.findIndex(item => item.itemId == values.itemId)

        if (existItemIndex >= 0) {
            lineItems.splice(existItemIndex, 1)
        }

        let existProductRowIndex = rows.findIndex(row => row.itemId == values.itemId)
        if (existProductRowIndex >= 0) {
            let noOf = rows[existProductRowIndex].processCount + 1
            rows.splice(existProductRowIndex, noOf)
        }

        let selectedProcess=[];
        if (this.props.invoiceId !== '' && flag === false) {
            selectedProcess = values.process !== undefined && values.process.length > 0 ? values.process.map(val => val.id) : undefined;
        } else {
            selectedProcess = values.process !== undefined && values.process.length > 0 ? values.process.map(val => val.value) : undefined;
        }
        
      
        let points = 0;
        if( allProcess !== undefined && selectedProcess.length > 0 ){
            selectedProcess.forEach(process => {
            let singleProcess = allProcess !== undefined && allProcess.find(pro => pro.itemId == process)
            let singleProcessPoint= singleProcess!==undefined?singleProcess.point:0
            points = points + singleProcessPoint
            })
        }
       
        let waste = 0;
        if (values.wastage === undefined) {
            let wasteCal = calculateWastage(values.quantity, values.itemId, points)
            waste = Math.round(parseFloat(wasteCal) * 100) / 100;
        }else{
            waste = Math.round(parseFloat(values.wastage) * 100) / 100;
        }

        //item.price = values.rate !== undefined ? values.rate : item.price;
        let rowObject={
            'id': rows.length + 1,
            'itemId': values.itemId,
            'processCount': values.process !== undefined && values.process.length,
            'description': item.text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name,
            'quantity': values.quantity + '( +' + waste + ' wastage ) ' + allUnit.find(unit => unit.unitId == values.unitId).text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name,
            'rate': item.price,
            'unit': allUnit.find(unit => unit.unitId == item.unitId).text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name,
            'total': parseFloat(totalAmountWithWaistage(item, values, waste)).toFixed(2),
            'action': <div className="d-flex">
                <a onClick={() => this.updateModal(item.itemId)}><i className="icon-pencil icon mr-2"></i></a>
                <a onClick={() => this.removeItem(item.itemId)}><i className="icon-trash icon"></i></a>
            </div>
        }
       
        rows.push(rowObject);
      
        let selectedProcessArray = [];
        
        if (allProcess !== undefined && values.process !== undefined && values.process.length > 0 && selectedProcess.length > 0) {
            selectedProcess.map(process => {
                let itemProcess = allProcess.find(pro => pro.itemId == process)
                
                if(itemProcess!==undefined){
                    //itemProcess.price = process.rate !== undefined ? process.rate : item.price;
                    let processName = itemProcess.text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name
                    let processObject={
                        'id': rows.length + 1,
                        'description': processName,
                        'quantity': values.quantity + '( +' + waste + ' wastage ) ' + allUnit.find(unit => unit.unitId == values.unitId).text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name,
                        'rate': itemProcess.price,
                        'unit': allUnit.find(unit => unit.unitId === item.unitId).text.find(text => text.langId === i18n.language === 'en' ? 1 : 2).name,
                        'total': parseFloat(totalAmountWithWaistage(itemProcess, values, waste)).toFixed(2),
                        'action': ''
                    };
                    rows.push(processObject);
                    selectedProcessArray.push({ "value": process, "label": processName })
                }
            })
            let lineItemArray = { ...values };
            lineItemArray.process = selectedProcessArray;
            let individualItemIndex = lineItems.findIndex(item => item.itemId === lineItemArray.itemId)
            if (individualItemIndex >= 0) {
                let individualItem = lineItems[individualItemIndex]
                lineItems[individualItemIndex] = { ...individualItem, ...lineItemArray }
            } else {
                lineItems.push(lineItemArray);
            }

        } else {
            lineItems.push({ ...values });
        }
       
        if (flag === true) {
            this.setState({ products: rows, modal: !this.state.modal, lineItems: lineItems, isModified: true })
        } else {
            return { products: rows, lineItems: lineItems }
        }



    }

    submitInvoice = (values) => {

        let allInvoice = [...this.state.lineItems]

        let allNewInvoice = []
        if (allInvoice.length > 0) {
            let allProcess = this.props.lookupData !== undefined && this.props.lookupData.Process;
            allInvoice.forEach(invoice => {
                if (invoice.process !== undefined && invoice.process.length > 0) {
                    let newInvoiceProcess = []
                    invoice.process.forEach(process => {
                        let item = allProcess !== undefined && allProcess.find(pro => pro.itemId === process.value)
                        newInvoiceProcess.push({ 'id': process.value, 'rate': item.price, value:process.value })
                    })
                    invoice.process = [...newInvoiceProcess]
                }
                if (this.props.invoiceId !== undefined || this.props.invoiceId !== '') {
                    // invoice.lineItemId= 
                }
                allNewInvoice.push(invoice)

            })
        }
        let invoice = {
            'orderId': values.orderId,
            'invoiceLineItems': allNewInvoice,
            'deliveryNotes': values.deliveryNotes,
        }
        let orderDetail=this.state.allOrders.find(order=>order.orderId==values.orderId);
        let customerDetail=this.state.allCustomers.find(customer=>customer.customerId==orderDetail.customerId);

        if (this.props.invoiceId === undefined || this.props.invoiceId === '') {
            if((customerDetail.isCreditCustomer!=1 && customerDetail.usedLimit<=0)|| (customerDetail.isCreditCustomer==1 && customerDetail.remainingLimit>=(this.state.invoiceTotal+this.state.invoiceTotal * 0.07)))
             {
                console.log('can create invoice')
                this.props.createInvoice(invoice);
             }
            else
            {
                console.log('can not create invoice')
                this.setState({ createError:"Credit Limit Exceeded, Invoice can not be created until the credit limit is increased. A notification has been added for the super admin."})
            }
        }
        else {
            console.log('can update invoice')
            this.props.updateInvoice(this.props.invoiceId, invoice);
        }
    }

    removeItem(itemId) {
        const allRowItems = [...this.state.products]
        let existProductRowIndex = allRowItems.findIndex(row => row.itemId == itemId)
        if (existProductRowIndex >= 0) {
            let noOf = allRowItems[existProductRowIndex].processCount + 1
            allRowItems.splice(existProductRowIndex, noOf)
        }
        this.setState({ products: allRowItems })
    }

    static getDerivedStateFromProps(nextProps, state) {
        let errors = '';
        if (state.error !== nextProps.error) {
            if (typeof nextProps.error === 'object') {
                errors = Object.values(nextProps.error)[0].join(',');
            }
            else {
                errors = nextProps.error;
            }
        }
        if (nextProps.invoice !== undefined && nextProps.invoiceSuccess !== undefined && nextProps.invoiceSuccess !== '' && state.invoice !== nextProps.invoice) {

            nextProps.toggle("Invoice List");
        }
        return {
            error: errors,
            allOrders: nextProps.allOrders,
            allCustomers: nextProps.allCustomers,
        };
    }

    componentDidMount() {

        let { lineItems } = this.state;
        let rowsData = { products: [], lineItems: [] }
        if (lineItems !== undefined && lineItems.length > 0) {
            lineItems.map((line) => {
                line.quantity = line.quantity - Math.round(parseFloat(line.wastage) * 100) / 100
                let subLineItems = this.addLineItem(line, false)

                if (subLineItems.products.length > 0) {
                    rowsData.products = [...rowsData.products, ...subLineItems.products]
                }
                if (subLineItems.lineItems.length > 0) {
                    rowsData.lineItems = [...rowsData.lineItems, ...subLineItems.lineItems]
                }
            })

            this.setState({
                products: rowsData.products,
                lineItems: rowsData.lineItems
            })
        }
    }
    render() {

        this.columns = [
            (isInvoicing() || isAdmin()) && this.state.paymentStatus=="Unpaid" ? {
                dataField: "action",
                text: this.props.t('Action'),
                headerStyle: () => {
                    return {
                        width: "9%",
                    };
                }
            } : false,
            {
                dataField: "id",
                text: this.props.t('Sno'),
                headerStyle: () => {
                    return {
                        width: "8%",
                    };
                }
            },
            {
                dataField: "description",
                text: this.props.t('DescriptionOfItem')
            },
            {
                dataField: "quantity",
                text: this.props.t('ItemQuantity'),
                headerAlign: 'right',
                align: 'right'
            },
            {
                dataField: "rate",
                text: this.props.t('Rate'),
                formatter: (cell) => {
                    return cell!==undefined?'฿' + cell:"";
                },
                headerAlign: 'right',
                align: 'right'
            },
            {
                dataField: "unit",
                text: this.props.t('Per'),
                headerAlign: 'right',
                align: 'right',
                headerStyle: () => {
                    return {
                        width: "8%",
                    };
                }
            },
            {
                dataField: "total",
                text: this.props.t('TotalAmount'),
                headerAlign: 'right',
                align: 'right',
                formatter: (cell) => {
                    return '฿' + cell;
                }
            }

        ].filter(val => val !== false);

        return (
            <React.Fragment>
                <Formik
                    enableReinitialize={false}
                    initialValues={{ orderId: this.state.orderId, deliveryNotes: this.state.deliveryNotes }}
                    onSubmit={(values) => this.submitInvoice(values)}
                    validationSchema={Yup.object().shape({ ...invoiceValidation })}
                >
                    {formprops => {
                        //console.log(formprops,'formprops')
                        const { values, initialValues, touched, errors, handleChange, handleBlur, handleSubmit, handleReset, setFieldValue } = formprops;
                        return (
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    {this.state.error !== '' ? <Col lg="12" md="12"><AlertMessage key={new Date().getTime()} error={this.state.error} /></Col> : ''}
                                </Row>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-md-3">{this.props.t('Order#')}</label>
                                            {(isInvoicing() || isAdmin()) && this.state.paymentStatus=="Unpaid" ? <div className="col-md-9">
                                                <select name="orderId"
                                                    className="form-control"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.orderId}
                                                    disabled={this.state.orderId !== '' ? true : false}
                                                >
                                                    <option key="orderIdEmpty" value="">--select an order-- </option>
                                                    {this.state.orderOptions}
                                                </select>
                                                {errors.orderId && touched.orderId && (<div className="input-feedback">{errors.orderId}</div>)}
                                            </div> : <label className="col-md-9">{this.state.orderId}</label>}
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group row">
                                            <label className="col-md-4">{this.props.t('DeliveryMessage')}</label>
                                            {(isInvoicing() || isAdmin()) && this.state.paymentStatus=="Unpaid"  ? <div className="col-md-8">
                                                <input type="text"
                                                    name="deliveryNotes"
                                                    className="form-control"
                                                    defaultValue={values.deliveryNotes}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur} />
                                                {errors.deliveryNotes && touched.deliveryNotes && (<div className="input-feedback">{errors.deliveryNotes}</div>)}
                                            </div> : <label className="col-md-7">{this.state.deliveryNotes}</label>
                                            }
                                        </div>
                                    </div>
                                    {(isInvoicing() || isAdmin()) && this.state.paymentStatus=="Unpaid"  ? <div className="col-md-3">
                                        <button disabled={values.orderId===''} type="button" className={`btn-primary-fill mr-1 float-right ${values.orderId===''?"btn-disabled":""}`} onClick={() => this.toggleModal()}>
                                            <i className="icon-plus icon mr-1 "></i>
                                            {this.props.t('AddInvoiceItem')}
                                        </button>
                                    </div> : ''}
                                </div>
                                <Row>
                                    {this.state.createError !== ''  ? <Col lg="12" md="12"><AlertMessage error={this.state.createError} /></Col> : ''}
                                </Row>
                                <div className="table-responsive invoice-details-table">
                                    {this.state.products.length > 0 && <BootstrapTable key="id" keyField="id" data={[...this.state.products, ...this.addSubTotalRows()]} columns={this.columns} />}
                                </div>
                                {(isInvoicing() || isAdmin()) && this.state.paymentStatus=="Unpaid"  ? <div className="row">
                                    <div className="col-md-12">
                                        {this.state.products.length > 0 && this.state.isModified === true && <button type="submit" className="btn-primary-fill mr-1 float-right">{this.props.t('SaveInvoice')}</button>}
                                    </div>
                                </div> : ''}
                            </form>
                        )
                    }}
                </Formik>
                <LineItemModal
                    {...this.props}
                    calculateAmount={this.calculateAmount}
                    modal={this.state.modal}
                    toggle={(index) => this.toggleModal(index)}
                    addLineItem={(values) => this.addLineItem(values)}
                    initialValues={this.state.singleLineItem}
                    invoiceTotal={this.state.invoiceTotal}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        lookupData: state.generalReducer.lookupData,
        allOrders: state.orderReducer.orders,
        allCustomers: state.customerReducer.customers,
        allInvoices: state.invoiceReducer.invoices,
        error: state.invoiceReducer.invoiceError,
        invoiceSuccess: state.invoiceReducer.invoiceSuccess,
        invoice: state.invoiceReducer.invoice,

    }
}

const mapDispatchToProps = {
    createInvoice: createInvoiceAction,
    updateInvoice: updateInvoiceAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(InvoiceCreate))