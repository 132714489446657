import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import i18n from '../i18n';
import Select from 'react-select';
import { AlertMessage } from '../helper/AlertBox';
import { paymentValidation } from '../Validation/CollectPaymentValidationSchema';
import { customerListAction } from '../redux/actions/CustomerActions';
import { invoiceListAction } from '../redux/actions/InvoiceActions';
import {onlyInvoicePayment } from '../redux/actions/PackageActions'

class CollectPayment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allCustomers: props.allCustomers,
            allInvoices: props.allInvoices,
            success:'',
            error:'',
            totalInvoiceAmount:0,
            customerId:'',
            invoiceId:[]
        }
    }
    calculateAmount=(val,e)=>{
        
        let total= 0
        if(e!==null && e.length>0){
            e.forEach(inv=>{
                let invObject =  this.props.allInvoices.find(ob=>ob.invoiceId==inv.value)
                total=total+ invObject.invoiceTotal
            })
            this.setState({totalInvoiceAmount:total,customerId:val.customerId,invoiceId:e})
        }
       
    }
    componentDidMount() {
        if (this.props.allCustomers === undefined)
            this.props.customerListAction();
        if (this.props.allInvoices === undefined)
            this.props.invoiceListAction();

       

    }


    deliverOrderToCustomer = (values) => {
       
        let formData = new FormData();
        for (let [key, value] of Object.entries(values)) {
            if (key === 'invoiceId') {
                for (let i = 0; i < values[key].length; i++) {
                    console.log(values[key][i].value)
                    formData.append('invoiceId[]', values[key][i].value);
                }
            } else {
                formData.append(key, value);
            }
        }
        formData.append('onlyInvoicePayment', 1);
    
        this.props.onlyInvoicePayment(formData);
        
    }
    static getDerivedStateFromProps(nextProps, state) {
		let nextRes = {};
		if (nextProps.success !== undefined && nextProps.success !== "" && nextProps.success !== state.success) {
            nextRes.success = nextProps.success;
            nextRes.totalInvoiceAmount=0;
            nextRes.customerId='';
            nextRes.invoiceId=[]
		}else{
			nextRes.success =''
        }
        if (nextProps.error !== undefined && nextProps.error !== "" && nextProps.error !== state.error) {
			nextRes.error = nextProps.error;
		}else{
			nextRes.error =''
		}
		return { ...nextRes }; 
	}


    render() {
      
        // const credit = this.state.allCustomers !== undefined && this.state.allCustomers.filter(customer => customer.customerId === customerId).find(customer => customer.creditLimit > 0 ? customer.creditLimit : '');
        // const creditLim = credit !== undefined && credit.creditLimit;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">{this.props.t('Collect Payment')}</div>
                                    </div>
                                </div>
                                    {this.state.success!=='' && <div className="alert alert-success">{this.state.success}</div>}
                                    {this.state.error!=='' && <div className="alert alert-danger">{this.state.error}</div>}
                            </div>
                            <CardBody> 
                                <Row>
                                {this.state.error !== '' && this.state.error !== undefined ? <Col lg="12" md="12"><AlertMessage key={new Date().getTime()} error={this.state.error} /></Col> : ''}
                                </Row>
                                <Formik
                                    enableReinitialize 
                                    initialValues={{ customerId: this.state.customerId, amount: this.state.totalInvoiceAmount ,invoiceId:this.state.invoiceId}}
                                    onSubmit={values => this.deliverOrderToCustomer(values)}
                                    validationSchema={Yup.object().shape({ ...paymentValidation })}
                                >
                                    {props => {
                                        const {
                                            values,
                                            touched,
                                            errors,
                                            dirty,
                                            setFieldValue,
                                            isSubmitting,
                                            handleChange,
                                            handleBlur,
                                            handleReset,
                                            handleSubmit,
                                        } = props;
                                        return (
                                            <form onSubmit={handleSubmit} >

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('Customer')}</label>
                                                            <select
                                                                type="select"
                                                                name="customerId"
                                                                value={values.customerId}
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur} >
                                                                <option value="">-- {this.props.t('customerName')} --</option>
                                                                {
                                                                    this.props.allCustomers !== undefined && this.props.allCustomers.map(customer => <option key={customer.customerId} value={customer.customerId}>{customer.fullName} {`(${this.props.t('CustomerId')}-${customer.customerId})`}</option>)
                                                                }
                                                            </select>
                                                            {errors.customerId && touched.customerId && (<div className="input-feedback">{errors.customerId}</div>)}
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('InvoiceId')}</label>
                                                            <Select 
                                                                options={this.props.allInvoices !== undefined && this.props.allInvoices.filter(invoice => invoice.paymentMode === 'Credit' && values.customerId == invoice.customerId)
                                                                .map(invoice => {
                                                                    return { 'value': invoice.invoiceId, 'label': `Invoice-${invoice.invoiceId}(฿${invoice.invoiceTotal})` }
                                                                } )}
                                                                isMulti={true} 
                                                                name="invoiceId"
                                                                defaultValue={values.invoiceId}
                                                                onChange={e => {
                                                                    this.calculateAmount(values, e);
                                                                    setFieldValue('invoiceId', e);
                                                                }}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('Amount')}</label>
                                                            <input
                                                                type="text"
                                                                name="amount"
                                                                value={values.amount}
                                                                className="form-control"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors.amount && touched.amount && (<div className="input-feedback">{errors.amount}</div>)}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('Mode')}</label>
                                                            <div className="col-md-12">
                                                                <div className="custom-control form-check form-check-inline mode">
                                                                    <input
                                                                        id="inline-checkbox1"
                                                                        name="mode"
                                                                        type="radio"
                                                                        className="form-check-input custom-control-input form-check-input"
                                                                        value="Cash"
                                                                        onChange={handleChange}
                                                                        checked={values.mode === "Cash" ? true : false}
                                                                    />
                                                                    <label htmlFor="inline-checkbox1" className="form-check-label custom-control-label form-check-label">{this.props.t('Cash')}</label>
                                                                </div>
                                                                <div className="custom-control form-check form-check-inline mode">
                                                                    <input
                                                                        id="inline-checkbox2"
                                                                        name="mode"
                                                                        type="radio"
                                                                        className="form-check-input custom-control-input form-check-input"
                                                                        value="Bank transfer"
                                                                        onChange={handleChange}
                                                                        checked={values.mode === "Bank transfer" ? true : false}
                                                                    />
                                                                    <label htmlFor="inline-checkbox2" className="form-check-label custom-control-label form-check-label">{this.props.t('BankTransfer')}</label>
                                                                </div>
                                                              
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {values.mode === "Bank Transfer" ? <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('BankPaymentReceipt')}</label>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                className="form-control"
                                                                name="BankPaymentReceipt"
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div> : ''}
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('SignatureFile')}</label>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                className="form-control"
                                                                name="SignatureFile"
                                                                onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-4">
                                                        <div className="float-right">
                                                            <Button type="submit" className="btn-primary-fill">{this.props.t('Save')}</Button>{' '}
                                                            <button type="button"
                                                                className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
                                                                onClick={handleReset}
                                                                disabled={isSubmitting}
                                                            >{this.props.t('Clear')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                    }}
                                </Formik>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        allCustomers: state.customerReducer.customers,
        allInvoices: state.invoiceReducer.invoices,
        allUsers: state.userReducer.users,
        success: state.packageReducer.packageSuccess,
        error: state.packageReducer.packageError,
    }
}

const mapDispatchToProps = {
    customerListAction,
    invoiceListAction,
    onlyInvoicePayment
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CollectPayment))