import React from 'react';
import { getWastagePercentage } from './General';
export const convertUnit = (input, output) => {

    if(input==output){
        return 1;
    }
    else if(input==2&&output==3){
        return 0.001;
    }
    else if(input==3&&output==2){
        return 1000;
    }
    else {
        return 1;
    }
}

export const calculateWastage=(quantity,metal,points,is_normal_job=1)=>{
   let percent=0;
    if(points>0) {
        const wastagePercentage=getWastagePercentage();
        if(wastagePercentage!==undefined) {
            let wastepercent = wastagePercentage.find(waste => waste.point == points && waste.itemId == metal);
            if (wastepercent !== undefined)
                percent = is_normal_job == 1 ? wastepercent.normalProcess : wastepercent.diffcultProcess;
        }
    }

    return parseFloat(quantity) * percent*0.01
}

export const totalAmountWithWaistage=(item,values,waistage)=>{ 
    return  ((parseFloat(values.quantity) + waistage) * convertUnit(values.unitId, item.unitId)) * parseFloat(item.price)
   
}


