import * as actionTypes from '../constant';
import {userData,lookupData} from '../../services/GeneralServices';

export const getUserData=()=>{
    
    return dispatch=>{
         userData().then(user=>{
             return dispatch({
                type: actionTypes.USER_PROFILE,
                value: user.data
            })
         }).catch(err=>
                console.log("Auth Action",err)
                )
    }
}
export const getLookupData=()=>{
    return dispatch=>{
        lookupData().then(lookup=>{
            return dispatch( {
                type: actionTypes.LOOKUP_DATA,
                value: lookup.data
            })
        }).catch(err=>console.log(" lookup data",err))
    }
}
