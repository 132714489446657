import * as Yup from 'yup';

export const invoiceValidation = {
    orderId: Yup.string()
        .required('Please select order.'),
}

export const LineItemValidation = {
    itemId: Yup.string()
        .required('Please select metal.'),
    unitId: Yup.string()
        .required('Please select unit.'),
    quantity: Yup.string().trim()
        .required('Please enter weight.')
}