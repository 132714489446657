import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { connect } from 'react-redux';
import { Formik, FieldArray, Field, getIn } from 'formik';
import * as Yup from 'yup';
import i18n from '../i18n';
import { createUserAction, updateUserAction } from '../redux/actions/UserActions';
import { userValidation } from '../Validation/UserValidationSchema';
import { getRole } from '../helper/General'
import { isAdmin } from '../helper/General'
import { AlertMessage } from '../helper/AlertBox'

class UserAdd extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: {},
			allUsers: this.props.allUsers,
			error: '',
			values: this.props.values || {}
		}
	}

	static getDerivedStateFromProps(nextProps, state) {
		let user = nextProps.allUsers !== undefined && nextProps.allUsers.find(user => {
			return user.userId === nextProps.userId
		});

		if (nextProps.userId !== undefined && Object.entries(state.user).length === 0) {

			return {
				...state,
				allUsers: nextProps.allUsers,
				user
			}
		}


		let errors = '';
		if (state.error !== nextProps.error) {

			if (typeof nextProps.error === 'object') {

				errors = Object.values(nextProps.error)[0].join(',');

			}
			else {

				errors = nextProps.error;
			}
			return {
				error: errors
			}
		}

		if (nextProps.success !== undefined && nextProps.success !== "") {
			nextProps.updateLayout("User List");
		}
		return null;

	}
	componentDidCatch() {
		console.log(this.state)
	}

	render() {

		let user = {}
		if (this.props.userId !== undefined && this.props.userId !== null) {
			user = this.props.allUsers.find(obj => obj.userId === this.props.userId)

		} else {
			user = { fullName: '', phone: '', email: '', password: '', roleId: '' }
		}

		let userTypeOptions = [1, 2, 3, 4, 5, 6, 7, 8,9]
		console.log(this.props.userId);

		return (
			<React.Fragment>
				<Formik
					enableReinitialize={true}
					initialValues={{

						fullName: user !== null && user.fullName !== undefined ? user.fullName : '',
						phone: user !== null && user.phone !== undefined ? user.phone : '',
						email: user !== null && user.email !== undefined ? user.email : '',
						// address: user !== null && user.address !== undefined ? user.address : '',
						password: user !== null && user.password !== undefined ? user.password : '',
						roleId: user !== null && user.roleId !== undefined ? user.roleId : '',

					}}
					onSubmit={values => {

						if (this.props.userId === undefined) {

							this.props.createUser(values);
						}
						else {
							if (values.password === '') {
								delete values.password;
							}
							this.props.updateUser(this.props.userId, values);
						}
					}}
					validationSchema={Yup.object().shape({
						...userValidation,
						password: Yup.string()
							.trim()
							.required((values) => {
								if (this.props.userId === undefined) {
									return 'Please enter password.'
								} else {
									return ''
								}

							})
					})
					}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit
						} = props;

						return (
							<form onSubmit={handleSubmit}>
								{this.state.error !== undefined && this.state.error !== '' ?
									<Row>
										<Col lg="12" md="12"><AlertMessage key={new Date().getTime()} error={this.state.error} /></Col>
									</Row>
									: ''}
								<div className="row">
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Name')}</label>
											<div className="col-md-9">
												<input
													type="text"
													name="fullName"
													value={values.fullName}
													className={
														errors.fullName && touched.fullName
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.fullName && touched.fullName && (<div className="input-feedback">{errors.fullName}</div>)}
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Mobile')}#</label>
											<div className="col-md-9">
												<input
													type="text"
													name="phone"
													value={values.phone}
													className={
														errors.phone && touched.phone
															? "form-control error"
															: "form-control"
													}
													readOnly={this.props.userId !== '' && this.props.userId !== undefined ? true : false}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.phone && touched.phone && (<div className="input-feedback">{errors.phone}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Email')}</label>
											<div className="col-md-9">
												<input
													type="email"
													name="email"
													value={values.email}
													className={
														errors.email && touched.email
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.email && touched.email && (<div className="input-feedback">{errors.email}</div>)}
											</div>

										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('UserType')}</label>
											<div className="col-md-9">
												<select
													name="roleId"
													className={
														errors.roleId && touched.roleId
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													defaultValue={user.roleId}
													disabled={this.props.userId !== '' && this.props.userId !== undefined ? true : false}
												>
													<option value="">--- {this.props.t('SelectUserType')} --- </option>
													{userTypeOptions.map(rId => <option value={rId} key={rId}>{this.props.t(getRole(rId))}</option>)}


												</select>
												{errors.roleId && touched.roleId && (<div className="input-feedback">{errors.roleId}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group row">
											<label className="col-md-3">{this.props.t('Password')}</label>
											<div className="col-md-9">
												<input
													type="password"
													name="password"
													className={this.props.userId === undefined &&
														errors.password && touched.password
														? "form-control error"
														: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}
												/>
												{errors.password && touched.password && (<div className="input-feedback">{errors.password}</div>)}
											</div>

										</div>
									</div>
									<div className="col-md-12 mt-4">
										<div className="float-right">
											<button type="submit"
												className={`btn-primary-fill mr-2`}
											>{this.props.t('Submit')}</button>
											<button type="button"
												className={`btn-primary-outline ${isSubmitting ? 'btn-disabled' : ''}`}
												onClick={() => { this.props.updateLayout('User List') }}
											>{this.props.t('Cancel')}</button>
											{/* <button type="button" className="btn-primary-outline" onClick={() => { this.props.updateLayout('User List') }}>{this.props.t('Cancel')}</button> */}
										</div>
									</div>
								</div>
							</form>)
					}}

				</Formik>

			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {

	return {
		allusers: state.userReducer.users,
		success: state.userReducer.userSuccess,
		error: state.userReducer.userError,
		user: state.userReducer.user,
	}
}

const mapDispatchToProps = {
	createUser: createUserAction,
	updateUser: updateUserAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(UserAdd));
