import * as actionTypes from '../constant';

import { transferPackage, getAssignedPackages, cancelPackage, acceptPackage, assignPackage } from '../../services/PackageServices';
import { deliverOrderToCustomer } from '../../services/OrderServices';

export const packageListAction = (type) => {
    return dispatch => {
        getAssignedPackages(type).then(res => {
            if (res.data !== undefined) {
                if(type=='incoming')
                    dispatch(getIncomingPackageList(res.data.data));
                else if(type=='outgoing')
                    dispatch(getOutgoingPackageList(res.data.data));
                else
                    dispatch(getAcceptedPackageList(res.data.data));

                dispatch(clearError());
            }
        }).catch(err => {
            console.log('error Package list action ', err);
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg ));
        });
    }
}

const getIncomingPackageList = (res) => {
    return {
        type: actionTypes.INCOMING_PACKAGE_LIST,
        value: res
    }
}
const getOutgoingPackageList = (res) => {
    return {
        type: actionTypes.OUTING_PACKAGE_LIST,
        value: res
    }
}
const getAcceptedPackageList = (res) => {
    return {
        type: actionTypes.ACCEPTED_PACKAGE_LIST,
        value: res
    }
}


export const transferPackageAction = (packages) => {
    return dispatch => {
        transferPackage(packages).then(res => {
            if (res.data !== undefined) {
                dispatch(transferPackagePost(packages));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error transfer package action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const transferPackagePost = (res) => {
    return {
        type: actionTypes.TRANSFER_PACKAGE,
        value: res
    }
}

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}

export const cancelPackageAction = (pack) => {
    return dispatch => {
        cancelPackage(pack).then(res => {
            if (res.data !== undefined) {
                dispatch(cancelPackagePost(pack));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error cancel package action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const cancelPackagePost = (res) => {
    return {
        type: actionTypes.CANCEL_PACKAGE,
        value: res
    }
}


export const assignPackageAction = (pack) => {
    return dispatch => {
        assignPackage(pack).then(res => {
            if (res.data !== undefined) {
                dispatch(assignPackagePost(res.data.data

                ));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error assign package action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const assignPackagePost = (res) => {
    return {
        type: actionTypes.ASSIGN_PACKAGE,
        value: res
    }
}

export const acceptPackageAction = (pack) => {
    return dispatch => {
        acceptPackage(pack).then(res => {
            if (res.data !== undefined) {
                dispatch(acceptPackagePost(pack));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error accept package action ', err);
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error( errormsg));
        });
    }
}

const acceptPackagePost = (res) => {
    return {
        type: actionTypes.ACCEPT_PACKAGE,
        value: res
    }
}


export const deliverOrderAction = (order) => {
    return dispatch => {
        deliverOrderToCustomer(order).then(res => {
            if (res.data !== undefined) {
                dispatch(deliverOrderPost(res.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

export const onlyInvoicePayment = (order) => {
    return dispatch => {
        deliverOrderToCustomer(order).then(res => {
            if (res.data !== undefined) {
                dispatch({
                    type: actionTypes.ONLY_INVOICE_PAYMENT,
                    value: res.data
                });
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            //var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
const deliverOrderPost = (res) => {
    return {
        type: actionTypes.DELIVER_ORDER,
        value: res.data
    }
}
