import * as actionTypes from '../constant';

const INITIAL_STATE = {}

export const itemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.ITEM_CREATE:
            return {
                ...state,
                itemResponse: action.value,
                itemSuccess: 'Item created successfully'
            };

        case actionTypes.ITEM_UPDATE:
            return {
                ...state,
                itemResponse: action.value,
                itemSuccess: 'Item updated successfully'
            };
        case actionTypes.ITEM_DELETE:
            return {
                ...state,
                itemResponse: action.value,
                itemSuccess: 'Item removed successfully'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                itemError: action.value,
                itemSuccess: ''
            };
        default:
            return { ...state, itemSuccess: '', itemError: '' };
    }
}