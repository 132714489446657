import React, {Component} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import InvoiceList from '../components/InvoiceList'
import InvoiceCreate from '../components/InvoiceCreate'
import InvoicePreview from '../components/InvoicePreview'
import InvoiceLogs from '../components/InvoiceLogs'

import { connect } from 'react-redux';
import { invoiceListAction } from '../redux/actions/InvoiceActions';

class Invoice extends Component{

    constructor(props){
        super(props);

        this.state = {
            hideShow : false,
            invoiceLayout : "Invoice List",
            invoiceId:''
        }
    }

    toggleButton = () =>{
        this.setState(prevState => ({
          hideShow: !prevState.hideShow
        }));
    }

    componentDidMount() {
        this.props.invoices();
    }

    listInvoice = (props) =>{
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> {this.props.t('InvoiceList')}</div>
                        </div>
                        <div className="col-md-6">
                            { props.userProfile!==undefined && props.userProfile.roleId==5 && <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Create Invoice") }}>  {this.props.t('CreateInvoice')}</button>}
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InvoiceList {...props} toggle={(id)=>this.updateLayout("Create Invoice",id)}/>
                </CardBody>
            </Card>
        )
    }

    createInvoice = (props) => {
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.state.invoiceId!=undefined && this.state.invoiceId!=''?this.props.t('Invoice')+"#: "+this.state.invoiceId:this.props.t('CreateInvoice')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Invoice List") }}>{this.props.t('InvoiceList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InvoiceCreate invoiceId={this.state.invoiceId} toggle={(layout)=>this.updateLayout(layout)} />
                </CardBody>
            </Card>
        )
    }

    logsInvoice = (props) => {
        return(
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> {this.props.t('InvoiceLogs')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Invoice Logs") }}> {this.props.t('InvoiceList')} </button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InvoiceLogs/>
                </CardBody>
            </Card>
        )
    }

    showPreview = () =>{

    }

    updateLayout(layout,invoiceId='') {
        this.setState({invoiceLayout : layout,invoiceId:invoiceId});
    }

    getInvoiceComponent(layout){
        
        let showLayout = '';

        switch(layout){
            case "Invoice List" : showLayout=this.listInvoice(this.props);
            break;
            case "Create Invoice" : showLayout=this.createInvoice(this.props);
            break;
            case "Invoice Preview" : showLayout=this.previewInvoice(this.props);
            break;
            case "Invoice Logs" : showLayout=this.logsInvoice(this.props);
            break;
            default:
            showLayout = "";
        }

        return showLayout;

    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                invoiceLayout: "Invoice List"
            })
        }
    }

	render(){
        return(
			<div className="container-fluid">
                <Row>
                    <Col md="12">
                        {this.getInvoiceComponent(this.state.invoiceLayout)}
                    </Col>
                </Row>
            </div>
			)
	}
}

const mapStateToProps = state => {
    return {
        allInvoices: state.invoiceReducer.invoices,
        userProfile:state.authReducer.userProfile
    }
    
}

const mapDispatchToProps = {
    invoices: invoiceListAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Invoice))