import Api from '../Api'

export const createItem = (data) => {
    return Api.post('item',data);
}

export const updateItem = (itemId,data) => {
    return Api.put('item/'+itemId,data);
}
export const removeItem = (itemId) => {
    return Api.delete('item/'+itemId);
}
