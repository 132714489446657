import * as actionTypes from '../constant';

const INITIAL_STATE = {};

export const finishedProductReducer = (state = INITIAL_STATE, action) => {
	let finishedProductList = [];
	let index = false;
	switch (action.type) {
		case actionTypes.FINISHED_PRODUCT_LIST:
			return {
				...state,
				finishedProductList: action.value,
				finishedProductSuccess: ''
			};
		case actionTypes.FINISHED_PRODUCT_ADD:
			return {
				...state,
				finishedProductList:[...state.finishedProductList, action.value],
				finishProductResponse: action.value,
				finishProductSuccess: 'Finished Product added  successfully',
			};
		case actionTypes.FINISHED_PRODUCT_UPDATE:
			finishedProductList = [...state.finishedProductList];
			index = finishedProductList.findIndex(product => {
				
				
                return product.productId === action.value.productId
            });
            
            
            finishedProductList[index] = action.value
			return {
				...state,
				finishedProductList:finishedProductList,
				finishProductResponse: action.value,
				finishProductSuccess: 'Finished Product updated  successfully',
			};
		default:
			return {...state,finshProductError: '',metalSuccess:'',metalError: '',finishProductSuccess:''};
	}
}
