import React, { Component } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import CustomerAdd from '../components/CustomerAdd'
import CustomerCreditExceedingList from '../components/CustomerCreditExceedingList'
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import ViewCustomer from '../components/ViewCustomer'
import { isInvoicing,isAccounting, isAssortmentAdmin } from "../helper/General";

class CreditExceedingCustomers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creditExceedingLayout: "Credit Exceeding List"
        }
    }
    componentDidMount() {
        document.title = this.props.t('CreditCustomers')
    }
    getCreditExceddingComponent(layout) {
        switch (layout) {
            case "Credit Exceeding List": return this.CustomerCreditExceedingList(this.props);
            case "View Customer Order": return this.customerOrderPreview(this.props);
            case "Add Customer": return this.customerCreate(this.props);
            default:
                return false;
        }
    }
    CustomerCreditExceedingList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('CreditCustomers')}</div>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CustomerCreditExceedingList handleOrderView={(layout,customerId)=>this.updateLayout('View Customer Order',customerId)} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    customerCreate = (props, id) => {

        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.state.customerId ? this.props.t('EditCustomer') : this.props.t('AddCustomer')} </div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Customer List") }}> {this.props.t('CustomerList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <CustomerAdd {...this.props} customerId={this.state.customerId} updateLayout={this.updateLayout} />
                </CardBody>
            </Card>
        )
    }

    customerOrderPreview = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title"> {this.props.t('ViewCustomerDetails')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Credit Exceeding List") }}> {this.props.t('CreditCustomers')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <ViewCustomer {...this.props} editCustomer={this.updateLayout} customerId={this.state.customerId} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, id = '') => {
        this.setState({ creditExceedingLayout: layout, customerId: id });
    }


    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                      {this.getCreditExceddingComponent(this.state.creditExceedingLayout)}
                    </div>
                </div>
            </div>
        )
    }
}

export default withOrderInvoiceHoc(CreditExceedingCustomers)
