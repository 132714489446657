import React, { Component, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, CardTitle, CardText } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { packageListAction, transferPackageAction, cancelPackageAction, acceptPackageAction, deliverOrderAction } from '../redux/actions/PackageActions'
import { userListAction } from '../redux/actions/UserActions'
import { invoiceListAction } from '../redux/actions/InvoiceActions';
import { customerListAction } from '../redux/actions/CustomerActions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import i18n from '../i18n';
import * as Yup from 'yup';
import { transferPackageValidation, deliverOrderValidation } from '../Validation/PackageValidationSchema'
import { Formik } from 'formik';
import { isInvoicing,isDeliveryAdmin } from "../helper/General";
import { getPacakgeDeatail } from '../services/PackageServices'
import { AlertMessage } from '../helper/AlertBox';
import { isAdmin } from "../helper/General";

const PackageInfoModal = (props) => {
	console.log(props.lookupData, props.lookupData !== undefined && props.lookupData['Metal'].find(metal => metal.itemId === 10).text.find(lang => lang.langId == 1).name, i18n.language)
	return (
		<Modal isOpen={props.packageDetailModal} toggle={props.packageDetailModalOff} size="md">
			<ModalHeader toggle={props.packageDetailModalOff} className="text text-center">Invoice Detail</ModalHeader>
			<ModalBody>
				{props.packageMode === 'Payment' && <div className="card">
					<ul>
						<li>Amount: <b>{props.packageDetail.amount}</b></li>
						{props.packageDetail.mode === 'Metal' && <React.Fragment>
							<li>Metal: <b>{props.packageDetail.metalId} {props.packageDetail.unitId}</b></li>

						</React.Fragment>}
						<li>Mode: <b>{props.packageDetail.mode}</b></li>
						<li>Type: <b>{props.packageDetail.type}</b></li>
						<li>Date: <b>{props.packageDetail.createdAt}</b></li>
						<li>Signature : {props.packageDetail.signatureFile !== null && <img src={props.packageDetail.signatureFile} />}</li>
					</ul>
				</div>}

			</ModalBody>
			<ModalFooter><Button onClick={props.packageDetailModalOff} className="float-right">Ok</Button></ModalFooter>
		</Modal>
	)
}



class Packages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: '1',
			modal: false,
			deliverModal: false,
			packageDetailModal: false,
			acceptedPackages: props.acceptedPackages,
			incomingPackages: props.incomingPackages,
			outgoingPackages: props.outgoingPackages,
			allCustomers: props.allCustomers,
			lookupData:props.lookupData,
			success: "",
			orderSuccess: "",
			orderError: "",
			invoiceId: "",
			invoiceTotal: 0,
			packageDetail: null,
			packageMode: null,
			deilverOrder: [],
			customerId: '',
		}
	}
	toggleTab = (tab) => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab, success: "" })
		}
	}
	packageDetail = (packageInfo) => {
		getPacakgeDeatail(packageInfo.invoiceId, packageInfo.packageType, packageInfo.transactionLineId)
			.then(res => {
				this.setState({
					packageDetailModal: true,
					packageDetail: res.data.data,
					packageMode: packageInfo.packageType
				})
			})
			.catch(err => console.log(err))
	}
	packageDetailModalOff = () => {
		this.setState({
			packageDetailModal: false
		})
	}
	toggleModal = (row, cell) => {
		this.setState({
			modal: !this.state.modal,
			trackingId: row.trackingId,
			packageName: row.packageName
		})
	}
	calculateInvoiceTotal = (e) => {

		let invoiceTotal = 0
		for (let i = 0; i < e.target.options.length; i++) {
			let option = e.target.options[i];
			if (option.selected) {
				let singleInv = this.props.allInvoices.find(invoice => invoice.invoiceId == option.value)
				invoiceTotal = singleInv !== undefined ? invoiceTotal + parseInt(singleInv.invoiceTotal) : invoiceTotal
			}
		}

		this.setState({ invoiceTotal })
	}
	toggleDeliverModal = (invoiceId = '') => {

		let singleInv = invoiceId !== '' && this.props.allInvoices !== undefined && this.props.allInvoices.find(invoice => invoice.invoiceId == invoiceId)
		this.setState({ deliverModal: !this.state.deliverModal, invoiceId: invoiceId, invoiceTotal: singleInv.invoiceTotal, customerId: singleInv.customerId })
	}

	packageDetailFormatter = (cell, row) => {
        if(row.packageType=='Casting Package'){
            let metaldata=cell.metalId && this.state.lookupData!==undefined && this.state.lookupData['Metal'].find(metal => metal.itemId === cell.metalId);
            let metalName=metaldata?metaldata.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name:'';
            return `${cell.amount} ${this.state.lookupData!==undefined && this.state.lookupData['Unit'].find(unit => unit.unitId === cell.unitId).text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name} ${metalName!=''?metalName:cell.metalName}`;
        }
        else if(row.packageType=='Finished Product'){
            return `${row.productName} - ${row.customerName} (${this.props.t('Customer Id')} -${row.customerId} )`;
        }
        else{
            return `${row.customerName} (${this.props.t('Customer Id')} -${row.customerId} )`;
        }
    }

	componentDidMount() {
		this.props.userListAction();
		this.props.invoiceListAction();
		if (isAdmin()) {
			this.props.packageListAction('accepted');
		}
		else {
			this.props.packageListAction('incoming');
			this.props.packageListAction('outgoing');
			this.props.packageListAction('accepted');
			this.props.customerListAction();
		}
	}

	transferPackage = (values) => {
		let pack = {
			'trackingId': values.trackingId,
			'assignedTo': values.assignedTo,
		}
		this.props.transferPackageAction(pack);
	}

	deliverOrderToCustomer = (values) => {
		
		let formData = new FormData();
		for (let [key, value] of Object.entries(values)) {
			if (key === 'invoiceId') {
				for (let i = 0; i < values[key].length; i++) {
					formData.append('invoiceId[]', values[key][i]);
				}
			} else {
				formData.append(key, value);
			}

		}
		this.props.deliverOrderAction(formData);
		this.setState({ deliverModal: false })
	}

	cancelPackageRequest = (trackingId) => {
		this.props.cancelPackageAction({ trackingId: trackingId });
	}

	acceptPackageRequest = (trackingId) => {
		this.props.acceptPackageAction({ trackingId: trackingId });
	}

	static getDerivedStateFromProps(nextProps, state) {
		let nextRes = {};
		if (nextProps.success !== undefined && nextProps.success !== "" && nextProps.success !== state.success) {
			nextRes.success = nextProps.success;
			nextRes.modal = false;
			nextRes.deliverModal = false;
		}else{
			nextRes.success =''
		}
		if(nextProps.orderSuccess!==undefined && nextProps.orderSuccess!==state.orderSuccess){
			nextRes.orderSuccess=nextProps.orderSuccess
		}else{
			nextRes.orderSuccess=''
		}
		if(nextProps.orderError!==undefined && nextProps.orderError!==state.orderSuccess){
			nextRes.orderError=nextProps.orderError
		}else{
			nextRes.orderError=''
		}
		if (nextProps.acceptedPackages !== undefined)
			nextRes.acceptedPackages = nextProps.acceptedPackages;
		if (nextProps.incomingPackages !== undefined)
			nextRes.incomingPackages = nextProps.incomingPackages;
		if (nextProps.outgoingPackages !== undefined)
			nextRes.outgoingPackages = nextProps.outgoingPackages;
		if (nextProps.order !== undefined)
			nextRes.order = nextProps.order;
        if (nextProps.lookupData !== undefined)
			nextRes.lookupData = nextProps.lookupData;
		return { ...nextRes }; 
	}

	render() {
		
		const { t } = this.props;
		if (isAdmin()) {
			this.admincolumns = [
				{
					dataField: "invoiceId",
					formatter: (cell) => {
						return cell != 0 ? cell : '--';
					},
					text: this.props.t('InvoiceID')
				},
				{
					dataField: "packageName",
					text: this.props.t('PackageName')
				},
				{
					dataField: "packageDetails",
					text: this.props.t('Detail'),
					formatter: this.packageDetailFormatter
				},
				{
					dataField: "assignedTo",
					text: this.props.t('Delivery man'),
					formatter: (cell, row) => {
						let assigneduser = this.props.allUsers !== undefined && this.props.allUsers.find(user => cell === user.userId)
						return (assigneduser !== undefined && assigneduser !== false && assigneduser.fullName)
					}
				}
			];
		}
		else {
			this.acceptedcolumns = [
				{
					dataField: "invoiceId",
					formatter: (cell) => {
						return cell != 0 ? cell : '--';
					},
					text: this.props.t('InvoiceID')
				},
				{
					dataField: "packageName",
					text: this.props.t('PackageName')
				},
				{
					dataField: "packageDetails",
					text: this.props.t('Detail'),
					formatter: this.packageDetailFormatter
				},
				{
					dataField: "createdAt",
					text: this.props.t('TransferPackage'),
					formatter: (cell, row) => {
						return <React.Fragment>
							<button className="btn btn-primary-fill mr-2"
								onClick={() => this.toggleModal(row, cell)}>{this.props.t('Transfer')}</button>
							{row.packageType == "Package" && !isInvoicing() && !isDeliveryAdmin() && <button className="btn btn-success"
								onClick={() => this.toggleDeliverModal(row.invoiceId)}>{this.props.t('Deliver')}</button>}
						</React.Fragment>;
					},
					headerAlign: 'center',
					align: 'center'
				}
			];

			this.incomingcolumns = [
				{
					dataField: "invoiceId",
					formatter: (cell) => {
						return cell != 0 ? cell : '--';
					},
					text: this.props.t('InvoiceID')
				},
				{
					dataField: "packageName",
					text: this.props.t('PackageName')
				},
				{
					dataField: "packageDetails",
					text: this.props.t('Detail'),
					formatter: this.packageDetailFormatter
				},
				{
					dataField: "createdAt",
					text: this.props.t('Action'),
					formatter: (cell, row) => {
						return <React.Fragment>
							<button className="btn btn-success btn-sm mr-2" onClick={() => {
								if (window.confirm(`Are you sure you wish to accept ${row.packageName} package request?`))
									this.acceptPackageRequest(row.trackingId)
							}
							}>{this.props.t('Accept')}</button>
							{/*<button className="btn btn-secondary btn-sm">Reject</button>*/}
						</React.Fragment>;
					},
					headerAlign: 'center',
					align: 'center'
				}
			];

			this.outgoingcolumns = [
				{
					dataField: "invoiceId",
					formatter: (cell) => {
						return cell != 0 ? cell : '--';
					},
					text: this.props.t('InvoiceID')
				},
				{
					dataField: "packageName",
					text: this.props.t('PackageName')
				},
				{
					dataField: "packageDetails",
					text: this.props.t('Detail'),
					formatter: this.packageDetailFormatter
				},
				/*{
				 dataField: "status",
				 text: this.props.t('Status')
				 },*/
				{
					dataField: "createdAt",
					formatter: (cell, row) => {
						return <React.Fragment>
							<button className="btn btn-secondary btn-sm" onClick={() => {
								if (window.confirm(`Are you sure you wish to cancel ${row.packageName} package request?`))
									this.cancelPackageRequest(row.trackingId)
							}
							}>{this.props.t('RejectRequest')}</button>
						</React.Fragment>;
					},
					text: this.props.t('Action'),
					headerAlign: 'center',
					align: 'center'
				}
			];
		}

		return (
			<React.Fragment>
				<Card className="card-box">
					<div className="card-header">
						<div className="row">
							<div className="col-md-12">
								<div className="title">{t('Packages')} </div>

							</div>
							<Col lg="12" md="12">
							{this.state.orderError !== '' && <AlertMessage error={this.state.orderError} />}
							{this.state.orderSuccess !== '' && <AlertMessage success={this.state.orderSuccess} />}
							{this.state.success !== '' && <AlertMessage success={this.state.success} /> }
							</Col>
						</div>
					</div>
					<CardBody>
						{isAdmin() ?
							<div className="table-responsive  mt-4">
								{this.state.acceptedPackages !== undefined && <BootstrapTable key={`adminPackageTable${new Date().getTime()}`} keyField="trackingId" data={this.state.acceptedPackages.filter((pack) => pack.packageType !== 'Payment')} columns={this.admincolumns} />}
							</div>
							: <div>
								<Nav tabs>
									<NavItem>
										<NavLink
											className={this.state.activeTab == '1' ? 'active' : ''}
											onClick={() => { this.toggleTab('1'); }}
										>
											{this.props.t('PackageList')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={this.state.activeTab == '2' ? 'active' : ''}
											onClick={() => { this.toggleTab('2'); }}
										>
											{this.props.t('NewIncomingPackages')}
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											className={this.state.activeTab == '3' ? 'active' : ''}
											onClick={() => { this.toggleTab('3'); }}
										>
											{this.props.t('PendingOutgoingPackages')}
										</NavLink>
									</NavItem>
								</Nav>
								
								<TabContent activeTab={this.state.activeTab}>
									<TabPane tabId="1">
										<div className="table-responsive  mt-4">
											{this.state.acceptedPackages  !== undefined  && <BootstrapTable key={`acceptedPackageTable${new Date().getTime()}`} keyField="trackingId" data={this.state.acceptedPackages.filter((pack) => pack.packageType !== 'Payment')} columns={this.acceptedcolumns} />}
										</div>
									</TabPane>
									<TabPane tabId="2">
										<div className="table-responsive mt-4">
											{this.state.incomingPackages !== undefined && <BootstrapTable key={`incomingPackageTable${new Date().getTime()}`} keyField="trackingId" data={this.state.incomingPackages.filter((pack) => pack.packageType !== 'Payment')} columns={this.incomingcolumns} />}
										</div>
									</TabPane>
									<TabPane tabId="3">
										<div className="table-responsive  mt-4">
											{this.state.outgoingPackages !== undefined && <BootstrapTable key={`outgoingPackageTable${new Date().getTime()}`} keyField="trackingId" data={this.state.outgoingPackages.filter((pack) => pack.packageType !== 'Payment')} columns={this.outgoingcolumns} />}
										</div>
									</TabPane>
								</TabContent>
							</div>}

					</CardBody>
				</Card>
				{/*<PackageInfoModal 
					{...this.props}
					packageDetailModal={this.state.packageDetailModal} 
					packageMode={this.state.packageMode} 
					packageDetail={this.state.packageDetail} 
					packageDetailModalOff={()=>this.packageDetailModalOff()}
				/>*/}
				<TransferModal allUsers={this.props.allUsers} transferPackage={(values) => this.transferPackage(values)} trackingId={this.state.trackingId} packageName={this.state.packageName} modal={this.state.modal} toggle={this.toggleModal} {...this.props} />
				<DeliverModal
					modal={this.state.deliverModal}
					deliverOrderToCustomer={(values) => this.deliverOrderToCustomer(values)}
					toggle={() => this.toggleDeliverModal()}
					allCustomers={this.props.allCustomers}
					{...this.props}
					invoiceId={this.state.invoiceId}
					invoiceTotal={this.state.invoiceTotal}
					customerId={this.state.customerId}
					calculateInvoiceTotal={(val) => this.calculateInvoiceTotal(val)}
				/>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		incomingPackages: state.packageReducer.incomingPackages,
		outgoingPackages: state.packageReducer.outgoingPackages,
		acceptedPackages: state.packageReducer.acceptedPackages,
		allUsers: state.userReducer.users,
		allCustomers: state.customerReducer.customers,
		userProfile: state.authReducer.userProfile,
		success: state.packageReducer.packageSuccess,
		allInvoices: state.invoiceReducer.invoices,
		orderError: state.packageReducer.orderError,
		orderSuccess: state.packageReducer.orderSuccess,
		order: state.packageReducer.order,
		lookupData: state.generalReducer.lookupData,
	}
}
const mapDispatchToProps = {
	packageListAction,
	transferPackageAction,
	userListAction,
	cancelPackageAction,
	acceptPackageAction,
	deliverOrderAction,
	invoiceListAction,
	customerListAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Packages))



export const TransferModal = (props) => {
	const { modal, toggle, allUsers } = props;


	const usersOptions = allUsers !== undefined && allUsers.filter(user => user.roleId !== 3).map(user => <option key={`user-${user.userId}`} value={user.userId}>{user.fullName}</option>);
	return (
		<div>
			<Modal isOpen={modal} toggle={() => toggle('', '')} size="md">
				<ModalHeader toggle={() => toggle('', '')}>{props.t('TransferPackage')}</ModalHeader>
				<Formik
					initialValues={{ assignedTo: '', trackingId: props.trackingId }}
					onSubmit={(values) => props.transferPackage(values)}
					validationSchema={Yup.object().shape({ ...transferPackageValidation })}
				>
					{formprops => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
						} = formprops;
						return (
							<form onSubmit={handleSubmit}>
								<ModalBody>
									<div className="row">
										<div className="col-md-12">
											<div className="form-group row">
												<h5 className="col-md-8">{props.t('Package')}#: {props.packageName}</h5>
												<input type="hidden" name="trackingId" />
											</div>
											<div className="form-group row">
												<label className="col-md-4">{props.t('selectDeliveryMan')}</label>
												<div className="col-md-8">
													<select name="assignedTo"
														className={
															errors.assignedTo && touched.assignedTo
																? "form-control error"
																: "form-control"
														}
														onChange={handleChange}
														onBlur={handleBlur}>
														<option key="userEmpty" value="">{props.t('SelectUsers')}</option>
														{usersOptions}
													</select>
													{errors.assignedTo && touched.assignedTo && (<div className="input-feedback">{errors.assignedTo}</div>)}
												</div>
											</div>
										</div>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" className="btn-primary-fill">{props.t('Ok')}</Button>{' '}
									<Button color="secondary" onClick={() => toggle('', '')}>{props.t('Cancel')}</Button>
								</ModalFooter>
							</form>
						)
					}}
				</Formik>
			</Modal>
		</div>
	);
}


export const DeliverModal = (props) => {
	
	const { modal, allCustomers, customerId } = props;
	const { toggle } = props;
	const [mode, setMode] = useState('Cash');
	const credit = allCustomers !== undefined && allCustomers.filter(customer => customer.customerId === customerId).find(customer => customer.creditLimit > 0 ? customer.creditLimit : '');
	const creditLim = credit !== undefined && parseFloat(credit.creditLimit);
	
	//let invoiceOptions = props.allInvoices !== undefined && props.allInvoices.filter(invoice => invoice.paymentStatus === 'Unpaid' && props.customerId === invoice.customerId).map(invoice => <option key={`Invoice-${invoice.invoiceId}`} value={invoice.invoiceId} >{`Invoice-${invoice.invoiceId}`}</option>);
	let invoiceOptions = props.acceptedPackages !== undefined && props.acceptedPackages.filter(invoice => invoice.packageType === 'Package' && props.customerId === invoice.customerId).map(invoice => <option key={`Invoice-${invoice.invoiceId}`} value={invoice.invoiceId} >{`Invoice-${invoice.invoiceId}`}</option>);

	return (
		<div>
			<Modal isOpen={modal} toggle={toggle} size="md">
				<ModalHeader toggle={toggle}>{props.t('DeliverPackage')}</ModalHeader>
				<Formik
					enableReinitialize={true}
					initialValues={{ amount: props.invoiceTotal !== undefined ? props.invoiceTotal.toFixed(2) : 0, mode: 'Cash', invoiceId: [props.invoiceId] }}
					onSubmit={(values) => props.deliverOrderToCustomer(values)}
					validationSchema={Yup.object().shape({ ...deliverOrderValidation })}

				>
					{formprops => {
						const {
							values,
							touched,
							errors,
							setFieldValue,
							handleChange,
							handleBlur,
							handleSubmit
						} = formprops;


						return (
							<form onSubmit={handleSubmit}>
								<ModalBody>
									<div className="row">
										<div className="col-md-12">

											<div className="form-group row">
												<label className="col-md-4">{props.t('Amount')}</label>
												<div className="col-md-8">
													<input type="text" name="amount" className={errors.amount && touched.amount
														? "form-control error"
														: "form-control"}
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.amount}
													/>
													{errors.amount && touched.amount && (<div className="input-feedback">{errors.amount}</div>)}
												</div>
											</div>
											<div className="form-group row">
												<label className="col-md-4">{props.t('InvoiceID')}</label>
												<div className="col-md-8">
													<select name="invoiceId"
														multiple={true}
														className={
															errors.invoiceId && touched.invoiceId
																? "form-control error"
																: "form-control"}
														onChange={handleChange, props.calculateInvoiceTotal}
														defaultValue={[props.invoiceId]}
														onBlur={handleBlur}>
														<option key="invoiceEmpty" value="">-- {props.t('SelectInvoice')} --</option>
														{invoiceOptions}
													</select>
													{/*errors.invoiceId && touched.invoiceId && (<div className="input-feedback">{errors.invoiceId}</div>)*/}
												</div>
											</div>
											<div className="form-group row">
												<label className="col-md-4">{props.t('Mode')}</label>
												<div className="col-md-8 px-4">
													<div className="custom-control form-check d-block w-100">
														<input id="inline-checkbox1" name="mode" type="radio" className="form-check-input custom-control-input form-check-input"
															value="Cash"
															checked={mode == 'Cash' ? true : false}
															onChange={(e) => { setMode('Cash'); setFieldValue('mode', 'Cash') }}
														/>
														<label htmlFor="inline-checkbox1" className="form-check-label custom-control-label form-check-label">{props.t('Cash')}</label>
													</div>
													<div className="custom-control form-check d-block w-100">
														<input id="inline-checkbox2" name="mode" type="radio" className="form-check-input custom-control-input form-check-input"
															value="Bank Transfer"
															checked={mode == 'Bank Transfer' ? true : false}
															onChange={(e) => { setMode('Bank Transfer'); setFieldValue('mode', 'Bank Transfer') }}
														/>
														<label htmlFor="inline-checkbox2" className="form-check-label custom-control-label form-check-label">{props.t('BankTransfer')}</label>
													</div>
													{creditLim > values.amount && <div className="custom-control form-check d-block w-100">
														<input id="inline-checkbox3" name="mode" type="radio" className="form-check-input custom-control-input form-check-input"
															value="Credit"
															checked={mode == 'Credit' ? true : false}
															onChange={(e) => { setMode('Credit'); setFieldValue('mode', 'Credit') }}
														/>
														<label htmlFor="inline-checkbox3" className="form-check-label custom-control-label form-check-label">{props.t('isCredit')}</label>
													</div>}

													{/*<div className="custom-control form-check d-block w-100">
														<input id="inline-checkbox3" name="mode" type="radio" className="form-check-input custom-control-input form-check-input"
															value="metal"
															checked={mode == 'metal' ? true : false}
															onChange={(e) => { setMode('metal'); setFieldValue('mode', 'metal') }}
														/>
														<label htmlFor="inline-checkbox3" className="form-check-label custom-control-label form-check-label">Metal</label>
													</div>*/}
												</div>

											</div>
											{mode === 'Bank Transfer' ? <div className="form-group row">
												<label className="col-md-4">{props.t('BankPaymentReceipt')}</label>
												<div className="col-md-8">
													<input type="file" accept="image/*" className="form-control" name="BankPaymentReceipt" onChange={handleChange} />
												</div>
											</div> : ''}
											{/*{mode == 'metal' ?
												<>
													<div className="form-group row">
														<label className="col-md-4">{props.t('Metal')}</label>
														<div className="col-md-8">

															<select
																name="metal"
																className={errors.metal && touched.metal
																	? "form-control error"
																	: "form-control"}

																onChange={handleChange}
																onBlur={handleBlur}>
																<option key="emptyItem" value="">-- {props.t('Metal')} --</option>
																{props.lookupData !== undefined && props.lookupData['Metal'] !== undefined && props.lookupData['Metal'].length > 0 && props.lookupData['Metal'].map(data => {
																	return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
																		{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
																	</option>
																})}
															</select>
															{errors.metal && touched.metal && (<div className="input-feedback">{errors.metal}</div>)}
														</div>

													</div>
													<div className="form-group row">
														<label className="col-md-4">{props.t('UnitId')}</label>
														<div className="col-md-8">
															<select
																className={errors.unitId && touched.unitId
																	? "form-control error"
																	: "form-control"}
																name="unitId"
																onChange={handleChange}
																onBlur={handleBlur}
															>
																<option key="emptyunit" value="">-- {props.t('Unit')} --</option>
																{
																	props.lookupData !== undefined && props.lookupData['Unit'] !== undefined &&
																	props.lookupData['Unit'].length > 0 &&
																	props.lookupData['Unit'].filter(unit => unit.unitId === 2 || unit.unitId == 3).map(data => {
																		return <option key={`opt-unit-${data.unitId}`}
																			value={data.unitId}>{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}</option>
																	})
																}
															</select>
															{errors.unitId && touched.unitId && (<div className="input-feedback">{errors.unitId}</div>)}
														</div>
													</div>
												</> : ''}*/}
											<div className="form-group row">
												<label className="col-md-4">{props.t('SignatureFile')}</label>
												<div className="col-md-8">
													<input type="file" accept="image/*" className="form-control" name="SignatureFile" onChange={handleChange} />
												</div>
											</div>


										</div>
									</div>
								</ModalBody>
								<ModalFooter>
									<Button type="submit" className="btn-primary-fill">{props.t('Save')}</Button>{' '}
									<Button color="secondary" onClick={() => toggle('', '')}>{props.t('Cancel')}</Button>
								</ModalFooter>
							</form>
						)
					}}
				</Formik>
			</Modal>
		</div>
	);
}


