import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getRole } from '../helper/General';
import { AlertMessage } from '../helper/AlertBox';
import { ledgerListAction } from '../redux/actions/LedgerActions'
import dateFormat from 'dateformat';

class LedgerList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			ledgerStatement: this.props.ledgerStatement,
			success: props.success,
			error: props.error,
		}

	}

	 static getDerivedStateFromProps(nextProps, state) {
			let nextRes = { ...state };

			if (nextProps.ledgerStatement !== undefined){
				nextRes.ledgerStatement = nextProps.ledgerStatement;
			}
			if (nextProps.success !== undefined && nextProps.success !== "") {
				nextRes.success = nextProps.success;
		   
		}

			return { ...nextRes };
	}

	componentDidMount() {
		this.props.ledgerListAction(this.props.userProfile.userId);
	}

	dateFormatter = (cell) => {
		return dateFormat(cell, "dd/mm/yyyy  hh:MM tt")
	}

	render() {
		const { SearchBar } = Search;
		let totals = [];

		const handleDataChange = ({ dataSize }) => {
		  totals = [];
		}

		let columns = [
			{
				dataField: "from_user_name",
				text: this.props.t('From'),
				formatter: (cell, row) => {
					if (row.from ===this.props.userProfile.userId)
						return cell+' ('+this.props.t('Me')+')';
					else
						return cell;
				}
			},
			{
				dataField: "to_user_name",
				text: this.props.t('To'),
				formatter: (cell, row) => {
					if (row.to ===this.props.userProfile.userId)
						return cell+' ('+this.props.t('Me')+')';
					else
						return cell;
				}
			},
			{
				dataField: "amount",
				text: this.props.t('Amount'),
				footerAttrs: (column, colIndex) => ({ 'colSpan': 4 }),
				footer: (columnData,column,colIndex) => columnData.map((item,index) => {
				    if(index===0)
				         totals = [];
					if(item===undefined)
						return  "";

					if (this.props.ledgerStatement[index].to === this.props.userProfile.userId)
					{
						if(totals['Received']===undefined)
							totals['Received']=0;
						totals['Received']= (parseFloat(totals['Received'])+parseFloat(item)).toFixed(2);
					}
					else {
					  /*  console.log(item);*/
						if(totals['Sent']===undefined)
							totals['Sent']=0;
						totals['Sent']=  (parseFloat(totals['Sent'])+parseFloat(item)).toFixed(2);
					}
					let totalarray=Object.entries(totals);
					let foot=totalarray.map((row)=>{
						return `${row['0']} : ${'฿'+row['1']}`;
					})
					return <div dangerouslySetInnerHTML={{__html: `Totals <br>${foot.join('<br>')}`}}/> ;
				}).pop()
			},
			{
				dataField: "date",
				text: this.props.t('Date'),
				formatter: this.dateFormatter,

			}
		];

		if (this.props.ledgerStatement === undefined) {
			return ''
		}

		return (
			<React.Fragment>
				<Row>
					{this.state.success !== undefined && this.state.success !== '' ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<Col lg="12" md="12">
						<ToolkitProvider
							keyField="id"
							key={`LedgerTable`}
							data={this.props.ledgerStatement}
							columns={columns}
							search
						>
							{
								props => (
									<div>
										<div className="mb-2">
											<SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
										</div>
										<div>
											<BootstrapTable onDataSizeChange={ this.handleDataChange } {...props.baseProps} pagination={paginationFactory()} />
										</div>
									</div>
								)
							}
						</ToolkitProvider>
					</Col>
				</Row>
			</React.Fragment>
		)

	}
}


const mapStateToProps = state => {
	return {
		ledgerStatement: state.ledgerReducer.ledgerStatement,
		success: state.ledgerReducer.ledgerSuccess,
		error: state.ledgerReducer.ledgerError,
		userProfile:state.authReducer.userProfile
	}
}
const mapDispatchToProps = {
	ledgerListAction,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LedgerList));