import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { withTranslation } from 'react-i18next';
import TransferMoneyForm from '../components/TransferMoneyForm'
import LedgerList from '../components/LedgerList'
import ViewUser from '../components/ViewUser'
import { isAccounting } from "../helper/General";
class Ledger extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ledgerLayout: "Ledger List",
            userId: null
        }
    }

    ledgerList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('Ledger')}</div>
                        </div>
                        {isAccounting()
                          &&
                          <div className="col-md-6">
                              <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Transfer Money") }}>{this.props.t('Transfer Money')}  </button>
                          </div>
                        }
                    </div>
                </div>
                <CardBody>
                    <LedgerList />
                </CardBody>
            </Card>
        )
    }

    transferMoney = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('Transfer')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Ledger List") }}>{this.props.t('Ledger')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <TransferMoneyForm updateLayout={this.updateLayout} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, id = null) => {
        this.setState({ ledgerLayout: layout, userId: id });
    }

    getLedgerComponent(layout) {
        switch (layout) {
            case "Ledger List": return this.ledgerList(this.props);

            case "Transfer Money": return this.transferMoney(this.props);

            default:
                return false;
        }
    }
    componentDidMount() {
        document.title = "Ledger"
    }

    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                ledgerLayout: "Ledger List"
            })
        }
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {this.getLedgerComponent(this.state.ledgerLayout)}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Ledger);