import React, {Component} from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink} from 'reactstrap'
import InventoryList from '../components/InventoryList'
import { withTranslation } from 'react-i18next'


class Inventory extends Component{

	constructor(props){
    super(props);

    this.state = {
        customerLayout : "Inventory List"
        }
    }

    inventoryList = (props) =>{
    	return(
    		<Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('Inventory Report')}</div>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <InventoryList handleOrderView={this.updateLayout}/>
                </CardBody>
            </Card>
            )
    }

    

    updateLayout = (layout) =>{
        this.setState({customerLayout : layout});
    }

    getCustomerComponent(layout){

        var showLayout = '';

        switch(layout){
            case "Inventory List" : showLayout=this.inventoryList(this.props);
            break;
            default:
            showLayout = "";
        }

        return showLayout;

    }

	render(){
		let showLayout = this.getCustomerComponent(this.state.customerLayout)
		return(
			<div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
			)
	}
}

export default withTranslation()(Inventory)