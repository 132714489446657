import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { verifyPayment } from '../services/OrderServices'
import i18n from '../i18n';
import { withTranslation } from 'react-i18next'

class MaterialList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'Cash',
            metal: '',
            allOrder: [],
            oldOrder: [],
            success: '',
            allUsers: [],
            allCustomers: [],
            lookupData: []
        }
    }

    static getDerivedStateFromProps(nextProps,nextState) {
         
        let nextobject={
            allUsers: nextProps.allUsers,
            allCustomers: nextProps.allCustomers,
            lookupData: nextProps.lookupData,
            oldOrder: nextProps.allOrder,
            allOrder: nextProps.allOrder,
        }
       
        return nextobject
    }
       

   

    verifyPaymentEvent = (transactionLineId) => {
        verifyPayment({ "transactionLineId": transactionLineId }).then(res => {
            let allOrder = [...this.state.allOrder]
            let index = allOrder.findIndex((order) => order.transactionLineItemId === transactionLineId)
            this.setState({
                allOrder: allOrder.splice(index, 1),
                success: "Payment approved successfully."
            })
        }).catch(err => {
            this.setState({
                //allOrder:allOrder.splice(index,1),
                error: err.data.message
            })
        })
    }

    handleTypeChange = (e) => {
        let value = e.target.value;
         this.setState({type: value,metal:''});
       
    }

    handleMetalChange = (e) => {
        console.log(e.target.value)
        let value = e.target.value;
        this.setState({metal: value});

    }
    render() {
        let langId = this.props.i18n.language === 'en' ? 1 : 2;
       
        this.columns = [

            {
                dataField: "deliveryUserId",
                text: this.props.t("DeliveryMan"),
                sort: true,
                formatter: (cell, row) => {
                    let fullName = this.state.allUsers != undefined && this.state.allUsers.find(user => user.userId === row.deliveryUserId)
                    return fullName !== undefined ? fullName.fullName : '';
                }
            },
            {
                dataField: "customerId",
                text: this.props.t("CustomerName"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                },
                formatter: (cell, row) => {
                    let fullName = this.state.allCustomers != undefined && this.state.allCustomers.find(customer => customer.customerId === row.customerId)
                    return fullName !== undefined ? fullName.fullName : '';

                }
            },
            {
                dataField: "paymentType",
                text: this.props.t("Type"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                }
            },
            {
                dataField: "amount",
                text: this.state.type==='Metal'?this.props.t("Quantity"):this.props.t("Amount"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                },
                formatter: (cell) => cell !== null ? this.state.type!=='Metal'?'฿'+parseFloat(cell).toFixed(2):parseFloat(cell).toFixed(2) : ''
            },
            {
                dataField: "amountType",
                text: this.props.t("AmountType"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                }
            },/*
            {
                dataField: "invoiceId",
                text: this.props.t("InvoiceID"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                },
                formatter: (cell) => cell !== null ? '#' + cell : ''
            },*/
            
            this.state.type==='Metal'?{
                dataField: "metalId",
                text: this.props.t("MaterialName"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                },
                formatter: (cell, row) => {
                    let metal='';
                    if (row !== undefined && row.amountType === 'Metal') {
                        let metalData=this.state.lookupData !== undefined && this.state.lookupData.Metal.find((metal) => metal.itemId === row.metalId )
                        metal =metalData!==undefined && metalData.text.find(txt => txt.langId === langId).name
                    }
                    return metal;

                }
            }:false,
            this.state.type==='Metal' ? {
                dataField: "unitId",
                text: this.props.t("Unit"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { padding: '16px 5px' };
                },
                formatter: (cell, row) => {
                    
                    if (row !== undefined && row.amountType === 'Metal') {
                        return this.state.lookupData !== undefined &&
                            this.state.lookupData.Unit.map((unit) => {
                                let name;
                                if (unit.unitId === cell) {
                                    name = unit.text.find(text => text.langId === langId).name
                                }
                                return name;
                            })

                    }

                }
            }:false,
            {
                dataField: "action",
                text: this.props.t("Action"),
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '80px', padding: '16px 5px' };
                },
                formatter: (cell, row) => {

                    return <React.Fragment>
                        <div className="d-flex justify-content-around">
                            <button type="button" className="btn btn-sm mr-2" onClick={() => this.props.handleOrderView('Edit Material', row)}><i className="icon-eye icon"></i></button>
                            <button type="button" className="btn btn-sm" title="Approve" onClick={() => { if (window.confirm(`Are you sure you wish to approve this Payment ${row.invoiceId!==null?'#'+row.invoiceId:''}?`)) this.verifyPaymentEvent(row.transactionLineItemId) }}><i className="icon-check icon"></i></button>
                        </div>
                    </React.Fragment>
                }
            }
        ].filter(val => val !== false);

        let metalOptions = this.state.lookupData !== undefined && this.state.lookupData.Metal.map((metal) => {
            return <option key={`metalOpt${metal.itemId}`} value={metal.itemId}>{metal.text.find(text => text.langId === langId).name}</option>
        });

        return (

            <div className="table-responsive material-table">
                <div className="row inventory-table">
                    <div className="col-md-4">
                        <div className="form-group row">
                            <label className="col-md-3">{this.props.t('Type')}</label>
                            <div className="col-md-9">
                                <select className="form-control" onChange={this.handleTypeChange} value={this.state.type} name="amountType">
                                    <option value="Cash">{this.props.t('Money')}</option>
                                    <option value="Metal">{this.props.t('Material')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {this.state.type == 'Metal' ?
                        <div className="col-md-4">
                            <div className="form-group row">
                                <label className="col-md-3">{this.props.t('Material')}</label>
                                <div className="col-md-9">
                                    <select className="form-control" onChange={(e)=>this.handleMetalChange(e)}>
                                        <option value="">--select--</option>
                                        {metalOptions}
                                    </select>
                                </div>
                            </div>
                        </div>
                        : ''}
                </div>
                <Row>
                    {this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><div className="alert alert-success">{this.state.success}</div></Col> : ''}
                </Row>
                <Row>
                    {this.state.error !== '' && this.state.error !== undefined ? <Col lg="12" md="12"><div className="alert alert-danger">{this.state.error}</div></Col> : ''}
                </Row>
                <BootstrapTable
                    key={'mlist'}
                    keyField={'transactionLineItemId'}
                    data={[...this.state.allOrder].filter(ord=>ord.amountType===this.state.type).filter(ordr=>this.state.metal===''|| (this.state.metal!=='' && ordr.metalId==this.state.metal))} columns={this.columns}
                    pagination={paginationFactory()}
                />

            </div>

        )
    }
}


export default withTranslation()(MaterialList)