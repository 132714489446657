import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import BranchAdd from '../components/BranchAdd'
import BranchList from '../components/BranchList'
import { withTranslation } from 'react-i18next'

class Branches extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerLayout: "Branch List",
            branchId: ''
        }
    }

    branchList = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('BranchList')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Create Branch") }}>{this.props.t('CreateBranch')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchList handleOrderView={(layout, branchId) => this.updateLayout(layout, branchId)} {...this.props} />
                </CardBody>
            </Card>
        )
    }

    branchCreate = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('CreateBranch')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>{this.props.t('BranchList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd updateLayout={(layout, branchId) => this.updateLayout(layout, branchId)} />
                </CardBody>
            </Card>
        )
    }

    editBranch = (props) => {
        return (
            <Card className="card-box">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title">{this.props.t('CreateBranch')}</div>
                        </div>
                        <div className="col-md-6">
                            <button className="btn-primary-fill float-right mt-3" onClick={() => { this.updateLayout("Branch List") }}>{this.props.t('BranchList')}</button>
                        </div>
                    </div>
                </div>
                <CardBody>
                    <BranchAdd {...this.props} updateLayout={(layout, branchId) => this.updateLayout(layout, branchId)} branchId={this.state.branchId} />
                </CardBody>
            </Card>
        )
    }

    updateLayout = (layout, branchId = '') => {
        this.setState({ customerLayout: layout, branchId: branchId });
    }


    componentDidUpdate (prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                customerLayout: "Branch List"
            })
        }
    }

    getCustomerComponent(layout) {

        var showLayout = '';

        switch (layout) {
            case "Branch List": showLayout = this.branchList(this.props);
                break;
            case "Create Branch": showLayout = this.branchCreate(this.props);
                break;
            case "Edit Branch": showLayout = this.editBranch(this.props);
                break;
            default:
                showLayout = "";
        }

        return showLayout;

    }

    render() {
        let showLayout = this.getCustomerComponent(this.state.customerLayout)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        {showLayout}
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Branches)