import * as Yup from 'yup';

export const customerValidation = {
    email: Yup.string().trim()
        .email()
        .required("Please enter email."),
    fullName: Yup.string().trim()
        .required("Please enter name.")
        .min(6, "Name must be minimum of 6 characters"),
    phone: Yup.string().trim()
        .required("Please enter mobile no.")
        .matches(/^\d{10}$/, 'Please enter valid number.'),
    address: Yup.string().trim()
        .required("Please enter address.")
        .max(50, "Must be maximum 50 characters.")

}

export const customerStatementValidation = {
    fromDate: Yup.date()
        .required("Please select from date")
        .max(new Date()),
    endDate: Yup.date()
        .required("Please select end date")
        .max(new Date()),

}
