import React from 'react'
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next'
import { orderListAction } from '../redux/actions/OrderActions'
import { customerListAction } from '../redux/actions/CustomerActions'
import { userListAction } from '../redux/actions/UserActions'
const OrderInvoiceHoc = BaseComponent => {

    class Wrapper extends React.Component {
        componentDidMount() {
            this.props.orderListAction();
            this.props.customerListAction();
            this.props.userListAction();
        }
        render() {
            return (<BaseComponent  {...this.props} />)
        }
    }
    const mapStateToProps = state => {
        return {
            allOrders: state.orderReducer.orders,
            lookupData:state.generalReducer.lookupData,
            allCustomers: state.customerReducer.customers,
            allUsers: state.userReducer.users,
            userProfile:state.authReducer.userProfile,
            finsihedProductList:[]
        }
    }
    const mapDispatchToProps = {
        orderListAction,
        customerListAction,
        userListAction,
    }
    return withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Wrapper));

}

export default OrderInvoiceHoc