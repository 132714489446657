import React, {Component} from 'react'
import {Row, Col, Card, CardBody} from 'reactstrap'
import {withTranslation} from 'react-i18next'
import {connect} from 'react-redux';
import {Formik} from 'formik';
import * as Yup from 'yup';
import i18n from '../i18n';
import {AlertMessage} from '../helper/AlertBox';
import {materialValidation} from '../Validation/CollectMaterialValidationSchema';
import { customerListAction ,collectMaterialAction} from '../redux/actions/CustomerActions';

class CollectMaterial extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allCustomers: props.allCustomers,
            error:'',
            success:''
        }
    }

    componentDidMount() {
        if (this.props.allCustomers === undefined)
            this.props.customerListAction();
    }

    static getDerivedStateFromProps(nextProps,state){
        if(nextProps.success!==undefined && nextProps.success!==state.success)
        {
            return {success:nextProps.success}
        }

        if(nextProps.error!==undefined && nextProps.error!==state.error)
        {
            return {error:nextProps.error}
        }
        return null;
    }

    render() {
        console.log(this.state,this.props)
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">{this.props.t('Collect Material')}</div>
                                    </div>
                                </div>
                            </div>
                            <CardBody>
                                <React.Fragment>
                                    <Row>
                                        {this.state.error !== '' ? <Col lg="12" md="12"><AlertMessage
                                                key={new Date().getTime()}
                                                error={this.state.error}/></Col> : ''}
                                    </Row>
                                    <Row>
                                        {this.state.success !== '' ? <Col lg="12" md="12"><AlertMessage
                                                key={new Date().getTime()}
                                                success={this.state.success.split('-')[1]}/></Col> : ''}
                                    </Row>
                                    <Formik
                                        initialValues={{customerId: '', metalId: '', quantity: '', unitId: ''}}
                                        onSubmit={async (values, {setSubmitting, setErrors, setStatus, resetForm}) => {
                                            try {
                                                await this.props.collectMaterialAction(values.customerId,{"metals":[{...values}]});
                                                resetForm()
                                                setStatus({success: true})
                                            } catch (error) {
                                                setStatus({success: false})
                                                setSubmitting(false)
                                                setErrors({submit: error.message})
                                            }
                                        }}
                                        validationSchema={Yup.object().shape({...materialValidation})
                                        }>
                                        {props => {
                                            const {
                                                values,
                                                touched,
                                                errors,
                                                isSubmitting,
                                                handleChange,
                                                handleBlur,
                                                handleSubmit,
                                                handleReset
                                            } = props;
                                            return (

                                                <form onSubmit={handleSubmit}>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group row">
                                                                <label
                                                                    className="col-md-4">{this.props.t("Customer")}</label>
                                                                <div className="col-md-8">
                                                                    <select
                                                                        type="select"
                                                                        name="customerId"
                                                                        value={values.customerId}
                                                                        className={
                                                                            errors.customerId && touched.customerId
                                                                                ? "form-control error"
                                                                                : "form-control"
                                                                        }
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}>
                                                                        <option value="">
                                                                            -- {this.props.t('customerName')} --
                                                                        </option>
                                                                        {
                                                                            this.props.allCustomers !== undefined && this.props.allCustomers.map(customer =>
                                                                                <option key={customer.customerId}
                                                                                        value={customer.customerId}>{customer.fullName} {`(${this.props.t('CustomerId')}-${customer.customerId})`}</option>)
                                                                        }
                                                                    </select>
                                                                    {errors.customerId && touched.customerId && (<div
                                                                        className="input-feedback">{errors.customerId}</div>)}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="form-group row">
                                                                <label
                                                                    className="col-md-4">{this.props.t("Metal")}</label>
                                                                <div className="col-md-8">
                                                                    <select
                                                                        name="metalId"
                                                                        className="form-control"
                                                                        value={values.metalId}
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                                        {this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
                                                                            return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                                {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                                            </option>
                                                                        })}
                                                                    </select>
                                                                    {errors.metalId && touched.metalId && (<div className="input-feedback">{errors.metalId}</div>)}

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group row">
                                                                <label
                                                                    className="col-md-4">{this.props.t("Quantity")}</label>
                                                                <div className="col-md-8">
                                                                    <input type="text"
                                                                           name="quantity"
                                                                           className="form-control"
                                                                           onChange={handleChange}
                                                                           value={values.quantity}/>
                                                                    {errors.quantity && touched.quantity && (<div
                                                                        className="input-feedback">{errors.quantity}</div>)}
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group row">
                                                                <label
                                                                    className="col-md-3">{this.props.t("Unit")}</label>
                                                                <div className="col-md-9">
                                                                    <select className="form-control"
                                                                            name="unitId"
                                                                            value={values.unitId}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                    >
                                                                        <option key="emptyunit" value="">-- {this.props.t('Unit')} --</option>
                                                                        {
                                                                            this.props.lookupData !== undefined && this.props.lookupData['Unit'] !== undefined &&
                                                                            this.props.lookupData['Unit'].length > 0 &&
                                                                            this.props.lookupData['Unit'].filter(unit => unit.unitId === 2 || unit.unitId == 3).map(data => {
                                                                                return <option key={`opt-unit-${data.unitId}`}
                                                                                               value={data.unitId}>{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}</option>
                                                                            })
                                                                        }
                                                                    </select>
                                                                    {errors.unitId && touched.unitId && (<div className="input-feedback">{errors.unitId}</div>)}

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mt-4">
                                                            <div className="float-right">
                                                                <button type="submit"
                                                                        className="btn-primary-fill mr-2" onClick={handleSubmit}>{this.props.t("Save")}</button>
                                                                <button type="button" className="btn-primary-outline"
                                                                        onClick={handleReset}>{this.props.t("Cancel")}</button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </form>
                                            )
                                        }}
                                    </Formik>
                                </React.Fragment>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        allCustomers: state.customerReducer.customers,
        lookupData: state.generalReducer.lookupData,
        success: state.customerReducer.metalSuccess,
        error: state.customerReducer.metalError,
    }
}

const mapDispatchToProps = {
    customerListAction,
    collectMaterialAction
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(CollectMaterial))