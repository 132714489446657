import * as Yup from 'yup';

export const orderValidation = {
    customerId: Yup.string()
        .required('Please select customer.'),
    orderRemarks: Yup.string().trim()
        .required('Please enter remarks.'),
    pickupType: Yup.string()
        .required('Please select pickUp type').nullable(),
    pickupAddress: Yup.string().trim()
        .required('Please enter pickUp address.').nullable(),
    deliveryType: Yup.string()
        .required('Please select delivery type.').nullable(),
    deliveryAddress: Yup.string().trim()
        .required('Please enter delivery address.').nullable(),
    quantity: Yup.number()
        .required('Please enter quantity.')
        .positive('Quantity should be greater than 0')
        .integer()
    /*orderImages:Yup.string()
        .required("Required"),*/
}