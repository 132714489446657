import Api from '../Api'

export const invoices = () => {
    return Api.get('invoice')
};

export const createInvoice = (invoice) => {
    return Api.post('invoice',invoice);
}

export const updateInvoice = (invoiceId,invoice) => {
    return Api.put('invoice/'+invoiceId,invoice);
}