import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import i18n from '../i18n';
import BootstrapTable from "react-bootstrap-table-next"
import { getPackages, getStatement } from '../services/UserServices'
import { getRole } from '../helper/General'
import { withTranslation } from 'react-i18next'
import dateFormat from 'dateformat';

class ViewUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			packageVisibility: false,
			statementVisibility: false,
			packages: [],
			statement: {}
		}
	}
	statementVisibilityHandle = () => {
		let statement = []
		getStatement(this.props.userId).then(res => {
			if (res.data.data !== undefined) {
				this.setState({
					packageVisibility: false,
					statementVisibility: true,
					statement: res.data.data
				});
			}
		}).catch(err => console.log(err))
	}
	toggleVisibility = () => {
	
		getPackages(this.props.userId).then(res => {
			if (res.data.data !== undefined) {
				this.setState({
					packageVisibility: !this.state.visibility,
					statementVisibility: false,
					packages: res.data.data
				});
			}
		}).catch(err => console.log(err))

	}

	render() {
		let langId = i18n.language === 'en' ? 1 : 2;
		let user = this.props.allUsers.find(user => user.userId === this.props.userId)
		this.columns = [
			{ dataField: "trackingId", text: "Package Id" },
			{ dataField: "invoiceId", text: "Invoice Id" },
			{ dataField: "packageName", text: "Package Name" }
		];
		const cashTransactions = this.state.statement && this.state.statement['Cash'] !== undefined && this.state.statement['Cash']['transactions'] !== undefined &&
			this.state.statement['Cash']['transactions'].map((transaction, index) =>
				<div className="row" key={`cash${index}`}
				>
					<div className="col-lg-6 col-md-6 col-xs-6">{dateFormat(transaction.time, "dd/mm/yyyy  hh:MM tt")}</div>
					<div className="col-lg-6 col-md-6 col-xs-6 float-data">฿ {transaction.amount + " " + transaction.type}</div>
				</div>
			)
		const metalTransactions = this.state.statement && this.state.statement['Metal'] !== undefined &&
			this.state.statement['Metal'].map((transaction, i) => {
				let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId == transaction.unitId).text.find(text => text.langId === langId).name
				let metalName = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId == transaction.metalId).text.find(text => text.langId === langId).name

				return <div className="card" key={`metal${transaction.metalId}${i}`}>
					<div className="card-header">
						<a className="card-link" data-toggle="collapse" href={`#metal${transaction.metalId}`}>{metalName} <span className="float-right">{`${transaction.total} ${unit} ${transaction.type}`}</span></a>
					</div>
					<div id={'metal' + transaction.metalId} className="collapse" data-parent="#accordionStatement">
						<div className="card-body">
							{
								transaction.transactions.map((tran, index) =>
									<div className="row" key={`metal${index}`}>
										<div className="col-lg-6 col-md-6 col-xs-6">{dateFormat(tran.time, "dd/mm/yyyy  hh:MM tt")}</div>
										<div className="col-lg-6 col-md-6 col-xs-6 float-data">{`${tran.quantity} ${unit} ${tran.type}`}</div>
									</div>)
							}
						</div>
					</div>
				</div>
			})

		return (
			<React.Fragment>
				<h5><strong>{user.fullName}</strong></h5>
				<h6>{this.props.t('Email')} : {user.email}</h6>
				<h6>{this.props.t('Role')} : {getRole(user.roleId)}</h6>
				<button className="btn-primary-fill mt-2" onClick={() => this.props.updateLayout('Edit User', this.props.userId)} >{this.props.t('Edit')}</button>
				<button className="btn-primary-fill ml-2" onClick={this.toggleVisibility}>{this.state.visibility ? this.props.t('HideStock') : this.props.t('ViewStock')}</button>
				<button className="btn-primary-fill ml-2" onClick={this.statementVisibilityHandle}>{this.props.t('Statement')}</button>

				{this.state.packageVisibility && <div className="mt-3">
					{this.state.packages.length > 0 ? <BootstrapTable key='trackingId' keyField='trackingId' data={this.state.packages}
						columns={this.columns} /> : <h6>{this.props.t('NoanyPackagefound')}</h6>}
				</div>
				}
				{this.state.statementVisibility
					&& <div id="accordionStatement" className="mt-4 accordionStatement">
						{Object.keys(this.state.statement).length > 0 ? Object.keys(this.state.statement).map(keyName => {
							if (keyName === 'Metal') {
								return metalTransactions
							} else if (keyName === 'Cash') {
								return (
									<div className="card" key={keyName}>
										<div className="card-header">
											<a className="card-link" data-toggle="collapse" href="#statement1">{keyName} <span className="float-right">{this.state.statement !== undefined && this.state.statement[keyName] !== undefined ? '฿ ' + this.state.statement[keyName].total + " " + this.state.statement[keyName].type : 0}</span></a>
										</div>
										<div id="statement1" className="collapse" data-parent="#accordionStatement">
											<div className="card-body">
												{cashTransactions}
											</div>
										</div>
									</div>
								)
							} else {
								return
							}
						})
							: <h6>{this.props.t('Notransactionfound')}.</h6>}

					</div>

				}
			</React.Fragment>
		)
	}
}

export default withTranslation()(ViewUser)