import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Row, Col, Card, CardBody, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import i18n from '../i18n';
import { statusFormatter, isAdmin } from '../helper/General'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { customerStatementAction } from '../redux/actions/CustomerActions';
import * as Yup from 'yup';
import { customerStatementValidation } from '../Validation/CustomerValidationSchema'
import { Formik } from 'formik';
import { isInvoicing, isAccounting, getRole } from "../helper/General";
import NoImage from '../assets/images/thumbnail.png';
import dateFormat from 'dateformat';

class ViewCustomer extends Component {
	constructor(props) {
		super(props);
		const currdate = new Date();
		currdate.setHours(0, 0, 0, 0);
		this.state = {
			userProfile: this.props.userProfile,
			allCustomers: this.props.allCustomers,
			allUsers: this.props.allUsers,
			customerId: this.props.customerId,
			allOrders: this.props.allOrders,
			allUsers: this.props.allUsers,
			lookupData: this.props.lookupData,
			visibility: false,
			statementVisibility: false,
			endDate: currdate.getTime(),
			fromDate: currdate.getTime() - 864E5 * 15,
			customerStatement: props.customerStatement,
		}
	}

	toggleVisibility = () => {
		this.setState({
			visibility: !this.state.visibility
		});
	}

	toggleStatementVisibility = () => {
		this.setState({
			statementVisibility: !this.state.statementVisibility
		});
	}

	getStatement = (values) => {
		let fromDate = new Date(values.fromDate).getTime();
		let endDate = new Date(values.endDate).getTime();
		this.props.customerStatementAction(this.state.customerId, { fromDate: fromDate / 1000, endDate: endDate / 1000 });
		this.setState({ fromDate: fromDate, endDate: endDate });
	}

	static getDerivedStateFromProps(nextProps, state) {
		if (nextProps.customerStatement !== undefined) {
			return {
				...state,
				customerStatement: nextProps.customerStatement
			}
		}
		if (nextProps.allCustomers !== undefined || nextProps.allOrders !== undefined) {
			return {
				...state,
				allCustomers: nextProps.allCustomers,
				allUsers: nextProps.allUsers,
				allOrders: nextProps.allOrders
			};
		}
		return null;
	}

	componentDidMount() {
		this.props.customerStatementAction(this.state.customerId, { fromDate: this.state.fromDate / 1000, endDate: this.state.endDate / 1000 });
	}

	render() {
		

		let langId = i18n.language === 'en' ? 1 : 2;
		let user = this.state.allCustomers !== undefined && this.state.allCustomers.find(user => {
			return user.customerId === this.props.customerId
		});

		

		let orders = this.state.allOrders !== undefined && this.state.allOrders.filter(data => data.customerId === this.props.customerId);
		const cashTransactions = this.state.customerStatement && this.state.customerStatement['Cash'] !== undefined && this.state.customerStatement['Cash']['transactions'] !== undefined &&
			this.state.customerStatement['Cash']['transactions'].map((transaction, index) =>
				<div className="row" key={`cash${index}`}
				>
					<div className="col-lg-6 col-md-6 col-xs-6">{dateFormat(transaction.time, "dd/mm/yyyy  hh:MM tt")}</div>
					<div className="col-lg-6 col-md-6 col-xs-6 float-data">฿{transaction.amount + " " + transaction.type}</div>
				</div>
			)

		const metalTransactions = this.state.customerStatement && this.state.customerStatement['Metal'] !== undefined &&
			this.state.customerStatement['Metal'].map((transaction, i) => {

				if (transaction !== undefined && transaction.metalId !== undefined && transaction.unitId !== undefined && transaction.metalId !== null && transaction.unitId !== null) {
					let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId == transaction.unitId).text.find(text => text.langId === langId).name
					let metalObj = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId == transaction.metalId)
					if (metalObj === undefined) {
						return null
					}
					let metalName = metalObj !== undefined ? metalObj.text.find(text => text.langId === langId).name : ''
					return <div className="card" key={`metal${transaction.metalId}${i}`}>
						<div className="card-header">
							<a className="card-link" data-toggle="collapse" href={`#metal${transaction.metalId}`}>{metalName} <span className="float-right">{`${transaction.total} ${unit} ${transaction.type}`}</span></a>
						</div>
						<div id={'metal' + transaction.metalId} className="collapse" data-parent="#accordionStatement">
							<div className="card-body">
								{
									transaction.transactions.map((tran, index) =>
										<div className="row" key={`metal${index}`}>
											<div className="col-lg-6 col-md-6 col-xs-6">{dateFormat(tran.time, "dd/mm/yyyy  hh:MM tt")}</div>
											<div className="col-lg-6 col-md-6 col-xs-6 float-data">{`${tran.quantity} ${unit} ${tran.type}`}</div>
										</div>)
								}
							</div>
						</div>
					</div>
				} else {
					return null
				}
			}
			)

		const startDate = new Date(this.state.fromDate);
		const endDate = new Date(this.state.endDate);

		return (

			<React.Fragment>
				<h5><strong>{user.fullName}</strong></h5><br />
				<h6>{this.props.t('Customer')}# : {user.customerId}</h6>
				<h6>{this.props.t('Email')} : {user.email}</h6>
				<h6>{this.props.t('Phone')} : {user.phone}</h6>
				<h6>{this.props.t('Address')} : {user.address}</h6> 
				{isAdmin() && <div><h6>{this.props.t('AvailableCreditLimit')} : {parseFloat(user.creditLimit).toFixed(2)}</h6><h6>{this.props.t('Note')} : {user.note}</h6></div>}
				<h6>{this.props.t('TotalOrders')} : {user.orderCount}</h6>
				
				{isAdmin() && <button className="btn-primary-fill" onClick={() => { this.props.editCustomer("Add Customer", user.customerId) }}>{this.props.t('Edit')}</button>}
				<button className="btn-primary-fill ml-2" onClick={this.toggleVisibility}>{this.state.visibility ? this.props.t('Hide') : this.props.t('View')} {this.props.t('Orders')}</button>
				{!isInvoicing() ? <button className="btn-primary-fill ml-2" onClick={this.toggleStatementVisibility}>{this.state.statementVisibility ? this.props.t('Hide') : this.props.t('View')} {this.props.t('Statement')}</button> : ''}
				{!isInvoicing() && !isAccounting() ? <Link className="btn btn-primary-fill ml-2" to={{
					pathname: '/main/invoice',
					state: { customerId: user.customerId }
				}}> {this.props.t('View')} {this.props.t('Invoices')} </Link> : ''}
				{this.state.visibility === true ?
					<React.Fragment>
						<hr />
						<div id="accordion" className="mt-4">
							{
								orders !== undefined && orders !== false &&
								orders.map(order => {
									let userPlacedBy = this.props.userProfile.userId === order.createdBy ? this.props.userProfile : false;
									if (userPlacedBy === false) {
										userPlacedBy = this.state.allUsers !== undefined && this.state.allUsers.find(user => {
											return user.userId === order.createdBy
										});
									}
									console.log(order, userPlacedBy, this.props.userProfile);

									let images = order.orderImages === undefined ? (
										<div key={`orderImage1`} className="img"><img
											src={NoImage} alt="defaultImg" className="img-thumbnail" /></div>
									) : order.orderImages !== undefined &&
										order.orderImages.map(image => {
											return (
												<div key={`orderImage${image.orderImageId}`} className="img"><img
													src={image.url} alt="img" className="img-thumbnail" /></div>
											)
										});
									let deliveryAddress = '';
									let pickupAddress = '';
									if (order.deliveryType === 'Customer self Pickup') {
										let langId = i18n.language === 'en' ? 1 : 2;
										let branchDataDelivery = this.state.lookupData !== undefined && this.state.lookupData.Branch !== undefined && this.state.lookupData.Branch.find(branch => branch.branchId == order.deliveryAddress);
										let branchTextDelivery = branchDataDelivery !== undefined && branchDataDelivery.text !== undefined && branchDataDelivery.text.length > 0 && branchDataDelivery.text.find(text => text.langId === langId)
										deliveryAddress = branchTextDelivery.name;
									}
									else {
										deliveryAddress = order.deliveryAddress;
									}

									if (order.pickupType === 'Drop Off by Customer') {
										let langId = i18n.language === 'en' ? 1 : 2;
										let branchDataPickup = this.state.lookupData !== undefined && this.state.lookupData.Branch !== undefined && this.state.lookupData.Branch.find(branch => branch.branchId == order.pickupAddress);
										let branchTextPickup = branchDataPickup !== undefined && branchDataPickup.text !== undefined && branchDataPickup.text.length > 0 && branchDataPickup.text.find(text => text.langId === langId)
										pickupAddress = branchTextPickup.name;
									} else {
										pickupAddress = order.pickupAddress
									}

									return (<div key={`orderDetail${order.orderId}`} className="card" >
										<div className="card-header">
											<a className="card-link" data-toggle="collapse"
												href={`#collapse${order.orderId}`}>
												{this.props.t('OrderId')} {order.orderId}#
										</a>
										</div>
										<div id={`collapse${order.orderId}`} className="collapse " data-parent="#accordion">
											<div className="card-body">
												<div className="row">
													<div className="col-md-12">
														<div className="order-content">
															<h6><strong>{this.props.t('ThumbnailOfImage')} </strong></h6>
															<div className="order-image">{

																images
															}</div>
														</div>
														<div className="order-content">
															<h6><strong>{this.props.t('Remarks')}</strong></h6>
															<div>{order.orderRemarks}</div>
														</div>
														<div className="order-content">
															<h6><strong>{this.props.t('PickupType')} ({order.pickupType})</strong></h6>
															<div>{pickupAddress}</div>
														</div>
														<div className="order-content">
															<h6><strong>{this.props.t('DeliveryType')} ({order.deliveryType})</strong></h6>
															<div>{deliveryAddress}</div>
														</div>
														<div className="order-content">
															<h6><strong>{this.props.t('Status')}</strong></h6>
															<div className="text-success">
																<strong>{statusFormatter([...this.state.lookupData.Status], order.orderStatus, langId)}</strong>
															</div>
														</div>
														<div className="order-content">
															<h6><strong>{this.props.t('PlacedBy')}</strong></h6>
															<div>
																{userPlacedBy.fullName} ({getRole(userPlacedBy.roleId)})
																</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>)
								})
							}


						</div></React.Fragment> : ''
				}
				{this.state.statementVisibility === true ?
					<React.Fragment>
						<hr />
						<Formik
							initialValues={{
								fromDate: `${startDate.getFullYear()}-${startDate.getMonth() < 9 ? '0' + (startDate.getMonth() + 1) : startDate.getMonth() + 1}-${startDate.getDate() < 9 ? '0' + startDate.getDate() : startDate.getDate()}`,
								endDate: `${endDate.getFullYear()}-${endDate.getMonth() < 9 ? '0' + (endDate.getMonth() + 1) : endDate.getMonth() + 1}-${endDate.getDate() < 9 ? '0' + endDate.getDate() : endDate.getDate()}`
							}}
							onSubmit={(values) => this.getStatement(values)}
							validationSchema={Yup.object().shape({ ...customerStatementValidation })}
						>
							{formprops => {
								const {
									values,
									touched,
									errors,
									dirty,
									isSubmitting,
									handleChange,
									handleBlur,
									handleSubmit,
								} = formprops;
								return (
									<form onSubmit={handleSubmit}>
										<div className="row  mt-3">
											<div className="col-md-8">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group row">
															<label className="col-lg-3 col-md-4">{this.props.t('From')}  </label>
															<div className="col-md-8">
																<input name="fromDate"
																	value={values.fromDate}
																	className={
																		errors.fromDate && touched.fromDate
																			? "form-control error"
																			: "form-control"
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	type="date" />
																{errors.fromDate && touched.fromDate && (<div className="input-feedback">{errors.fromDate}</div>)}
															</div>
														</div>
													</div>
													<div className="col-md-6">
														<div className="form-group row">
															<label className="col-lg-3 col-md-4">{this.props.t('To')} </label>
															<div className="col-md-8">
																<input name="endDate"
																	value={values.endDate}
																	className={
																		errors.endDate && touched.endDate
																			? "form-control error"
																			: "form-control"
																	}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	type="date" />
																{errors.endDate && touched.endDate && (<div className="input-feedback">{errors.endDate}</div>)}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-4">
												<button className="btn-primary-fill generate-statement-btn">{this.props.t('GenerateStatement')}
												</button>
											</div>
										</div>
									</form>
								)
							}
							}
						</Formik>
						<div id="accordionStatement" className="mt-4 accordionStatement">
							<div className="card">
								<div className="card-header">
									<a className="card-link" data-toggle="collapse" href="#statement1">{this.props.t('Cash')} <span className="float-right">{this.state.customerStatement !== undefined && this.state.customerStatement['Cash'] !== undefined ? '฿' + this.state.customerStatement['Cash'].total + " " + this.state.customerStatement['Cash'].type : 0}</span></a>
								</div>
								<div id="statement1" className="collapse" data-parent="#accordionStatement">
									<div className="card-body">
										{cashTransactions}
									</div>
								</div>
							</div>
							{
								metalTransactions
							}

						</div>
					</React.Fragment> : ''
				}
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		success: state.customerReducer.customerSuccess,
		error: state.customerReducer.customerError,
		customerStatement: state.customerReducer.customerStatement,
	}
}

const mapDispatchToProps = {
	customerStatementAction,
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ViewCustomer));
