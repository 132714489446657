import React from 'react';
import './App.css';
import Routes from './routes'
import { Provider } from 'react-redux';
import { persistor,store } from './redux/store'
import { PersistGate } from 'redux-persist/lib/integration/react';


const LoadingView=()=>{
	return(
		<h2 style={{textAlign:"center"}}>Loading</h2>
	)
}

class App extends React.Component{

	render(){
	    return(
			 <Provider store={store}>
				 <PersistGate loading={<LoadingView />} persistor={persistor}>
					 <Routes/>
				</PersistGate>
			</Provider>
	    	
	    )
	}
}
export default App;
