import React from 'react'

class Reports extends React.Component{
    render(){
        return(
            <h1>Reports</h1>
        )
    }

}

export default Reports;