import * as actionTypes from '../constant';
const INITIAL_STATE = {};

export const userReducer = (state = INITIAL_STATE, action) => {
    let users = [];
    let index = false;
    switch (action.type) {
        case actionTypes.USER_LIST:
            return {
                ...state,
                users: action.value,
                userSuccess: ''
            };
        case actionTypes.USER_CREATE:
            return {
                ...state,
                users: [...state.users, action.value],
                user: action.value,
                userSuccess: 'User created successfully'
            };
        case actionTypes.USER_UPDATE:
            users = [...state.users];
            index = users.findIndex(user => {
                return user.userId === action.value.userId
            });
            users[index] = action.value
            return {
                ...state,
                users: [...state.users, action.value],
                user: action.value,
                userSuccess: 'User updated successfully'
            };
        case actionTypes.LOG_ERROR:
            return {
                ...state,
                userError: action.value,
                userSuccess: ''
            };
        case actionTypes.CLEAR_ERROR:
            return {
                ...state,
                userError: '',
                userSuccess: ''
            };
        default:
            return { ...state, userError: '', userSuccess: '' };
    }
}
