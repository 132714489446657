import * as actionTypes from '../constant';
import { createItem, updateItem, removeItem } from '../../services/ItemServices';

export const createItemAction = (item) => {
    return dispatch => {
        createItem(item).then(res => {

            if (res.data !== undefined) {
                dispatch(createItemPost(res.data.data));
            } else {

            }

        }).catch(err => {
            console.log('error Item create action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));

        });
    }
}

const createItemPost = (res) => {
    return {
        type: actionTypes.ITEM_CREATE,
        value: res
    }
}
export const updateItemAction = (itemId, item) => {
    return dispatch => {
        updateItem(itemId, item).then(res => {

            if (res.data !== undefined) {
                dispatch(updateItemPut(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error Item Update action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}

const updateItemPut = (res) => {
    return {
        type: actionTypes.ITEM_UPDATE,
        value: res
    }
}
export const removeItemAction = (itemId) => {
    return dispatch => {
        removeItem(itemId).then(res => {

            if (res.data !== undefined) {
                dispatch(removeItemDel(res.data.data));
            } else {
                // dispatch(error(res.error_description));
            }

        }).catch(err => {
            console.log('error Item delete action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
const removeItemDel = (res) => {
    return {
        type: actionTypes.ITEM_DELETE,
        value: res
    }
}
const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}