import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

class MaterialEdit extends Component {

	render() {
		if (this.props.rowData === undefined && this.props.rowData === '') {
			return false
		}
		let langId = this.props.i18n.language === 'en' ? 1 : 2;
		let { rowData, allUsers, allCustomers, lookupData } = this.props
		let user = allUsers.find(user => user.userId === rowData.deliveryUserId)
		let customer = allCustomers.find(customer => customer.customerId === rowData.customerId)
		let metal = rowData.amountType == 'Metal' ? lookupData['Metal'].find(metal => metal.itemId == rowData.metalId) : ''
		let unit = rowData.amountType == 'Metal' ? lookupData['Unit'].find(unit => unit.unitId == rowData.unitId) : ''

		return (
			<React.Fragment>
				<form>
					<div className="row">
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('DeliveryMan')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={user !== undefined ? user.fullName : ''} />
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('CustomerName')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={customer !== undefined ? customer.fullName : ''} />
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('Type')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={rowData.paymentType} />
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('Amount')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={parseFloat(rowData.amount).toFixed(2)} />
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('AmountType')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={rowData.amountType} />
								</div>

							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group row">
								<label className="col-md-3">{this.props.t('InvoiceID')}</label>
								<div className="col-md-9">
									<input type="text" className="form-control" readOnly={true} value={rowData.invoiceId} />
								</div>

							</div>
						</div>
						{rowData.amountType == 'Metal' &&
							<React.Fragment>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">Material Name</label>
										<div className="col-md-9">
											<input type="text" className="form-control" readOnly={true} value={metal !== '' ? metal.text.find(mt => mt.langId === langId).name : ''} />
										</div>

									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group row">
										<label className="col-md-3">Quantity</label>
										<div className="col-md-9">
											<input type="text" className="form-control" readOnly={true} value={unit !== '' ? unit.text.find(ut => ut.langId === langId).name : ''} />
										</div>

									</div>
								</div>
							</React.Fragment>
						}

						<div className="col-md-12 mt-4">
							<div className="float-right">
								{/* <button type="button" className="btn-primary-fill mr-2">Save</button>*/}
								<button type="button" className="btn-primary-outline" onClick={() => this.props.updateLayout('Material List')}>Cancel</button>
							</div>
						</div>
					</div>
				</form>
			</React.Fragment>
		)
	}
}

export default withTranslation()(MaterialEdit)