import React, { Component } from 'react'
// import {withTranslation} from 'react-i18next'
import { Row, Col, Card, CardBody } from 'reactstrap'
import OrderList from '../components/OrderList'
import OrderCreate from '../components/OrderCreate'
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import { isInvoicing, isAdmin, isAssortmentAdmin } from "../helper/General";


class Orders extends Component {

    state = {
        orders: [],
        customers: [],
        loggedIn: '',
        error: '',
        orderId: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            hideShow: false
        }
    }

    toggleButton = (orderId = '') => {

        if (!isAdmin() && !isInvoicing() || orderId !== '' || this.state.hideShow) {
            this.setState({
                hideShow: !this.state.hideShow,
                orderId: orderId
            });
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.key !== this.props.location.key) {
            this.setState({
                hideShow: false,
            })
        }
    }

    render() {
        const { t } = this.props
        return (
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div
                                            className="title">{this.state.hideShow ? (this.state.orderId !== '' ? !isAdmin() && !isInvoicing() && !isAssortmentAdmin() ? t('EditOrder') : t('View Order') : t('CreateOrder')) : t('OrderList')}</div>
                                    </div>
                                    <div className="col-md-6">

                                        {((isAdmin() || isInvoicing()) && this.state.hideShow) || (!isAdmin() && !isInvoicing() && !isAssortmentAdmin()) ? <button className="btn-primary-fill float-right mt-3"
                                            onClick={() => this.toggleButton()}> {isAdmin() || isInvoicing() ? t('OrderList') : (this.state.hideShow ? t('OrderList') : t('CreateOrder'))}</button> : ''}
                                    </div>
                                </div>
                            </div>
                            <CardBody>
                                {this.state.hideShow ? <OrderCreate orderId={this.state.orderId} toggleButton={() => this.toggleButton()} {...this.props} /> : <OrderList toggleButton={this.toggleButton} lookupData={this.state.lookupData} {...this.props} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default withOrderInvoiceHoc(Orders);