import React from 'react'
import { store, persistor } from '../redux/store'


export const getLookup = (type) => getStore().generalReducer

export const statusFormatter = (statusArr, id, langId) => {
   let statusData = statusArr !== undefined && statusArr.find(status => status.statusId === id);
   let statusText = statusData !== undefined && statusData.text !== undefined && statusData.text.length > 0 && statusData.text.find(text => text.langId === langId)
   return statusText !== undefined && statusText.name;
}
export const roleToRedirect = (roleId) => {
   switch (roleId) {
      case 1:
         return '/main/users'
      case 2:
         return '/main/orders'
      case 3:
         return false
      case 4:
         return '/main/packages'
      case 5:
         return '/main/orders' //return 'InvoicingAdmin'
      case 6:
         return '/main/orders'//return 'FrontOffice'
      case 7:
         return '/main/orders' //return 'DeliveryMan'
      case 8:
         return '/main/customers' //return 'Accounting'
      case 9:
         return '/main/customers' //return 'AssortmentAdmin'
      default:
         return false
   }
}
export const getRole = (roleId) => {
   switch (roleId) {
      case 1:
         return 'Admin'
      case 2:
         return 'Messenger'
      case 3:
         return 'Customer'
      case 4:
         return 'DeliveryAdmin'
      case 5:
         return 'InvoicingAdmin'
      case 6:
         return 'FrontOffice'
      case 7:
         return 'DeliveryMan'
      case 8:
         return 'Accounting'
      case 9:
         return 'AssortmentAdmin'
      case 10:
         return 'CastingAdmin'
      default:
         return 'Guest'
   }
}

export const getStatusById = (statusId) => {
   switch (statusId) {
      case 1:
         return 'Pending'
      case 2:
         return 'Approved'
      case 3:
         return 'In Progress'
      case 4:
         return 'Invoiced'
      case 5:
         return 'Delivered'
      case 6:
         return 'Cancelled'
      default:
         return 'Pending'
   }
}

function getStore() {
   return store.getState()
}

function userProfile() {
   return getStore().authReducer.userProfile !== undefined ? getStore().authReducer.userProfile : false
}
export function isAdmin() {
   return userProfile().roleId === 1

}

export function isAccounting() {
   return userProfile().roleId === 8
}
export function isInvoicing() {
   return userProfile().roleId === 5
}
export function isDeliveryAdmin() {
   return userProfile().roleId === 4
}
export function isAssortmentAdmin() {
   return userProfile().roleId === 9
}
export function isCastingAdmin() {
   return userProfile().roleId === 10
}

export function isCustomer(roleId = null) {
   if (roleId !== null) {
      return roleId === 3
   } else {
      return userProfile().roleId === 3
   }
}

export function getAuthRedirectUrl() {
   return roleToRedirect(userProfile().roleId)

}

export function getWastagePercentage() {
   return getStore().generalReducer.lookupData !== undefined ? getStore().generalReducer.lookupData.wastagePercentage : false;
}

store.subscribe(getStore)