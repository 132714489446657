import React from 'react'
import { NavLink } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import { isAdmin, isAccounting, isDeliveryAdmin,isInvoicing, isAssortmentAdmin,isCastingAdmin } from '../helper/General'
import { UncontrolledCollapse, Button, CardBody, Card } from 'reactstrap';


class Sidebar extends React.Component {
	subMenu = (subMenuRoutes) => {
		return (
			<UncontrolledCollapse toggler="#toggler" id="reportDropdown">
				<ul className="mt-0">
					{this.menuItem(subMenuRoutes)}
				</ul>
			</UncontrolledCollapse>
		)
	}
	adminMenu = () => {
		return this.menuItem(this.props.roles.Admin)
	}
	menuItem = (routes) => {
		return routes.map((route, index) => {
			if(isInvoicing() && route.url.includes('collect')){
				return null
			}
			return (
				<li key={index}>
					<NavLink to={route.url} className={route.className !== undefined ? route.className : "sidebar-menu-item"} activeClassName='active' id={route.subMenu !== undefined ? 'toggler' : ''}>
						<i className={route.iconClassName}></i>
						<span className={this.props.handleClass ? "menu hide" : "menu"}>
							{this.props.t(route.title)}
						</span>
					</NavLink>
					{route.subMenu !== undefined && this.subMenu(route.subMenu)}
				</li>
			)
		})
	}

	render() {

		return (
			<div className={this.props.handleClass ? "main-layout" : "main-layout open"} id="side-menu">
				<aside className="left-sidebar-layout ">
					<div className="sidebar-content">
						<ul>
							{isAdmin() ? this.adminMenu() : isAccounting() ? this.menuItem(this.props.roles.Accounting) : isDeliveryAdmin() ? this.menuItem(this.props.roles.DeliveryAdmin): isAssortmentAdmin() ? this.menuItem(this.props.roles.AssortmentAdmin): isCastingAdmin() ? this.menuItem(this.props.roles.CastingAdmin):this.menuItem(this.props.roles.Common)}
						</ul>
					</div>
				</aside>
			</div>

		)
	}
}

export default withTranslation()(Sidebar)