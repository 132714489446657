import * as actionTypes from '../constant';

import { getLedger , transferMoney } from '../../services/LedgerServices';

export const ledgerListAction = (userId) => {
    return dispatch => {
        getLedger(userId).then(res => {
            if (res.data !== undefined) {
                dispatch(getLedgerList(res.data.data));
                dispatch(clearError());
            }
        }).catch(err => {
            console.log('error user list action ', err);
            let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
            dispatch(error(errormsg));
        });
    }
}
export const transferMoneyAction=(data)=>{
  return dispatch =>{
      transferMoney(data).then(res => {  if (res.data !== undefined) {
            dispatch(tranferMoneyPost(res.data));
        }
    }).catch(err => {
        console.log('error transfer money action ', err);
        let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
        dispatch(error( errormsg));
    });
    //var errMsg = Object.assign({}, err).response.data.error_description;
  }
}
const getLedgerList = (res) => {
    return {
        type: actionTypes.LEDGER_LIST,
        value: res
    }
}
//
// export const createUserAction = (user) => {
//     return dispatch => {
//         createUser(user).then(res => {
//             if (res.data !== undefined) {
//                 dispatch(createUserPost(res.data.data));
//             } else {
//                 // dispatch(error(res.error_description));
//             }
//
//         }).catch(err => {
//             console.log('error user create action ', err);
//             //var errMsg = Object.assign({}, err).response.data.error_description;
//             let errormsg = err !== undefined && err.data !== undefined && err.data.message !== undefined ? err.data.message : '';
//             dispatch(error(errormsg));
//         });
//     }
// }
//
const tranferMoneyPost = (res) => {
   return {
      type: actionTypes.TRANSFER_MONEY,
        value: res
   }
 }

const error = (err) => {
    return {
        type: actionTypes.LOG_ERROR,
        value: err
    }
}
const clearError = (err) => {
    return {
        type: actionTypes.CLEAR_ERROR,
    }
}
