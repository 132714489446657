import React, { Component } from 'react'
import { Row, Col, Card } from 'reactstrap'
import { connect } from 'react-redux';
import Logo from '../assets/images/logo.png'
import { loginUser } from '../redux/actions/AuthActions';
import {Formik} from 'formik';
import {getAuthRedirectUrl} from '../helper/General'
import * as Yup from 'yup';

class Login extends Component {
	state = {
		email: '',
		password: '',
		formErrors: { email: '', password: '' },
		emailValid: false,
		passwordValid: false,
		formValid: false,
		loggedIn: '',
		error: ''
	}
	onSubmit = (user) => {
		this.props.login(user)
	}
	
	UNSAFE_componentWillReceiveProps(nextProps){
	
		if(nextProps.loggedIn===true){
		
			this.props.history.push(nextProps.redirectTo);
		}
		else if(nextProps.loggedIn===false && this.state.error!==nextProps.error){
			this.setState({
				loggedIn:nextProps.loggedIn,
				error:nextProps.error
			})
		}
		
		
	}
	componentDidMount(){
		document.title='Login'
		if(this.props.loggedIn===true){
			this.props.history.push(getAuthRedirectUrl()); 
		}
	}

	render() {
			
		return (

			<section>
				<div className="container">
					<Row>
						<Col lg="5" md="7" className="mx-auto mt-5">
							<Card className="card-box p-4">
								<div className="card-header text-center"><img src={Logo} className="img-fluid" width="30%" alt="logo"/></div>
								<div className="card-body">
									<Formik
										initialValues = {{
											email: '',
											password: ''
										}}
										onSubmit={values => {
                                         this.onSubmit(values)
                                        }}
										validationSchema={Yup.object().shape({
                                            email: Yup.string()
                                                .email()
                                                .required("Required"),
											password: Yup.string().required('No password provided.')
                                        })}
									>
										{
											props => {
												const {
													errors,
													handleChange,
                                                    handleSubmit,
                                                    handleBlur,
                                                    touched
												} = props;
												return (
													<form  onSubmit={handleSubmit} >
														<div className={`form-group`}>
															<label>Username</label>
															<input type="text" name="email"   onBlur={handleBlur} className={ errors.email && touched.email ? "form-control error" : "form-control"
                                                            }  placeholder="" onChange={handleChange} />
                                                            {errors.email && touched.email && (
																<div className="input-feedback">{errors.email}</div>
                                                            )}
														</div>
														<div className={`form-group`}>
															<label>Password</label>
															<input type="password" name="password" onBlur={handleBlur} className={ errors.email && touched.email ? "form-control error" : "form-control"
                                                            } placeholder="" onChange={handleChange} />
                                                            {errors.password && touched.password && (
																<div className="input-feedback">{errors.password}</div>
                                                            )}
														</div>
                                                        {this.state.loggedIn===false?<p className="alert alert-danger">{this.state.error}</p>:''}
														<button type="submit" className="btn-primary-fill d-block text-center mt-5 w-100">Login</button>

													</form>
												)
											}
										}
									</Formik>


								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</section>

		)
	}
}
const mapStateToProps= state =>{
	
	return {
		loggedIn: state.authReducer.loggedIn,
		redirectTo: state.authReducer.redirectTo,
		error:state.authReducer.loginError
	}
}

const mapDispatchToProps={
	login:loginUser
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);