import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Row, Col, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import i18n from '../i18n';
import thumbnail from '../assets/images/thumbnail.png'
import { connect } from 'react-redux';
import { deleteOrderAction, updateOrderStatusAction } from '../redux/actions/OrderActions';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { isInvoicing, isAdmin, isAssortmentAdmin } from '../helper/General';
import { AlertMessage } from '../helper/AlertBox'
import { finishedProductAction } from '../redux/actions/finishedProductActions'

//this component is used inside finished product page to show list of finished products
class FinishedProductList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			finishedProductList: props.finishedProductList,
			lookupData: props.lookupData,
			allOrders: props.allOrders,
			lng: 'en',
			success: props.success,
			error: props.error,
			showEditModal: false,
			orderId: '',
			orderStatus: '',
			allCustomers:props.allCustomers
		}
	}
	static getDerivedStateFromProps(nextProps, state) {
        let nextRes = { ...state };
        if (nextProps.finishedProductList !== undefined) {
            nextRes.finishedProductList = nextProps.finishedProductList;
           
        }
        if (nextProps.lookupData !== undefined) {
            nextRes.lookupData = nextProps.lookupData;
        }
        if (nextProps.allOrders !== undefined) {
            nextRes.allOrders = nextProps.allOrders;
        }
        if (nextProps.success !== undefined && nextProps.success !== ""&&nextProps.success !== state.success) {

            nextRes.success = nextProps.success;
           
        }
        return { ...nextRes };
    }

	updateOrder = (orderId) => {
		this.props.toggleButton(orderId);
	}


	updateOrderStatus = (orderId, orderStatus) => {
		this.setState({showEditModal:false})
		this.props.updateOrderStatus(orderId, { orderStatus: orderStatus });
	}

	deleteOrder = (orderId) => {
		console.log('delete order', orderId);
		this.props.deleteOrder(orderId);

	}

	imageThumbFormatter = (cell, row) => {
		return (<span><img alt="order" src={cell ? cell : thumbnail} width={50} /></span>);
	}

	customerNameFormatter = (cell, row) => {
		const customerData = this.state.allCustomers !== undefined && this.state.allCustomers.length && this.state.allCustomers.find(customer => customer.customerId === row.customerId);
		return (customerData !== undefined && customerData.fullName);
	}

	materialNameFormatter = (cell, row) => {
		var materialdetail='';
		let langId = i18n.language === 'en' ? 1 : 2
		row.productLineItems.map(product=>{
			 const materialData = this.state.lookupData['Metal'] !== undefined &&  this.state.lookupData['Metal'].length && this.state.lookupData['Metal'].find(item => item.itemId === product.itemId);
			 const unitData = this.state.lookupData['Unit'] !== undefined &&  this.state.lookupData['Unit'].length && this.state.lookupData['Unit'].find(item => item.unitId === product.unitId);

		materialdetail+= (materialData !== undefined && materialData.text.find(text => text.langId === langId).name)+"("+product.quantity+(unitData !== undefined && unitData.text.find(text => text.langId === langId).name)+"),"

		})
		return(materialdetail.trimRight(','))
	   
	}


	buttonFormatter = (cell, row) => {
		return (<React.Fragment>
			<button type="button" className="btn-primary-outline" title="Edit"  onClick={() => this.handleShow(row.orderId, row.orderStatus)}><i className="icon icon-settings"></i></button>
			{ /*<button type="button" className="btn-primary-outline" title="Update Status"><i className="icon icon-note"></i></button>*/}
			<button type="button" className="btn-primary-outline ml-2" title="edit" onClick={() => this.props.toggleButton(row)}><i className="icon icon-pencil"></i></button>
		</React.Fragment>
		);
	}

	actionFormatter = (cell, row) => {
		let actions = '';
		if (isAdmin()) {
			actions = <span>
				{row.orderStatus === 1 && <button className="btn btn-link" onClick={() => this.updateOrder(row.orderId)}><i className="fa fa-pencil"></i></button>}
				{this.props.userProfile !== undefined && this.props.userProfile.roleId == 1 && <button className="btn btn-link" onClick={() => this.handleShow(row.orderId, row.orderStatus)}><i className="fa fa-cog"></i> </button>}
			</span>
		} else if (row.orderStatus === 1) {
			actions = <span><button className="btn btn-link" onClick={() => this.updateOrder(row.orderId)}><i className="fa fa-pencil"></i> </button>
				<button className="btn btn-link" onClick={() => { if (window.confirm(`Are you sure you wish to delete Order#${row.orderId}?`)) this.deleteOrder(row.orderId) }}><i className="fa fa-trash text-danger"></i> </button>
			</span>
		} else {
			actions = <span>{/*<button className="btn btn-link" onClick={()=>this.handleShow(row.orderId,row.orderStatus)}><i className="fa fa-cog"></i> </button>*/}</span>

		}
		return actions;
	}

	handleClose = () => this.setState({ orderId: '', orderStatus: '', showEditModal: !this.state.showEditModal });

	handleShow = (orderId, orderStatus) => {
		this.setState({ orderId: orderId, orderStatus: orderStatus, showEditModal: true });
	}

	componentDidMount() {
        this.props.finishedProductAction();
    }

	render() {
		let langId = i18n.language === 'en' ? 1 : 2
		document.title = 'Finished Products';
		 let statusOptions = this.state.lookupData !== undefined && this.state.lookupData.Status.map(
			(status) => { return <option key={`statusOpt${status.statusId}`} value={status.statusId}>{status.text.find(text => text.langId === langId).name}</option> }
		)

		const { t } = this.props;
		const { SearchBar, ClearSearchButton } = Search;
		const defaultSorted = [{
			dataField: 'orderId',
			order: 'desc'
		}];
		let columns = [
			{
				dataField: "productNames",
				text:t('Products'),
				headerAlign: 'left',
				align: 'left',
				sort: true
			},
			{
				dataField: "orderId",
				text:t('Order Id'),
				headerAlign: 'left',
				align: 'left'
			},
			{
				dataField: "customerName",
				text: t("CustomerName"),
				formatter: this.customerNameFormatter,
				headerAlign: 'left',
				align: 'left'
			},
			{
				dataField: "Material",
				text:t("Material"),
				formatter: this.materialNameFormatter,
				headerAlign: 'center',
				align: 'center'
			},
			{
				dataField: "Action",
				text: t('Action'),
				headerAlign: 'left',
				align: 'left',
				formatter: this.buttonFormatter
			},
		];
		if (!isInvoicing() && !isAssortmentAdmin()) {
			columns = [
				...columns,
				{
					dataField: "createdDate",
					text: t("Action"),
					formatter: this.actionFormatter,
					headerAlign: 'center',
					align: 'center'
				}
			]
		}

		return (

			<React.Fragment>
				<Row>
					{this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<Col lg="12" md="12">
						{this.state.lookupData !== undefined && this.state.allOrders !== undefined&& this.state.finishedProductList !== undefined ?
							<ToolkitProvider
								keyField="productId"
								key={'finishedProductListTable'}
								data={this.state.finishedProductList}
								columns={columns}
								search={{
									searchFormatted: true
								}}
							>
								{
									props => (
										<div>
											<div className="mb-2">
												<SearchBar {...props.searchProps} placeholder={this.props.t('Search')} className="offset-md-9 col-md-3" />
											</div>
											<div className="table-responsive">
											{/* <ClearSearchButton { ...props.searchProps } /> */}
												<BootstrapTable
													{...props.baseProps}
													pagination={paginationFactory()}
													defaultSorted={defaultSorted}
												/>
											</div>
										</div>
									)
								}
							</ToolkitProvider>
							: ''}
					</Col>
				</Row>
				<Row>
				<Modal isOpen={this.state.showEditModal}>
					<ModalHeader>
						{t('Update Order Status')}
					</ModalHeader>
					<ModalBody>
						<Row>
							<Col md="4" className="align-items-center">{t('Order Id')}:</Col>
							<Col md="8">{this.state.orderId}</Col>
						</Row>
						<Row className={`form-group`}>
							<label className="col-md-4">{this.props.t('Status')}:</label>
							<Col md="8" className="align-items-center">
								<Input
									type="select"
									name="orderStatus"
									value={this.state.orderStatus}
									onChange={(e) => this.setState({ 'orderStatus': e.target.value })}
									className="form-control">
									<option value="">-- {this.props.t('Status')} --</option>
									{statusOptions}
								</Input>
							</Col>
						</Row>
					</ModalBody>

					<ModalFooter>
						<Button onClick={this.handleClose}>{t('Close')}</Button>
						<Button color="primary" className="btn btn-primary" onClick={() => this.updateOrderStatus(this.state.orderId, this.state.orderStatus)}>{t('Update')}</Button>
					</ModalFooter>
				</Modal>


				</Row>
			</React.Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		finishedProductList: state.finishedProductReducer.finishedProductList,
		allOrders:state.orderReducer.orders,
		lookupData: state.generalReducer.lookupData,
		success: state.finishedProductReducer.finishProductSuccess,
		error: state.orderReducer.orderError,
		allCustomers: state.customerReducer.customers,
	}
}

const mapDispatchToProps = {
	deleteOrder: deleteOrderAction,
	updateOrderStatus: updateOrderStatusAction,
	finishedProductAction:finishedProductAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FinishedProductList));
