import React, { Component, useState } from 'react'
import { Row, Col, Card, CardBody } from 'reactstrap'
import { TabContent, TabPane, Nav, NavItem, NavLink, Button, CardTitle, CardText } from 'reactstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { packageListAction, transferPackageAction, cancelPackageAction, acceptPackageAction } from '../redux/actions/PackageActions'
import { userListAction } from '../redux/actions/UserActions'
import { invoiceListAction } from '../redux/actions/InvoiceActions';
import { connect } from 'react-redux';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { withTranslation } from 'react-i18next'
import i18n from '../i18n';
import * as Yup from 'yup';
import { transferPackageValidation, deliverOrderValidation } from '../Validation/PackageValidationSchema'
import { Formik } from 'formik';
import { isInvoicing } from "../helper/General";
import { getPacakgeDeatail } from '../services/PackageServices'
import { AlertMessage } from '../helper/AlertBox';
import { isAdmin } from "../helper/General";
import { convertUnit } from "../helper/common";

class Payments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            modal: false,
            packageDetailModal: false,
            acceptedPackages: props.acceptedPackages,
            incomingPackages: props.incomingPackages,
            outgoingPackages: props.outgoingPackages,
            success: "",
            invoiceId: "",
            invoiceTotal: 0,
            packageDetail: null,
            packageMode: null,
            deilverOrder: [],
            customerId: '',
            filters: {},
            userId:'',
            money:'',
            showResults: true,
            metalType:'',
        }
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab, success: "" })
        }
    }

    packageDetail = (packageInfo) => {
        getPacakgeDeatail(packageInfo.invoiceId, packageInfo.packageType, packageInfo.transactionLineId)
            .then(res => {
                this.setState({
                    packageDetailModal: true,
                    packageDetail: res.data.data,
                    packageMode: packageInfo.packageType
                })
            })
            .catch(err => console.log(err))
    }

    packageDetailModalOff = () => {
        this.setState({
            packageDetailModal: false
        })
    }

    toggleModal = (row, cell) => {
        this.setState({
            modal: !this.state.modal,
            trackingId: row.trackingId,
            packageName: row.packageName
        })
    }

    calculateInvoiceTotal = (e) => {

        let invoiceTotal = 0
        for (let i = 0; i < e.target.options.length; i++) {
            let option = e.target.options[i];
            if (option.selected) {
                let singleInv = this.props.allInvoices.find(invoice => invoice.invoiceId == option.value)
                invoiceTotal = singleInv !== undefined ? invoiceTotal + parseInt(singleInv.invoiceTotal) : invoiceTotal
            }
        }

        this.setState({ invoiceTotal })
    }

    componentDidMount() {
        this.props.userListAction();
        this.props.invoiceListAction();
        if (isAdmin()) {
            this.props.packageListAction('accepted');
        }
        else {
            this.props.packageListAction('incoming');
            this.props.packageListAction('outgoing');
            this.props.packageListAction('accepted');
        }
    }

    handleMetalChange = (e) => {
        let value = e.target.value;
        this.setState({metalType: value }); 
    }

    handleDeliveryManChange = (e) => {

       let value = e.target.value;
        this.setState({userId: value });  

    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }

    handleMoneyChange = (e) => {
        this.setState({money: e.target.value,
        showResults: true,metalType:''});
    };

    onClick = e => {
        this.setState({
          showResults: this.state.showResults
        });
    };

    transferPackage = (values) => {
        let pack = {
            'trackingId': values.trackingId,
            'assignedTo': values.assignedTo,
        }
        this.props.transferPackageAction(pack);
    }

    cancelPackageRequest = (trackingId) => {
        this.props.cancelPackageAction({ trackingId: trackingId });
    }

    acceptPackageRequest = (trackingId) => {
        this.props.acceptPackageAction({ trackingId: trackingId });
    }

    static getDerivedStateFromProps(nextProps, state) {
        let nextRes = { ...state };
        if (nextProps.success !== undefined && nextProps.success !== "" && nextProps.success !== state.success) {
            nextRes.success = nextProps.success;
            nextRes.modal = false;
            nextRes.deliverModal = false;
        }

        if (nextProps.acceptedPackages !== undefined)
            nextRes.acceptedPackages = nextProps.acceptedPackages;
        if (nextProps.incomingPackages !== undefined)
            nextRes.incomingPackages = nextProps.incomingPackages;
        if (nextProps.outgoingPackages !== undefined)
            nextRes.outgoingPackages = nextProps.outgoingPackages;
        if (nextProps.order !== undefined)
            nextRes.order = nextProps.order;
        return { ...nextRes };
    }

    render() {
        let totals = [];
        const { t } = this.props;
        const { SearchBar, ClearSearchButton } = Search;
       
        let userOptions = this.props.allUsers !== undefined && this.props.allUsers.map(
            (user) => {

                return  <option key={`userOpt${user.userId}`} value={user.userId}>{user.fullName}</option>
                    
            }
        );

        let metalOptions = this.props.allUsers !== undefined && this.props.allUsers.map(
            (metal) => {

                return  <option key={`metalOpt${metal.metalId}`} value={metal.metalId}>{metal.Name}</option>
                    
            }
        );
        

        if (isAdmin()) {
            this.admincolumns = [
                {
                    dataField: "fromUser",
                    formatter: (cell, row) => {
                        let fromuser = this.props.allUsers !== undefined && this.props.allUsers.find(user => cell === user.userId)
                        return cell !== '' && fromuser !== undefined ? fromuser.fullName : '--';
                    },
                    text: this.props.t('From User'),
                },
                {
                    dataField: "customerId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('Customer Id'),
                },
                {
                    dataField: "customerName",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('CustomerName'),
                },
                {
                    dataField: "packageType",
                    text: this.props.t('Package Type'),
                },
                {
                    dataField: "invoiceId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('InvoiceID'),
                },
                {
                    dataField: "packageName",
                    text: this.props.t('PackageName'),
                },
                {
                    dataField: "packageDetails",
                    text: this.props.t('Detail'),
                    formatter: (cell, row) => {
                        let amount = '';
                        if (cell.mode === 'Metal') {
                            let metaldata=this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === cell.metalId);
                            let metal = metaldata !== undefined &&metaldata.text!==undefined&& metaldata.text.find(lang => lang.langId == 1).name
                            let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === cell.unitId).text.find(lang => lang.langId == 1).name
                            amount = `${cell.amount} ${unit} ${metal}`
                        }
                        else {
                            amount = `฿${parseFloat(cell.amount).toFixed(2)}`;
                        }

                        return amount

                    },
                    footerAttrs: (column, colIndex) => ({ 'colSpan': 8 }),
                    footer: columnData => columnData.map((item) => {
                        if(item===undefined)
                            return  "";
                        let unit= this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === 2).text.find(lang => lang.langId == 1).name;
                        if (item.mode === 'Metal')
                        {
                            let metaldata=this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === item.metalId);
                            let metal = metaldata !== undefined &&metaldata.text!==undefined&& metaldata.text.find(lang => lang.langId == 1).name
                            if(totals[metal]===undefined)
                                totals[metal]=0;
                            totals[metal]= (parseFloat(totals[metal])+(parseFloat(item.amount)*convertUnit(item.unitId, 2))).toFixed(2);
                        }
                        else {
                            if(totals['Money']===undefined)
                                totals['Money']=0;
                            totals['Money'] = (parseFloat(totals['Money'])+parseFloat(item.amount)).toFixed(2);
                        }
                        let totalarray=Object.entries(totals);
                        let foot=totalarray.map((row)=>{
                            return `${row['0']} : ${(row['0']==='Money'?('฿'+row['1']):(row['1']+' '+unit))}`;
                        })
                        return <div key='ft' dangerouslySetInnerHTML={{__html: `Totals <br>${foot.join('<br>')}`}}/> ;
                    }).slice(-1)
                },
                {
                    dataField: "assignedTo",
                    text: this.props.t('Delivery man'),
                    formatter: (cell, row) => {
                        let assigneduser = this.props.allUsers !== undefined && this.props.allUsers.find(user => cell === user.userId)
                        return (assigneduser !== undefined && assigneduser !== false && assigneduser.fullName)
                    },
                }
            ];
        }
        else {
            this.acceptedcolumns = [
                {
                    dataField: "fromUser",
                    formatter: (cell, row) => {
                        let fromuser = this.props.allUsers !== undefined && this.props.allUsers.find(user => cell === user.userId)
                        return cell !== '' && fromuser !== undefined ? fromuser.fullName : '--';
                    },
                    text: this.props.t('From User')
                },
                {
                    dataField: "customerId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('Customer Id')
                },
                {
                    dataField: "customerName",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('CustomerName')
                },
                {
                    dataField: "packageType",
                    text: this.props.t('Package Type')
                },
                {
                    dataField: "packageName",
                    text: this.props.t('PackageName')
                },
                {
                    dataField: "invoiceId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('InvoiceID')
                },
                {
                    dataField: "packageDetails",
                    text: this.props.t('Detail'),
                    formatter: (cell, row) => {
                        let amount = '';
                        if (cell.mode === 'Metal') {
                            let metaldata=this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === cell.metalId);
                            let metal = metaldata !== undefined &&metaldata.text!==undefined&& metaldata.text.find(lang => lang.langId == 1).name
                            let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === cell.unitId).text.find(lang => lang.langId == 1).name
                            amount = `${cell.amount} ${unit} ${metal}`
                        }
                        else {
                            amount = `฿${parseFloat(cell.amount).toFixed(2)}`;
                        }
                        return amount

                    },
                    footerAttrs: (column, colIndex) => ({ 'colSpan': 8 }),
                    footer: columnData => columnData.map((item) => {
                        if(item===undefined)
                            return  "";
                        let unit= this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === 2).text.find(lang => lang.langId == 1).name;
                        if (item.mode === 'Metal')
                        {
                            let metaldata=this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === item.metalId);
                            let metal = metaldata !== undefined &&metaldata.text!==undefined&& metaldata.text.find(lang => lang.langId == 1).name
                            if(totals[metal]===undefined)
                                totals[metal]=0;
                            totals[metal]= (parseFloat(totals[metal])+(parseFloat(item.amount)*convertUnit(item.unitId, 2))).toFixed(2);
                        }
                        else {
                            if(totals['Money']===undefined)
                                totals['Money']=0;
                            totals['Money'] = (parseFloat(totals['Money'])+parseFloat(item.amount)).toFixed(2);
                        }
                        let totalarray=Object.entries(totals);
                        let foot=totalarray.map((row)=>{
                            return `${row['0']} : ${(row['0']==='Money'?('฿'+row['1']):(row['1']+' '+unit))}`;
                        })
                        return <div dangerouslySetInnerHTML={{__html: `Totals <br>${foot.join('<br>')}`}}/> ;
                    }).slice(-1)
                },
                {
                    dataField: "createdAt",
                    text: this.props.t('TransferPackage'),
                    formatter: (cell, row) => {
                        return <React.Fragment>
                            <button className="btn btn-primary-fill mr-2"
                                onClick={() => this.toggleModal(row, cell)}>{this.props.t('Transfer')}</button>
                            {row.packageType == "Package" && <button className="btn btn-success"
                                onClick={() => this.toggleDeliverModal(row.invoiceId)}>{this.props.t('Deliver')}</button>}
                        </React.Fragment>;
                    },
                    headerAlign: 'center',
                    align: 'center'
                }
            ];

            this.incomingcolumns = [
                {
                    dataField: "fromUser",
                    formatter: (cell, row) => {
                        let fromuser = this.props.allUsers !== undefined && this.props.allUsers.find(user => cell === user.userId)
                        return cell !== '' && fromuser !== undefined ? fromuser.fullName : '--';
                    },
                    text: this.props.t('From User')
                },
                {
                    dataField: "customerId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('Customer Id')
                },
                {
                    dataField: "customerName",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('CustomerName')
                },
                {
                    dataField: "packageType",
                    text: this.props.t('Package Type')
                },
                {
                    dataField: "packageName",
                    text: this.props.t('PackageName')
                },
                {
                    dataField: "invoiceId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('InvoiceID')
                },
                {
                    dataField: "packageDetails",
                    text: this.props.t('Detail'),
                    formatter: (cell, row) => {
                        let amount = '';
                        if (cell.mode === 'Metal') {
                            let metal = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === cell.metalId).text.find(lang => lang.langId == 1).name
                            let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === cell.unitId).text.find(lang => lang.langId == 1).name
                            amount = `${cell.amount} ${unit} ${metal}`
                        }
                        else {
                            amount = `฿${parseFloat(cell.amount).toFixed(2)}`;
                        }
                        return amount

                    },
                    footerAttrs: (column, colIndex) => ({ 'colSpan': 8 }),
                    footer: columnData => columnData.map((item) => {
                        if(item===undefined)
                            return  "";
                        let unit= this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === 2).text.find(lang => lang.langId == 1).name;
                        if (item.mode === 'Metal')
                        {
                            let metal = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === item.metalId).text.find(lang => lang.langId == 1).name
                            if(totals[metal]===undefined)
                                totals[metal]=0;
                            totals[metal]= (parseFloat(totals[metal])+(parseFloat(item.amount)*convertUnit(item.unitId, 2))).toFixed(2);
                        }
                        else {
                            if(totals['Money']===undefined)
                                totals['Money']=0;
                            totals['Money'] = (parseFloat(totals['Money'])+parseFloat(item.amount)).toFixed(2);
                        }
                        let totalarray=Object.entries(totals);
                        let foot=totalarray.map((row)=>{
                            return `${row['0']} : ${(row['0']==='Money'?('฿'+row['1']):(row['1']+' '+unit))}`;
                        })
                        return <div dangerouslySetInnerHTML={{__html: `Totals <br>${foot.join('<br>')}`}}/> ;
                    }).slice(-1)
                },
                {
                    dataField: "createdAt",
                    text: this.props.t('Action'),
                    formatter: (cell, row) => {
                        return <React.Fragment>
                            <button className="btn btn-success btn-sm mr-2" onClick={() => {
                                if (window.confirm(`Are you sure you wish to accept ${row.packageName} package request?`))
                                    this.acceptPackageRequest(row.trackingId)
                            }
                            }>{this.props.t('Accept')}</button>
                            {/*<button className="btn btn-secondary btn-sm">Reject</button>*/}
                        </React.Fragment>;
                    },
                    headerAlign: 'center',
                    align: 'center'
                }
            ];

            this.outgoingcolumns = [
                {
                    dataField: "customerId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('Customer Id')
                },
                {
                    dataField: "customerName",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('CustomerName')
                },
                {
                    dataField: "packageType",
                    text: this.props.t('Package Type')
                },
                
                {
                    dataField: "packageName",
                    text: this.props.t('PackageName')
                },
                {
                    dataField: "invoiceId",
                    formatter: (cell) => {
                        return cell != 0 ? cell : '--';
                    },
                    text: this.props.t('InvoiceID')
                },
                {
                    dataField: "packageDetails",
                    text: this.props.t('Detail'),
                    formatter: (cell, row) => {
                        let amount = '';
                        if (cell.mode === 'Metal') {
                            let metal = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === cell.metalId).text.find(lang => lang.langId == 1).name
                            let unit = this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === cell.unitId).text.find(lang => lang.langId == 1).name
                            amount = `${cell.amount} ${unit} ${metal}`
                        }
                        else {
                            amount = `฿${parseFloat(cell.amount).toFixed(2)}`;
                        }
                        return amount
                    },
                    footerAttrs: (column, colIndex) => ({ 'colSpan': 7 }),
                    footer: columnData => columnData.map((item) => {
                        if(item===undefined)
                            return  "";
                        let unit= this.props.lookupData !== undefined && this.props.lookupData['Unit'].find(unit => unit.unitId === 2).text.find(lang => lang.langId == 1).name;
                        if (item.mode === 'Metal')
                        {
                            let metal = this.props.lookupData !== undefined && this.props.lookupData['Metal'].find(metal => metal.itemId === item.metalId).text.find(lang => lang.langId == 1).name
                            if(totals[metal]===undefined)
                                totals[metal]=0;
                            totals[metal]= (parseFloat(totals[metal])+(parseFloat(item.amount)*convertUnit(item.unitId, 2))).toFixed(2);
                        }
                        else {
                            if(totals['Money']===undefined)
                                totals['Money']=0;
                            totals['Money'] = (parseFloat(totals['Money'])+parseFloat(item.amount)).toFixed(2);
                        }
                        let totalarray=Object.entries(totals);
                        let foot=totalarray.map((row)=>{
                            return `${row['0']} : ${(row['0']==='Money'?('฿'+row['1']):(row['1']+' '+unit))}`;
                        })
                        return <div dangerouslySetInnerHTML={{__html: `Totals <br>${foot.join('<br>')}`}}/> ;
                    }).slice(-1)
                },
                {
                    dataField: "createdAt",
                    formatter: (cell, row) => {
                        return <React.Fragment>
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                if (window.confirm(`Are you sure you wish to cancel ${row.packageName} package request?`))
                                    this.cancelPackageRequest(row.trackingId)
                            }
                            }>{this.props.t('RejectRequest')}</button>
                        </React.Fragment>;
                    },
                    text: this.props.t('Action'),
                    headerAlign: 'center',
                    align: 'center'
                }
            ];
        }

        return (
            <React.Fragment>
                <Card className="card-box">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title">{t('Money Tracking')}</div>

                            </div>
                        </div>
                    </div>
                    <CardBody>
                        { isAdmin() ?
                            <div className="mt-4">
                                { this.state.acceptedPackages !== undefined &&
                                <ToolkitProvider
                                    keyField="trackingId"
                                    key={`adminPackageTable${new Date().getTime()}`}
                                    data = { [...this.state.acceptedPackages].filter((pack) => pack.packageType === 'Payment')
                                            .filter(pack => this.state.userId === ''|| (this.state.userId !== '' && pack.assignedTo == this.state.userId))
                                            .filter(pack => this.state.money === ''|| (this.state.money !== '' && pack.packageDetails.mode == this.state.money))
                                            .filter(pack => this.state.metalType === ''|| (this.state.metalType !== '' && pack.packageDetails.metalId == this.state.metalType))
                                    }
                                    columns={this.admincolumns}
                                    search>
                                    {
                                        props => (
                                            <div>
                                                <div className="row inventory-table">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('Delivery man')}</label>
                                                            <select id="delivery" className="form-control" value={this.state.userId} onChange={this.handleDeliveryManChange} name="DeliveryFilter">
                                                                <option value="">{this.props.t('Select Delivery Man')}</option>
                                                                {userOptions}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label>{this.props.t('Filter By Money /Material')}</label>
                                                            <select className="form-control" id="money" value={this.state.money} onChange={this.handleMoneyChange} name="MoneyFilter">
                                                               <option value="">{this.props.t('Select Money /Material')}</option>
                                                               <option value="Cash">{this.props.t('Cash')}</option>
                                                               <option onClick={this.onClick} value="Metal">{this.props.t('Metal')}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4" style={{ display: this.state.money == "Metal" ? "block" : "none" }}>
                                                        <div className="form-group">
                                                            <label>{this.props.t('Filter By Metal')}</label>
                                                            <select id="metal"
                                                                name="metalId" value={this.state.metalType}
                                                                className="form-control"
                                                                onChange={this.handleMetalChange}>
                                                                <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                                {this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
                                                                    return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                        {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                                    </option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <BootstrapTable {...props.baseProps}/>
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                }
                            </div>
                            : <div>
                                {this.props.orderError !== undefined && this.props.orderError !== '' && <AlertMessage error={this.props.orderError} />}
                                {this.props.orderSuccess !== undefined && this.props.orderSuccess !== '' && <AlertMessage success={this.props.orderSuccess} />}
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab == '1' ? 'active' : ''}
                                            onClick={() => { this.toggleTab('1'); }}
                                        >
                                            {this.props.t('TrackingList')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab == '2' ? 'active' : ''}
                                            onClick={() => { this.toggleTab('2'); }}
                                        >
                                            {this.props.t('NewIncoming')}
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={this.state.activeTab == '3' ? 'active' : ''}
                                            onClick={() => { this.toggleTab('3'); }}
                                        >
                                            {this.props.t('PendingOutgoing')}
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <Row>
                                    {this.state.success !== '' && this.state.success !== undefined ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
                                </Row>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1">
                                        <div className="table-responsive  mt-4">
                                            {this.state.acceptedPackages !== undefined &&
                                            <ToolkitProvider
                                                data = { [...this.state.acceptedPackages].filter((pack) => pack.packageType === 'Payment')
                                                        .filter(pack => this.state.userId === ''|| (this.state.userId !== '' && pack.fromUser == this.state.userId))
                                                        .filter(pack => this.state.money === ''|| (this.state.money !== '' && pack.packageDetails.mode == this.state.money))
                                                        .filter(pack => this.state.metalType === ''|| (this.state.metalType !== '' && pack.packageDetails.metalId == this.state.metalType))
                                                }
                                                key={`acceptedPackageTable${new Date().getTime()}`}
                                                keyField="trackingId"
                                                columns={this.acceptedcolumns}
                                                search>
                                                {
                                                    props => (
                                                        <div>
                                                            <div className="row inventory-table">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Delivery man')}</label>
                                                                        <select id="delivery" className="form-control" value={this.state.userId} onChange={this.handleDeliveryManChange} name="DeliveryFilter">
                                                                            <option value="">{this.props.t('Select Delivery Man')}</option>
                                                                            {userOptions}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Money /Material')}</label>
                                                                        <select className="form-control" id="money" value={this.state.money} onChange={this.handleMoneyChange} name="MoneyFilter">
                                                                           <option value="">{this.props.t('Select Money /Material')}</option>
                                                                           <option value="Cash">{this.props.t('Cash')}</option>
                                                                           <option onClick={this.onClick} value="Metal">{this.props.t('Metal')}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4" style={{ display: this.state.money == "Metal" ? "block" : "none" }}>
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Metal')}</label>
                                                                        <select id="metal"
                                                                            name="metalId" value={this.state.metalType}
                                                                            className="form-control"
                                                                            onChange={this.handleMetalChange}>
                                                                            <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                                            {this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
                                                                                return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                                    {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                                                </option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <BootstrapTable {...props.baseProps}/>
                                                        </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div className="table-responsive mt-4">
                                            {this.state.incomingPackages !== undefined &&
                                            <ToolkitProvider
                                                key={`incomingPackageTable${new Date().getTime()}`}
                                                keyField="trackingId"
                                                columns={this.incomingcolumns}
                                                data = { [...this.state.incomingPackages].filter((pack) => pack.packageType === 'Payment')
                                                        .filter(pack => this.state.userId === ''|| (this.state.userId !== '' && pack.fromUser == this.state.userId))
                                                        .filter(pack => this.state.money === ''|| (this.state.money !== '' && pack.packageDetails.mode == this.state.money))
                                                        .filter(pack => this.state.metalType === ''|| (this.state.metalType !== '' && pack.packageDetails.metalId == this.state.metalType))
                                                }
                                                search>
                                                {
                                                    props => (
                                                        <div>
                                                            <div className="row inventory-table">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Delivery man')}</label>
                                                                        <select id="delivery" className="form-control" value={this.state.userId} onChange={this.handleDeliveryManChange} name="DeliveryFilter">
                                                                            <option value="">{this.props.t('Select Delivery Man')}</option>
                                                                            {userOptions}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Money /Material')}</label>
                                                                        <select className="form-control" id="money" value={this.state.money} onChange={this.handleMoneyChange} name="MoneyFilter">
                                                                           <option value="">{this.props.t('Select Money /Material')}</option>
                                                                           <option value="Cash">{this.props.t('Cash')}</option>
                                                                           <option onClick={this.onClick} value="Metal">{this.props.t('Metal')}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4" style={{ display: this.state.money == "Metal" ? "block" : "none" }}>
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Metal')}</label>
                                                                        <select id="metal"
                                                                            name="metalId" value={this.state.metalType}
                                                                            className="form-control"
                                                                            onChange={this.handleMetalChange}>
                                                                            <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                                            {this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
                                                                                return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                                    {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                                                </option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <BootstrapTable {...props.baseProps} />
                                                            </div>
                                                    )
                                                }
                                            </ToolkitProvider>}
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <div className="table-responsive  mt-4">
                                            {this.state.outgoingPackages !== undefined &&
                                            <ToolkitProvider
                                                key={`outgoingPackageTable${new Date().getTime()}`}
                                                keyField="trackingId"
                                                columns={this.outgoingcolumns}
                                                data = { [...this.state.outgoingPackages].filter((pack) => pack.packageType === 'Payment')
                                                        .filter(pack => this.state.userId === ''|| (this.state.userId !== '' && pack.fromUser == this.state.userId))
                                                        .filter(pack => this.state.money === ''|| (this.state.money !== '' && pack.packageDetails.mode == this.state.money))
                                                        .filter(pack => this.state.metalType === ''|| (this.state.metalType !== '' && pack.packageDetails.metalId == this.state.metalType))
                                                }
                                                search>
                                                {
                                                    props => (
                                                        <div>
                                                            <div className="row inventory-table">
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Delivery man')}</label>
                                                                        <select id="delivery" className="form-control" value={this.state.userId} onChange={this.handleDeliveryManChange} name="DeliveryFilter">
                                                                            <option value="">{this.props.t('Select Delivery Man')}</option>
                                                                            {userOptions}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Money /Material')}</label>
                                                                        <select className="form-control" id="money" value={this.state.money} onChange={this.handleMoneyChange} name="MoneyFilter">
                                                                           <option value="">{this.props.t('Select Money /Material')}</option>
                                                                           <option value="Cash">{this.props.t('Cash')}</option>
                                                                           <option onClick={this.onClick} value="Metal">{this.props.t('Metal')}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4" style={{ display: this.state.money == "Metal" ? "block" : "none" }}>
                                                                    <div className="form-group">
                                                                        <label>{this.props.t('Filter By Metal')}</label>
                                                                        <select id="metal"
                                                                            name="metalId" value={this.state.metalType}
                                                                            className="form-control"
                                                                            onChange={this.handleMetalChange}>
                                                                            <option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
                                                                            {this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
                                                                                return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
                                                                                    {data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
                                                                                </option>
                                                                            })}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div><BootstrapTable {...props.baseProps} />
                                                            </div>
                                                    )
                                                }
                                            </ToolkitProvider>
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        }
                    </CardBody>
                </Card>
                <TransferModal allUsers={this.props.allUsers} transferPackage={(values) => this.transferPackage(values)} trackingId={this.state.trackingId} packageName={this.state.packageName} modal={this.state.modal} toggle={this.toggleModal} {...this.props} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        incomingPackages: state.packageReducer.incomingPackages,
        outgoingPackages: state.packageReducer.outgoingPackages,
        acceptedPackages: state.packageReducer.acceptedPackages,
        allUsers: state.userReducer.users,
        userProfile: state.authReducer.userProfile,
        success: state.packageReducer.packageSuccess,
        allInvoices: state.invoiceReducer.invoices,
        orderError: state.packageReducer.orderError,
        orderSuccess: state.packageReducer.orderSuccess,
        order: state.packageReducer.order,
        lookupData: state.generalReducer.lookupData,
    }
}
const mapDispatchToProps = {
    packageListAction,
    transferPackageAction,
    userListAction,
    cancelPackageAction,
    acceptPackageAction,
    invoiceListAction
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Payments))

export const TransferModal = (props) => {
    const { modal, toggle, allUsers } = props;
    const usersOptions = allUsers !== undefined && allUsers.filter(user => user.roleId !== 3).map(user => <option key={`user-${user.userId}`} value={user.userId}>{user.fullName}</option>);
    return (
        <div>
            <Modal isOpen={modal} toggle={() => toggle('', '')} size="md">
                <ModalHeader toggle={() => toggle('', '')}>{props.t('TransferPackage')}</ModalHeader>
                <Formik
                    initialValues={{ assignedTo: '', trackingId: props.trackingId }}
                    onSubmit={(values) => props.transferPackage(values)}
                    validationSchema={Yup.object().shape({ ...transferPackageValidation })}
                >
                    {formprops => {
                        const {
                            values,
                            touched,
                            errors,
                            dirty,
                            isSubmitting,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        } = formprops;
                        return (
                            <form onSubmit={handleSubmit}>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group row">
                                                <h5 className="col-md-8">{props.t('Package')}#: {props.packageName}</h5>
                                                <input type="hidden" name="trackingId" />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-md-4">{props.t('selectDeliveryMan')}</label>
                                                <div className="col-md-8">
                                                    <select name="assignedTo"
                                                        className={
                                                            errors.assignedTo && touched.assignedTo
                                                                ? "form-control error"
                                                                : "form-control"
                                                        }
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}>
                                                        <option key="userEmpty" value="">{props.t('SelectUsers')}</option>
                                                        {usersOptions}
                                                    </select>
                                                    {errors.assignedTo && touched.assignedTo && (<div className="input-feedback">{errors.assignedTo}</div>)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ModalBody>
                                <ModalFooter>
                                    <Button type="submit" className="btn-primary-fill">{props.t('Ok')}</Button>{' '}
                                    <Button color="secondary" onClick={() => toggle('', '')}>{props.t('Cancel')}</Button>
                                </ModalFooter>
                            </form>
                        )
                    }}
                </Formik>
            </Modal>
        </div>
    );
}


