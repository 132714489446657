import * as Yup from 'yup';

export const userValidation = {
    email: Yup.string().trim()
        .email()
        .required("Please enter email."),
    fullName: Yup.string().trim()
        .required("Please enter name.")
        .min(6, "Name must be minimum of 6 characters"),
    phone: Yup.string().trim()
        .required("Please enter mobile no.")
        .matches(/^\d{1,10}$/, 'Please enter valid number.'),
    roleId: Yup.string()
        .required("Please select user type.")


}