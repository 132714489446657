import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import paginationFactory from 'react-bootstrap-table2-paginator';
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import BootstrapTable from "react-bootstrap-table-next"
import { connect } from 'react-redux';
import { isInvoicing, isAdmin, getRole } from "../helper/General";
import { assignPackageAction } from '../redux/actions/PackageActions';
import { AlertMessage } from '../helper/AlertBox'
import dateFormat from 'dateformat';

class InvoiceList extends Component {

	constructor(props) {
		super(props)
		this.state = {
			allInvoices: this.props.allInvoices,
			allCustomers: this.props.allCustomers,
			lookupData: this.props.lookupData,
			allOrders: this.props.orders,
			success: props.success ? props.success : props.packageSuccess,
			error: props.error ? props.error : props.packageError,
			logPopup: false,
			logText: [],
			customerId: props.location.state !== null && props.location.state.customerId !== undefined ? props.location.state.customerId : '',
			paymentStatus: props.paymentStatus !== undefined ? props.paymentStatus : ''
		}
	}
	static getDerivedStateFromProps(nextProps, state) {

		let allNewInvoices = state.allInvoices !== undefined ? [...state.allInvoices] : [];
		if (nextProps.packageSuccess !== undefined && nextProps.packageSuccess !== '' && nextProps.packageAssignedInvoice !== undefined) {
			let index = allNewInvoices.findIndex(invoice => {
				return invoice.invoiceId === nextProps.packageAssignedInvoice.invoiceId
			});
			if (index !== undefined) {
				allNewInvoices[index] = nextProps.packageAssignedInvoice;
				return {
					allInvoices: allNewInvoices,
					success: nextProps.packageSuccess,
				}
			}
		}

		if (nextProps.allInvoices !== undefined) {
			allNewInvoices = nextProps.allInvoices;
		}

		return {
			...state,
			allInvoices: allNewInvoices,
			allCustomers: nextProps.allCustomers,
			lookupData: nextProps.lookupData,
			allOrders: nextProps.orders,
			error: nextProps.error ? nextProps.error : nextProps.packageError,
			success: nextProps.success,
		};
	}

	customerNameFormatter = (cell, row) => {
		const customerData = this.state.allCustomers !== undefined && this.state.allCustomers.length && this.state.allCustomers.find(customer => customer.customerId === cell);
		return (<span>{customerData !== undefined && customerData.fullName}</span>);
	}

	updateInvoice = (invoiceId) => {
		this.props.toggle(invoiceId);
	}
	showInvoiceLog = invoiceId => {
		let invoiceInfo = this.props.allInvoices.find(invoice => invoice.invoiceId == invoiceId)
		let logs = []
		invoiceInfo.invoiceLog !== undefined && invoiceInfo.invoiceLog.forEach(log => {
			if (this.props.authUser.userId == log.userId) {
				log.userId = "You"
			} else {
				let user = this.props.allUsers.find(user => user.userId === log.userId)
				log.userId = user !== undefined ? user.fullName : 'anonymous'
			}
			logs.push(log)
		})

		this.setState({
			logText: logs,
			logPopup: true
		})
	}
	logPopupOff = () => {
		this.setState({
			logPopup: false
		})
	}
	buttonFormatter = (cell, row) => {
		
		return (
			<React.Fragment>
				<Button type="button" className="btn-primary-outline" title="Edit Invoice" onClick={() => this.updateInvoice(row.invoiceId)}>
					{isAdmin() && row.paymentStatus==="Unpaid"? <i className="icon icon-pencil"></i> : <i className="icon icon-eye"></i>}
				</Button>
				<Button className="btn-primary-outline" title="Log" onClick={() => this.showInvoiceLog(row.invoiceId)}><i className="icon icon-graph"></i></Button>
			</React.Fragment>
		);
	}
	dateFormatter = (cell) => {
		return dateFormat(cell, "dd/mm/yyyy  hh:MM tt")
	}

	handleCustomerChange = (e) => {
		
		let value = e.target.value;
		this.setState({ customerId: value });

	}
	handleStatusChange = (e) => {
		
		let value = e.target.value;
		this.setState({ paymentStatus: value });

	}
	assignPackage = (invoiceId, userId) => {
		this.props.assignPackage({ invoiceId: invoiceId, assignedTo: userId });
	}

	render() {
		let customerOptions = this.props.allCustomers !== undefined && this.props.allCustomers.map(
			(customer) => { return <option key={`customerOpt${customer.customerId}`} value={customer.customerId}>{`${customer.fullName} (${this.props.t('CustomerId')}-${customer.customerId})`}</option> }
		);

		let userOptions = this.props.allUsers !== undefined && this.props.allUsers.map(
			(user) => {
				return getRole(user.roleId) === 'DeliveryAdmin'
					? <option key={`userOpt${user.userId}`} value={user.userId}>{user.fullName}</option>
					: ''
			}
		);
		document.title = 'Invoices';
		const { t } = this.props

		const defaultSorted = [{ dataField: 'invoiceId', order: 'desc' }];

		const columns = [
			{
				dataField: "invoiceId",
				text: t('InvoiceID'),
				headerAlign: 'left',
				align: 'left',
				sort: true
			},
			{
				dataField: "packageName",
				text: t('PackageName'),
				headerAlign: 'left',
				align: 'left',
				formatter: (cell, row) => {
					if (row !== undefined)
						return `PAC${row.invoiceId}`;
				}
			},
			{
				dataField: "updatedAt",
				text: t('Date'),
				headerAlign: 'left',
				align: 'left',
				formatter: this.dateFormatter
			},
			{
				dataField: "customerId",
				text: t('CustomerName'),
				formatter: this.customerNameFormatter,
				headerAlign: 'left',
				align: 'left'
			},
			isInvoicing() ? {
				dataField: "assignedTo",
				text: t('Assignfordelivery'),
				headerAlign: 'left',
				align: 'left',
				formatter: (cell, row) => {
					return cell !== '' ? "Assigned" : <div className="form-group"> <select onChange={(event) => { if (window.confirm(`Are you sure you wish to assign Invoice#${row.invoiceId} to ${event.target.options[event.target.selectedIndex].text}?`)) this.assignPackage(row.invoiceId, event.target.value) }} className="form-control" disabled={cell !== ''} value={cell}>
						<option value="">{this.props.t('SelectUser')}</option>
						{userOptions}
					</select></div>;
				},
			} : false,
			{
				dataField: "deliveryNotes",
				text: t("DeliveryMessage"),
				align: 'left'
			},
			{
				dataField: "paymentStatus",
				text: t("Status"),
				align: 'left'
			},
			{
				dataField: "action",
				text: t('Action'),
				headerAlign: 'center',
				formatter: this.buttonFormatter,
				align: 'center'
			}
		].filter(val => val !== false);

		return (
			<React.Fragment>
				<Row>

					{this.state.error !== '' ? <Col lg="12" md="12"><AlertMessage error={this.state.error} /></Col> : ''}
				</Row>
				<Row>
					{this.state.success !== undefined && this.state.success !== '' ? <Col lg="12" md="12"><AlertMessage success={this.state.success} /></Col> : ''}
				</Row>
				<Row>
					<div className="col-md-4">
						<div className="form-group row">
							<label className="col-md-4">{this.props.t('Customer')}</label>
							<div className="col-md-8">
								<select className="form-control" value={this.state.customerId} onChange={this.handleCustomerChange} name="customerFilter">
									<option value="">{this.props.t('SelectCustomer')}</option>
									{customerOptions}
								</select>
							</div>
						</div>
					</div>
					<div className="col-md-4">
						<div className="form-group row">
							<label className="col-md-4">{this.props.t('Status')}</label>
							<div className="col-md-8">
								<select className="form-control" onChange={this.handleStatusChange} value={this.state.type} name="amountType">
									<option value="">{this.props.t('All')}</option>
									<option value="Paid">{this.props.t('Paid')}</option>
									<option value="Unpaid">{this.props.t('Unpaid')}</option>
								</select>
							</div>
						</div>
					</div>
				</Row>
				<div className="table-responsive">
					{this.state.allInvoices !== undefined && this.state.allInvoices.length > 0 ?
						<BootstrapTable
							key="invoiceListTable"
							keyField="invoiceId"
							data={[...this.state.allInvoices].filter(inv => this.state.customerId === ''
								|| (this.state.customerId !== '' && inv.customerId == this.state.customerId)).filter(inv => this.state.paymentStatus === ''
									|| (this.state.paymentStatus !== '' && inv.paymentStatus == this.state.paymentStatus))}
							defaultSorted={defaultSorted}
							columns={columns}
							noDataIndication={() => "No invoices found."}
							pagination={paginationFactory()} /> : 'No invoices are found.'}

				</div>
				<Modal isOpen={this.state.logPopup} toggle={this.logPopupOff} size="md">
					<ModalHeader toggle={this.logPopupOff} className="text text-center">{this.props.t('InvoiceActivity')}</ModalHeader>
					<ModalBody>
						<ul>
							{[...this.state.logText].map(log => {
								return (
									<li key={log.createdAt}>{log.actionText} <b>{this.props.t('By')} {log.userId} <em>{this.props.t('At')} {dateFormat(log.createdAt, "dd/mm/yyyy  hh:MM tt")}</em></b></li>
								)
							})}

						</ul>
					</ModalBody>
					<ModalFooter><Button onClick={this.logPopupOff} className="float-right">{this.props.t('Ok')}</Button></ModalFooter>
				</Modal>
			</React.Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		allOrders: state.orderReducer.orders,
		allInvoices: state.invoiceReducer.invoices,
		allUsers: state.userReducer.users,
		lookupData: state.generalReducer.lookupData,
		success: state.invoiceReducer.invoiceSuccess,
		error: state.invoiceReducer.invoiceError,
		packageError: state.packageReducer.packageError,
		packageSuccess: state.packageReducer.packageSuccess,
		packageAssignedInvoice: state.packageReducer.assignedInvoice,
		authUser: state.authReducer.userProfile
	}
}

const mapDispatchToProps = {
	assignPackage: assignPackageAction,
}
export default withOrderInvoiceHoc(connect(mapStateToProps, mapDispatchToProps)(InvoiceList))