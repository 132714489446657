import Api from '../Api'

export const transferPackage = (packages) => {
    return Api.post('package/transfer', packages);

};

export const cancelPackage = (packages) => {
    return Api.post('package/cancel', packages);

};

export const assignPackage = (packages) => {
    return Api.post('package/assign',packages);

};

export const acceptPackage = (packages) => {
    return Api.post('package/accept', packages);

};

export const getAssignedPackages = (type = 'incoming') => {
    return Api.get('package/assigned/' + type);

};

export const getPacakgeDeatail=(invoiceId,packageType,txnLineitemId)=>{
	 return Api.get('package/' + invoiceId+'/'+packageType+'/'+txnLineitemId);
}