import React from 'react'
import Orders from '../pages/Orders'
import Invoice from '../pages/Invoice'
import Customers from '../pages/Customers'
import Packages from '../pages/Packages'
import Payments from '../pages/Payments'
import Branches from '../pages/Branches'
import ItemMaster from '../pages/ItemMaster'
import Reports from '../pages/Reports'
import Users from '../pages/Users'
import Inventory from '../pages/Inventory'
import Wastage from '../pages/Wastage'
import Sales from '../pages/Sales'
import ManageMaterial from '../pages/ManageMaterial';
import CollectPayment from '../pages/CollectPayment';
import CollectMaterial from '../pages/CollectMaterial';
import Ledger from '../pages/Ledger';
import FinishedProducts from '../pages/FinishedProducts';
import CreditExceedingCustomers from '../pages/CreditExceedingCustomers';

const Roles = {
    'Common': [
        { 'url': '/main/customers', 'component': Customers, 'title': 'Customers', 'iconClassName': 'icon-user icons' },
        { 'url': '/main/orders', 'component': Orders, 'title': 'Orders', 'iconClassName': 'icon-basket icons' },
        { 'url': '/main/invoice', 'component': Invoice, 'title': 'Invoices', 'iconClassName': 'icon-book-open icon' },
        { 'url': '/main/packages', 'component': Packages, 'title': 'Packages', 'iconClassName': 'icon-present icon' },
        { 'url': '/main/payments', 'component': Payments, 'title': 'Money Tracking', 'iconClassName': 'icon-wallet icon' },
        { 'url': '/main/payment/collect', 'component': CollectPayment, 'title': 'Collect Payment', 'iconClassName': 'icon-briefcase icon' },
        { 'url': '/main/material/collect', 'component': CollectMaterial, 'title': 'Collect Material', 'iconClassName': 'icon-diamond icon' },

    ],
    'Admin': [
        { 'url': '/main/customers', 'component': Customers, 'title': 'Customers', 'iconClassName': 'icon-user icons' },
        { 'url': '/main/users', 'component': Users, 'title': 'Users', 'iconClassName': 'icon-people icons' },
        { 'url': '/main/orders', 'component': Orders, 'title': 'Orders', 'iconClassName': 'icon-basket icons' },
        { 'url': '/main/invoice', 'component': Invoice, 'title': 'Invoices', 'iconClassName': 'icon-book-open icon' },
        { 'url': '/main/branch', 'component': Branches, 'title': 'Branches', 'iconClassName': 'icon-organization icons' },
        { 'url': '/main/item', 'component': ItemMaster, 'title': 'Item Master', 'iconClassName': 'icon-bag icons' },
        { 'url': '/main/packages', 'component': Packages, 'title': 'Packages', 'iconClassName': 'icon-present icon' },
        { 'url': '/main/payments', 'component': Payments, 'title': 'Money Tracking', 'iconClassName': 'icon-wallet icon' },
        {
            'url': '/main/report', 'component': Reports, 'title': 'Reports', 'iconClassName': 'icon-graph icons', 'className': 'sidebar-menu-item sidebar-sub-menu', 'subMenu': [
                { 'url': '/main/reports/inventory', 'component': Inventory, 'title': 'Inventory', 'iconClassName': '' },
                { 'url': '/main/reports/wastage', 'component': Wastage, 'title': 'Wastage', 'iconClassName': '' },
                { 'url': '/main/reports/sales', 'component': Sales, 'title': 'Sales', 'iconClassName': '' },
            ]
        },
        { 'url': '/main/Ledger', 'component': Ledger, 'title': 'Ledger', 'iconClassName': 'icon-list icon' },
        { 'url': '/main/CreditCustomers', 'component': CreditExceedingCustomers, 'title': 'Credit Customers', 'iconClassName': 'icon-user icon' },


    ],
    'Accounting': [
        { 'url': '/main/customers', 'component': Customers, 'title': 'Manage Customer', 'iconClassName': 'icon-people icons' },
        { 'url': '/main/managematerial', 'component': ManageMaterial, 'title': 'Receive Money/Material', 'iconClassName': 'icon-support icons' },
        { 'url': '/main/payments', 'component': Payments, 'title': 'Money Tracking', 'iconClassName': 'icon-wallet icon' },
        { 'url': '/main/Ledger', 'component': Ledger, 'title': 'Ledger', 'iconClassName': 'icon-list icon' },
    ],
    'DeliveryMan': [],
    'FrontOffice': [],
    'DeliveryAdmin': [
        { 'url': '/main/packages', 'component': Packages, 'title': 'Packages', 'iconClassName': 'icon-present icon' },
        { 'url': '/main/payments', 'component': Payments, 'title': 'Money Tracking', 'iconClassName': 'icon-wallet icon' },
    ],
    'AssortmentAdmin': [
        { 'url': '/main/customers', 'component': Customers, 'title': 'Customers', 'iconClassName': 'icon-user icons' },
        { 'url': '/main/orders', 'component': Orders, 'title': 'Orders', 'iconClassName': 'icon-basket icons' },
        { 'url': '/main/finished-products', 'component': FinishedProducts, 'title': 'Finished Product', 'iconClassName': 'icon-trophy icons' },
        { 'url': '/main/packages', 'component': Packages, 'title': 'Packages', 'iconClassName': 'icon-present icon' },
        //{ 'url': '/main/Ledger', 'component ': Ledger, 'title': 'Ledger', 'iconClassName': 'icon-list icon' },
    ],
    'CastingAdmin': [
        { 'url': '/main/packages', 'component': Packages, 'title': 'Packages', 'iconClassName': 'icon-present icon' },
    ],
    'InvoicingAdmin': [],
    'Customer': [],
    'Messenger': []
}
export default Roles
