import * as Yup from 'yup';

export const transferPackageValidation = {
    trackingId: Yup.string()
        .required('Please select package.'),
    assignedTo: Yup.string()
        .required('Please select delivery Man.'),
}

export const deliverOrderValidation = {
    amount: Yup.number()
        .required('Please enter amount.')
        .positive('Amount should be greater than 0')
        .round(),
   //invoiceId: Yup.array()
     //  .min(1,'Please select at least one Invoice'),
    // metal: Yup.string()
    //     .required('Please enter metal.'),
    // unitId: Yup.number()
    //     .required('Please enter unit id.'),
}