import * as actionTypes from '../constant';
import { login } from '../../services/authFunctions';
import {isCustomer} from '../../helper/General'

export const loginUser = (values) => {
	
	return dispatch => {
		login(values).then(res => {
			
			if (res.data.data !== undefined) {
				
				if(isCustomer(res.data.data.roleId)===true){
					dispatch(error("Sorry! You are not authorize to access the portal."));
				}else{
					if(res.data.data.token!==undefined && res.data.data.token!==null){
						localStorage.setItem('token', res.data.data.token);
						dispatch(success(res.data));
					}else{
						dispatch(error(res.data.message));
					}
				}
				
			} else {
				dispatch(error(res.error_description));
			}

		}).catch(err => {
			
			//var errMsg = Object.assign({}, err).response.data.error_description;
            let errormsg=err!==undefined && err.data!==undefined &&err.data.message!==undefined?err.data.message:'';
            dispatch(error(errormsg));
		});
	}
}

const success = (res) => {
	return {
		type: actionTypes.LOGIN,
		value: res.data
	}
}


const error = (err) => {
	return {
		type: actionTypes.LOG_ERROR,
		value: err
	}
}




