import * as Yup from 'yup';

export const branchValidation = {
    branchNameEng: Yup.string().trim()
        .required("Please enter branch name in English"),
    branchNameThi: Yup.string().trim()
        .required("Please enter branch name in Thai"),
    phone: Yup.string().trim()
        .matches(/^\d{1,10}$/, 'Please enter valid number.'),
    branchAddress: Yup.string().trim()
        .required("Please enter branch address")
}