import React, { Component } from 'react'
// import {withTranslation} from 'react-i18next'
import { Row, Col, Card, CardBody,Modal, ModalHeader, ModalBody, ModalFooter,Input ,Button} from 'reactstrap'
import FinishedProductList from '../components/FinishedProductList'
import FinishedProductAdd from '../components/FinishedProductAdd'
import withOrderInvoiceHoc from '../Hoc/OrderInvoiceHoc'
import { isInvoicing, isAdmin, isAssortmentAdmin } from "../helper/General";
import {Formik} from 'formik';
// this page is showed inside AssortmentAdmin in side menu finshed product list
class FinishedProducts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hideShowAddProduct: false,
            showAddProductModal:false,
            finishedProductsName:[''],
            productName:[''],
            quantity:'',
            itemId:'',
            unitId:'',
            productLineItems:[],
            productId:''
        }
    }



  handleHideShowAddProduct= (productData) => {
      if(productData!== undefined){
          this.setState({hideShowAddProduct: !this.state.hideShowAddProduct,orderId:productData.orderId,productName:[...productData.productNames],material:[''],quantity:productData.productLineItems[0].quantity,itemId:productData.productLineItems[0].itemId,unitId:productData.productLineItems[0].unitId,lineItemId:productData.productLineItems[0].lineItemId,productLineItems:productData.productLineItems, productId:productData.productLineItems[0].productId});
        
      }else{
          this.setState({hideShowAddProduct: !this.state.hideShowAddProduct,productName:[''],material:[''],quantity:'',orderId:'',  itemId:'',unitId:'',lineItemId:'',productLineItems:[''], productId:''});
      }

    }
    render() {
        const { t } = this.props
        return (
            <div className="container-fluid">
                <Row>
                    <Col md="12">
                        <Card className="card-box">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="title">{ t('Finished Product')}</div>
                                    </div>
                                    <div className="col-md-6">
                                          <button className="btn-primary-fill float-right mt-3"  onClick={() => this.handleHideShowAddProduct()}> {this.state.hideShowAddProduct ?t('Finished Product'):t('Add Finished Products')}</button>
                                    </div>
                                </div>
                            </div>
                            <CardBody>
                                {this.state.hideShowAddProduct ? <FinishedProductAdd orderId={this.state.orderId} productName={this.state.productName} toggleButton={this.handleHideShowAddProduct} quantity={this.state.quantity}itemId={this.state.itemId}unitId={this.state.unitId}lineItemId={this.state.lineItemId}{...this.props} productLineItems={this.state.productLineItems} productId={this.state.productId} /> : <FinishedProductList toggleButton={this.handleHideShowAddProduct}  {...this.props} />}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default withOrderInvoiceHoc(FinishedProducts);
