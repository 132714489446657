import React, { Component,} from 'react'
import { Row, Col, Label, Input, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { addFinishedProductValidation } from '../Validation/AddFinishedProductValidationSchema';
import i18n from '../i18n';
import { FieldArray, Field, getIn } from 'formik';
import { addFinishedProductAction,UpdatefinishedProductAction } from '../redux/actions/finishedProductActions';

class FinishedProductAdd extends Component {

	onSubmit=(values)=>{
		if (this.props.productId==='') {

			this.props.finishedProductAdd(values)
			this.props.toggleButton()

		}
		else{
			this.props.finishedProductUpdate(this.props.productId,values)
			this.props.toggleButton()
		}		
	}

	deleteLineItem=(arrayHelpers,index,lineItemId,deletedLineItems)=>{
		deletedLineItems.push(lineItemId)
		arrayHelpers.remove(index)
	}
	render() {
		return (
			<React.Fragment>
				<Formik
					initialValues={{
						'productNames':[...this.props.productName],
						'productLineItems':this.props.productLineItems,
						'orderId':this.props.orderId,
						deletedLineItems:[],
					}}
					onSubmit={values => this.onSubmit(values)}
					validationSchema={Yup.object().shape({ ...addFinishedProductValidation })}
				>
					{props => {
						const {
							values,
							touched,
							errors,
							dirty,
							isSubmitting,
							handleChange,
							handleBlur,
							handleSubmit,
							handleReset,
							setFieldValue
						} = props;
						return (
							<form onSubmit={handleSubmit}>
								{this.props.orderId !== '' &&
									<Row>
									<Col lg="6" md="6">
										<Row className={`form-group`}>
											<label className="col-md-4">{this.props.t('Finished Product')}#: </label>
											<Col md="8" className="align-items-center"><label>{values.orderId}</label></Col>
										</Row>
									</Col>
									</Row>
								}
								<Row>
									<Col lg="6" md="6">
										<Row className={`form-group`}>
											<label className="col-md-4">{this.props.t('Order Id')}:</label>
											<Col md="8" className="align-items-center">
												<Input
													type="select"
													name="orderId"
													id="orderId"
													value={values.orderId}	
													className={
														errors.orderId && touched.orderId
															? "form-control error"
															: "form-control"
													}
													onChange={handleChange}
													onBlur={handleBlur}>
													<option value={this.props.allOrders}>-- {this.props.t('Order Id')} --</option>
													{
													   this.props.allOrders !== undefined && this.props.allOrders.map(orders=> <option key={orders.orderId} value={orders.orderId}>{orders.orderId} </option>)
													}
												</Input>
												{errors.orderId && touched.orderId && (<div className="input-feedback">{errors.orderId}</div>)}
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col lg="12" md="12">
										<Row className="form-group">
										  <label className="col-md-2">{this.props.t('Products Name')}:</label>
											<Col md={8}>
												<FieldArray
												  name="productNames"
												  render={arrayHelpers => (
													<div >
														 {
															values.productNames.map((product, index) => (
																<Row key={index}>
																<Col lg="6" md="6">
																<Input type="text"
																name={`productNames.${index}`}
																value={product}
																className={
																	errors.productName && touched.productName
																		? "mar-bottom-5 form-control error"
																		: "mar-bottom-5 form-control"
																}
																key={'product'+index}
																onChange={props.handleChange(`productNames.${index}`)}
																onBlur={handleBlur} />
																</Col>
																<Col lg="4" md="4">
																<Row className="form-group">
																{index > 0?<a className="remove-input" title="Remove product" onClick={() => arrayHelpers.remove(index)}> <i className="icon-close icons"></i></a>:
																		<a className="add-more" title="Add more products" onClick={() => arrayHelpers.push('')} ><i className="icon-plus icons"></i></a>

																}
																</Row>
																</Col>


																</Row>
															))
														}
													</div>
												  )}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col lg="12" md="12">
										<Row className="form-group">
											<Col md={12}>
												<FieldArray
												  name="productLineItems"
													render={arrayHelpers => (
														<div >
														{
															values.productLineItems.map((product, index) => (
															<Row key={index}>
																<Col lg="6" md="6">
																	<Row>
																	{index===0 ?
																		<label className="col-md-4">{this.props.t('Metal')}</label>
																		:<div className="col-md-4"></div>

																	}
																	<div className="col-md-8">
																	
																		<select
																			name={`productLineItems[${index}].itemId`}
																			className="form-control"
																			defaultValue={product.itemId}
																			onChange={handleChange}
																			onBlur={handleBlur}
																		>
																			<option key="emptyItem" value="">-- {this.props.t('SelectMetal')} --</option>
																			{this.props.lookupData !== undefined && this.props.lookupData['Metal'] !== undefined && this.props.lookupData['Metal'].length > 0 && this.props.lookupData['Metal'].map(data => {
																				return <option key={`opt-metal-${data.itemId}`} value={data.itemId}>
																					{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}
																				</option>
																			})}
																		</select>
																		{errors.itemId && touched.itemId && (<div className="input-feedback">{errors.itemId}</div>)}
																	</div>
															</Row>

															  </Col>
															 <Col lg="6" md="6">
																<Row className="form-group">                
																	<Col lg="4">
																	  <Input type="text"
																		  name={`productLineItems[${index}].quantity`}
																		  value={product.quantity}
																		  className={
																			  errors.quantity && touched.quantity
																				  ? "form-control error"
																				  : "form-control"
																		  }
																		  onChange={handleChange}
																		  onBlur={handleBlur} />
																	  {errors.quantity && touched.quantity && (<div className="input-feedback">{errors.quantity}</div>)}
																	</Col>
																	<Col  lg="4">
																		<select className="form-control"
																			name={`productLineItems[${index}].unitId`}
																			defaultValue={product.unitId}
																			onChange={handleChange}
																			onBlur={handleBlur}
																		>
																			<option key="emptyunit" value="">-- {this.props.t('Unit')} --</option>
																			{
																			   this.props.lookupData !== undefined && this.props.lookupData['Unit'] !== undefined &&
																			   this.props.lookupData['Unit'].length > 0 &&
																			   this.props.lookupData['Unit'].filter(unit => unit.unitId === 2 || unit.unitId == 3).map(data => {
																					return <option key={`opt-unit-${data.unitId}`}
																						value={data.unitId}>{data.text !== undefined && data.text.find(text => text.langId === (i18n.language === 'en' ? 1 : 2)).name}</option>
																				})
																			}
																		</select>
																	</Col>
																 	<Col lg="4" md="4">
																		<Row className="form-group">
																		  {index > 0?
																			<a className="remove-input" title="Remove material" onClick={()=>this.deleteLineItem(arrayHelpers,index,product.lineItemId,values.deletedLineItems)}><i className="icon-close"></i></a>
																			:<a className="add-more" title="Add more material" onClick={() => arrayHelpers.push('')} ><i className="icon-plus icons"></i></a>
																		  }
																		</Row>
																	</Col>
																</Row>

																 </Col>   

																</Row>
																))
															}
														</div>
													)}
												/>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row className="mb-5">
									<div className="col-md-12 mt-4">
										<div className="float-right">
											<button type="submit"
												className={`btn-primary-fill mr-2 ${isSubmitting ? 'btn-disabled' : ''}`}
												disabled={isSubmitting} >{this.props.t('Submit')}</button>
											<button type="button"
												className={`btn-primary-outline ${!dirty || isSubmitting ? 'btn-disabled' : ''}`}
												onClick={handleReset}
												disabled={!dirty || isSubmitting}
											>{this.props.t('Clear')}</button>
										</div>
									</div>
								</Row>
							</form>
						)
					}}
				</Formik>
			</React.Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		error: '',
		allOrders:state.orderReducer.orders,
		lookupData: state.generalReducer.lookupData,

	}
}

const mapDispatchToProps = {
	finishedProductAdd:addFinishedProductAction,
	finishedProductUpdate:UpdatefinishedProductAction

}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(FinishedProductAdd));
