import * as Yup from 'yup';

export const materialValidation = {
    customerId: Yup.string().trim()
        .required("Please Select Customer"),
    metalId: Yup.string().trim()
        .required('Please select Metal'),
    quantity: Yup.number().required("Please enter quantity")
        .when("unitId", {
        is: val => (val && val == 10 ? true : false),
        then: Yup.number().min(
            10,
            "Quantity less than 10 Mg. is not accepted"
        )
    }),
    unitId: Yup.string().trim()
        .required('Please select unit'),
}